import React, { useState } from "react"
import Alert from "@material-ui/lab/Alert"
import PresetForm from "../PasswordReset/PresetForm"
import { useNavigate } from "react-router-dom"

const PresetHandler = ({ token }) => {
    const navigate = useNavigate()

    const [alert, setAlert] = useState(false)
    const [errors, setErrors] = useState(false)
    const [pwd, setPwd] = useState({
        pwd: "",
    })

    const handleChange = (e) => {
        setPwd({ pwd: e.target.value })
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        try {
            let config = {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify(pwd),
            }

            let res = await fetch(process.env.REACT_APP_BACKEND_BASE_URL + "/users/resetPassword", config)
            let response = await res.json()

            if (response.code === 200) {
                // console.log(response)
                setAlert(true)
                setErrors(false)
                setPwd({ pwd: "" })
            } else {
                setErrors(true)
                setAlert(false)
                setPwd({ pwd: "" })

                // handleErrors(response)
                // console.log(response)
            }
        } catch (error) {
            // console.log('errores',error)
        }
    }

    return (
        <>
            {alert && (
                <Alert variant="filled" color="success">
                    ¡Contraseña actualizada!
                </Alert>
            )}
            {errors && (
                <Alert variant="filled" color="error">
                    Tiempo de recuperacion finalizado
                </Alert>
            )}
            <PresetForm
                alert={alert}
                navigate={navigate}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                values={pwd.pwd}
                type="password"
                name="password"
                placehoder="Password"
                sendButton="Confirmar"
            />
        </>
    )
}

export default PresetHandler

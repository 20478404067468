import React, { useEffect, useState } from "react"
import Container from "../../Components/Container/Container"
import { fetchResource } from "../../Functions/Functions"
import CircularProgress from "@material-ui/core/CircularProgress"
import UsersTable from "../../Components/Table/UsersTable"
import DefaultMessage from "../../Components/DefaultMessage/DefaultMessage"
import TextField from "@material-ui/core/TextField"
import Modal from "@material-ui/core/Modal"
import ModalBody from "../../Components/ModalBody/ModalBody"
import ActionsTable from "../../Components/Table/ActionsTable"
import { AsistenciaTable } from "../../Components/Table/AppColumns"
import { CollectionsOutlined, Delete, RemoveShoppingCartRounded, Save } from "@material-ui/icons"
import { matricula } from "../../dummyData"
import { Button, makeStyles, Typography } from "@material-ui/core"
import toast, { Toaster } from "react-hot-toast"
import HeaderExamen from "../../Components/HeaderExamen/HeaderExamen.js"
import TableLibreta from "../../Components/Table/TableLibreta"
import MoveButton from "../../Components/MoveButton/MoveButton"

const Styles = makeStyles({
    filtersContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        minWidth: "100%",
        flexWrap: "wrap",
    },
    filterInput: {
        alignSelf: "flex-end",
    },
    recordCounter: {
        alignSelf: "end",
    },
    ageInputs: {
        maxWidth: "5em",
    },
    ctaButton: {
        backgroundColor: "red",
    },
})

const Libreta = () => {
    const [categoria, setCategoria] = useState(1) // primer categoria es blanco por defecto 1
    const [notas, setNotas] = useState([
        { examen_id: 12, categoria_nueva: 4, actitud_marcial: "B", teoria: "B", tul: "B", promedio: "B" },
        { examen_id: 13, categoria_nueva: 3, actitud_marcial: "MB", teoria: "B", tul: "S", promedio: "MB" },
        { examen_id: 14, categoria_nueva: 2, actitud_marcial: "S", teoria: "S", tul: "S", promedio: "S" },
        { examen_id: 18, categoria_nueva: 1, actitud_marcial: "MB", teoria: "B", tul: "S", promedio: "MB" },
    ])
    const [notaMuestra, setnotaMuestra] = useState({})

    const [loading, setLoading] = useState(true)
    console.log(categoria)
    //  /users/examen/showGrades

    useEffect(() => {
        const fetchNotas = async () => {
            let parametro = [1355]
            const [data, error] = await fetchResource("/users/examen/showGrades", "post", true, parametro)

            if (data) {
                console.log(data)
            } else {
                console.log(error)
            }
        }
        fetchNotas()
    }, [])
    useEffect(() => {
        // descarga los datos del usuario logeado
        cargaDatos()
    }, [categoria]) //carga los datos nuevamente al cambiar la categoria.

    const ChangeCategoria = (accion) => {
        switch (accion) {
            case 1:
                if (categoria < 19) {
                    setCategoria(categoria + 1)
                }

                break
            case 0:
                if (categoria > 1) {
                    setCategoria(categoria - 1)
                }

                break
            default:
                break
        }
    }

    const cargaDatos = () => {
        const n = notas.filter((x) => x.categoria_nueva == categoria)
        if (n.length == !null) {
            let r = n[0].categoria_nueva
            setnotaMuestra(n)
            setLoading(false)
        } else {
        }
    }

    const FormatoDia = (formato, dato1, dato2, dato3) => {
        let mes = 0
        let dia = 0
        let fecha = 0
        if (formato == "yyyy-mm-dd") {
            if (dato2 < 10) {
                mes = "0" + dato2
            } else {
                mes = dato2
            }
            if (dato3 < 10) {
                dia = "0" + dato3
            } else {
                dia = dato3
            }
            fecha = dato1 + "-" + mes + "-" + dia
            return fecha
        }
        if (formato == "dd-mm-yyyy") {
            if (dato2 < 10) {
                mes = "0" + dato2
            } else {
                mes = dato2
            }
            if (dato1 < 10) {
                dia = "0" + dato1
            } else {
                dia = dato1
            }
            fecha = dia + "-" + mes + "-" + dato3
            return fecha
        }
    }

    const nombreDia = (n) => {
        switch (n) {
            case 1:
                return "Lunes"
                break
            case 2:
                return "Martes"
                break
            case 3:
                return "Miercoles"
                break
            case 4:
                return "Jueves"
                break
            case 5:
                return "Viernes"
                break
            case 6:
                return "Sabado"
                break
            case 0:
                return "Domingo"
                break
            default:
                return "No Definido"
                break
        }
    }

    function cuentaDias(diaBusca) {
        var inicio = new Date("2022-03-15") //Fecha inicial
        var fin = new Date("2022-12-15") //Fecha final
        let inicoNumero = inicio.getTime() //obtengo el timestamp
        let finNumero = fin.getTime()
        var timeDiff = Math.abs(finNumero - inicoNumero)
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) //Días entre las dos fechas
        var cuentaFinde = 0 //Número de Sábados y Domingos
        var array = new Array(diffDays)

        for (var i = 0; i < diffDays; i++) {
            if (inicio.getDay() == diaBusca) {
                cuentaFinde++
            }
            inicio.setDate(inicio.getDate() + 1)
        }

        return cuentaFinde
    }

    const classes = Styles()

    return (
        <Container more>
            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <MoveButton direccion="left" onClick={(accion) => ChangeCategoria(accion)} />

                <div
                    style={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "30px",
                    }}
                >
                    {" "}
                    LIBRETA FIT{" "}
                </div>

                <MoveButton direccion="right" onClick={(accion) => ChangeCategoria(accion)} />
            </div>

            {loading ? (
                <CircularProgress color="secondary" />
            ) : (
                <>
                    <HeaderExamen datos={notaMuestra} />

                    <TableLibreta data={notaMuestra} />
                </>
            )}
        </Container>
    )
}

export default Libreta

import React from "react"
import HamburgerMenu from "./HamburgerMenu"
import UserMenu from "./UserMenu"

const MenuOptions = ({ user, logout }) => {
    if (window.innerWidth >= 600) {
        return <UserMenu user={user} logout={logout} />
    } else {
        return <HamburgerMenu user={user} logout={logout} />
    }
}

export default MenuOptions

import React, { useEffect, useState } from "react"
import Container from "../Components/Container/Container"
import { fetchResource } from "../Functions/Functions"
import CircularProgress from "@material-ui/core/CircularProgress"
import AlumnosExamenTable from "../Components/Table/AlumnosExamenTable"
import DefaultMessage from "../Components/DefaultMessage/DefaultMessage"
import Modal from "@material-ui/core/Modal"
import ModalBody from "../Components/ModalBody/ModalBody"
import TextField from "@material-ui/core/TextField"
import { UsersTableBasicColumns } from "../Components/Table/AppColumns"
import ActionsTable from "../Components/Table/ActionsTable"
import UsersTable from "../Components/Table/UsersTable"
import { makeStyles } from "@material-ui/styles"
import Button from "@material-ui/core/Button/Button"
import Typography from "@material-ui/core/Typography/Typography"

const useStyles = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.down("sm")]: {
            top: "50vh",
            left: "1vw",
        },
        position: "absolute",
        top: "40vh",
        left: "50vw",
        minWidth: 370,
        maxWidth: 400,
        padding: 10,
        background: "linear-gradient(to right,  #101010, #151515 , #202020 , #303030 , #434343)",
        boxShadow: 10,
        borderRadius: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    alertText: {
        color: "#e00",
    },
    text: {
        textAlign: "center",
    },
    button: {
        width: 80,
        backgroundColor: "#e00",
    },
    buttonSection: {
        display: "flex",
    },
}))

const AlumnosTorneo = ({ user }) => {
    const [loading, setLoading] = useState(true)
    const [modalOpen, setModalOpen] = useState(false)
    const [alumnos, setAlumnos] = useState([])
    const [alumnoToUnsign, setAlumnoToUnsign] = useState(null)
    const [filtro, setFiltro] = useState(null)

    useEffect(() => {
        const fetchAlumnosTorneo = async () => {
            const [data, error] = await fetchResource("/instructor/torneo/show", "get", true, null)

            if (data) {
                setLoading(false)
                setAlumnos(
                    data.usuarios.map((alumno) => {
                        return { ...alumno, nombre: `${alumno.nombre} ${alumno.apellido}` }
                    })
                )
                setAlumnos(data.usuarios)
            } else {
                setLoading(false)
            }
        }

        fetchAlumnosTorneo()
    }, [])

    const handleDelete = (id) => {
        let filterAlumno = alumnos.find((alumno) => alumno.id === id)
        if (filterAlumno)
            setAlumnoToUnsign({
                id: filterAlumno.id,
                fullName: `${filterAlumno.nombre}`,
            })
        setModalOpen(false)
    }

    const handleChange = (e) => {
        setFiltro(e.target.value)
    }
    // Modal

    const handleClose = () => {
        setModalOpen(false)
    }

    const unsignToEvent = async (id) => {
        setLoading(true)
        const parametro = [id]
        const [data, error] = await fetchResource("/instructor/torneo/unsign", "post", true, parametro)

        if (data) {
            setLoading(false)
            let filterAlumnos = alumnos.filter((alumno) => alumno.id !== id)
            if (filterAlumnos.length) {
                setAlumnos(filterAlumnos)
                setAlumnoToUnsign(null)
            } else {
                setAlumnos([])
                setAlumnoToUnsign(null)
            }
        } else {
            setLoading(false)
        }
    }
    // Modal

    return (
        <div className="alumnos-container">
            <Container title="Alumnos Inscriptos" more={true}>
                {loading ? (
                    <CircularProgress color="secondary" />
                ) : (
                    <>
                        {alumnos.length > 0 ? (
                            <ActionsTable data={alumnos} columns={UsersTableBasicColumns}>
                                {(props) => (
                                    <UsersTable
                                        data={props.filteredCategoriaData}
                                        columns={props.columns}
                                        edit={props.handleRecordEdit}
                                        // addAndRemove={props.handleRecordEdit}
                                        addAndRemove={handleDelete}
                                    />
                                )}
                            </ActionsTable>
                        ) : (
                            <DefaultMessage title={"No tiene alumnos registrados"} />
                        )}
                    </>
                )}
                <Modal
                    open={alumnoToUnsign}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <UnsignTorneoModalBody
                        setAlumnoToUnsign={setAlumnoToUnsign}
                        deleteCallback={unsignToEvent}
                        student={alumnoToUnsign}
                    />
                </Modal>
            </Container>
        </div>
    )
}

export default AlumnosTorneo

const UnsignTorneoModalBody = ({ setAlumnoToUnsign, deleteCallback, student }) => {
    const style = useStyles()

    return (
        <div className={style.paper}>
            <Typography variant="h5" className={style.alertText}>
                Atencion
            </Typography>
            <>
                <Typography
                    variant="h6"
                    className={style.text}
                >{`¿Desea eliminar del torneo al alumno ${student.fullName}?`}</Typography>
                <section className={style.buttonSection}>
                    <Button className={style.button} onClick={() => deleteCallback(student.id)} size="small">
                        Borrar
                    </Button>
                    <Button
                        className={style.button}
                        onClick={() => {
                            setAlumnoToUnsign(null)
                        }}
                        size="small"
                    >
                        Cancelar
                    </Button>
                </section>
            </>
        </div>
    )
}

import React, { useEffect, useState } from "react"
import Container from "../../Components/Container/Container"
import { fetchResource, usePermitions } from "../../Functions/Functions"
import { useNavigate } from "react-router-dom"
import AlumnosTable from "../Alumnos/AlumnosTable"
import { useSelector } from "react-redux"
import { AlumnosDataFilters } from "../Alumnos/AlumnosDataFilters"
import { AlumnosTopInformation } from "../Alumnos/AlumnosTopInformation"
import { Button, makeStyles, MenuItem, Modal, Select, TextField, Typography } from "@material-ui/core"
import { selectClasses } from "@mui/material"
import toast, { Toaster } from "react-hot-toast"
const AlumnosSinInstructor = () => {
    const state = useSelector((state) => ({ ...state["basicDataReducer"] }))
    const { basicData } = state
    const { role_id } = usePermitions()
    const navigate = useNavigate()
    //   const{heightModal, setHeightModal}=useState(400)
    const paramsInitialValues = {
        page: 0,
        nombre: "",
        apellido: "",
        escuela: "",
        asociacion: "",
        categoria: "",
        instructor: "",
        edadDesde: "",
        edadHasta: "",
        actives: true,
    }

    const [loading, setLoading] = useState(true)
    const [clases, setClases] = useState([])
    const [claseSelect, setClaseSelect] = useState(0)
    const [userSelect, setUserSelect] = useState({ id: "", nombre: "" })
    const [alumnos, setAlumnos] = useState([])
    const [params, setParams] = useState(paramsInitialValues)
    const [records, setRecords] = useState(0)
    const [modalOpen, setModalOpen] = useState(false)

    useEffect(() => {
        const fetchClases = async () => {
            const [data, error] = await fetchResource(`/menu/clases`, "get", true, null)

            if (data) {
                setLoading(false)
                setClases(data.data)
                console.log(data.data)
            } else {
                setLoading(false)

                setClases([])
                console.error(error)
            }
        }
        fetchClases()
    }, [])

    useEffect(() => {
        const fetchAlumnosSinInst = async () => {
            const [data, error] = await fetchResource(
                `/instructor/alumnos/showNoIns?page=${params.page + 1}&actives=${params.actives ? 1 : 0}&nombre=${params.nombre}&apellido=${params.apellido}&escuela=${params.escuela}&asociacion=${params.asociacion}&categoria=${params.categoria}&edadDesde=${params.edadDesde}&edadHasta=${params.edadHasta}`,
                "get",
                true,
                null
            )

            if (data) {
                setLoading(false)
                setRecords(data.data.total_records)
                setAlumnos(
                    data.data.alumnos.map((alumno) => ({
                        ...alumno,
                        nombre_completo: `${alumno.nombre} ${alumno.apellido}`,
                    }))
                )
            } else {
                setLoading(false)
                setRecords(0)
                setAlumnos([])
                console.error(error)
            }
        }

        fetchAlumnosSinInst()
    }, [params])

    const notify = (x, nombre) => {
        let mensaje = ""

        if (x == 1) {
            if (nombre) {
                mensaje = nombre
            } else {
                mensaje = "Registro Correcto."
            }
            toast.success(mensaje, { style: { background: "lightgreen", color: "Black" } })
        } else {
            if (nombre) {
                mensaje = nombre
            } else {
                mensaje = "Registro Eliminado"
            }
            toast.error(mensaje, { style: { background: "red", color: "white" } })
        }
    }
    const handlePageChange = (page) => {
        setParams({ ...params, page: page })
    }

    const handleClases = (x) => {
        setClaseSelect(x.target.value)
        console.log(x.target.value)
    }

    const handleAdd = async (id, name) => {
        setModalOpen(true)
        setUserSelect({ id: id, nombre: name })
    }

    const asignarClase = async () => {
        let parametro = [{ id: userSelect.id, clase_id: claseSelect }]
        const [data, error] = await fetchResource("/instructor/alumnos/attachToClass", "post", true, parametro)

        if (data) {
            let noInstFiltrado = alumnos.filter((data) => data.id !== userSelect.id)
            setAlumnos(noInstFiltrado)
            setModalOpen(false)
            setParams({ ...params, page: 0 })
            notify(1, "Alumno Asignado Correctamente")
        } else {
            console.log(error)
        }
    }

    const AsignaCancel = () => {
        setModalOpen(false)
        setClaseSelect(0)
    }

    const editUser = (userId) => {
        navigate(`/editCategory/${userId}`)
    }

    const clearFilters = () => {
        setParams(paramsInitialValues)
    }

    const handleFilter = (e) => {
        const field = e.target.name
        const value = e.target.value
        switch (field) {
            case "nombre":
                setParams({ ...params, nombre: value })
                break
            case "apellido":
                setParams({ ...params, apellido: value })
                break
            case "categoria":
                setParams({ ...params, categoria: value })
                break
            case "escuela":
                setParams({ ...params, escuela: value })
                break
            case "instructor":
                setParams({ ...params, instructor: value })
                break
            case "asociacion":
                setParams({ ...params, asociacion: value })
                break
            case "edadDesde":
                setParams({ ...params, edadDesde: value })
                break
            case "edadHasta":
                setParams({ ...params, edadHasta: value })
                break
            case "actives":
                setParams({ ...params, actives: !params.actives })
                break
            default:
                break
        }
    }
    // const handleOpen = () => setModalON(true);
    // const handleClose = () => setModalON(false);

    return (
        <div className="alumnos-container">
            <Toaster position="top-center" />
            <Container title="Listado de Alumnos sin Instructor" styles={{ display: "flex" }} more>
                <AlumnosTopInformation records={records} params={params} handleFilter={handleFilter} />
                <AlumnosDataFilters
                    basicData={basicData}
                    handleFilter={handleFilter}
                    clearFilters={clearFilters}
                    params={params}
                    userRole={role_id}
                />
                <AlumnosTable
                    data={alumnos}
                    changePage={handlePageChange}
                    pageData={{ currentPage: params.page, records: records }}
                    loading={loading}
                    editRecord={editUser}
                    addRecord={handleAdd}
                    Noedita={true}
                />

                <Modal open={modalOpen} style={{ width: "100%" }}>
                    <Container
                        title={"Asigne una Clase a"}
                        styles={{ marginLeft: "25%", marginTop: "10%", width: "50%", height: "50%" }}
                        more
                    >
                        <Typography variant="h6">{userSelect.nombre}</Typography>

                        <Select
                            style={{ width: 250, textAlign: "center", fontSize: 18 }}
                            defaultValue={0}
                            onChange={handleClases}
                        >
                            <MenuItem disabled key={0} value={0}>
                                Sin Clase Asignada
                            </MenuItem>
                            {clases.map((x) => {
                                return (
                                    <MenuItem key={x.id} value={x.id}>
                                        {x.nombre}
                                    </MenuItem>
                                )
                            })}
                        </Select>

                        <div>
                            <Button
                                disabled={claseSelect == 0 ? 1 : 0}
                                onClick={() => asignarClase()}
                                style={{ background: "red", margin: 10, width: 100 }}
                            >
                                Asignar
                            </Button>
                            <Button
                                onClick={() => AsignaCancel()}
                                style={{ background: "red", margin: 10, width: 100 }}
                            >
                                Cancelar
                            </Button>
                        </div>
                        <Typography>
                            IMPORTANTE: Es condición necesaria asignar una clase para completar la acción.
                        </Typography>
                    </Container>
                </Modal>
            </Container>
        </div>
    )
}

export default AlumnosSinInstructor


import { Typography, Button, Select, Menu, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react'
import Timer from './Timer/Timer';



const Styles = makeStyles({

    letraRoad: {
        width: 60,
        fontFamily: "Road_Rage",
        color: "red",
        textShadow: " -3px 3px 1px   black",
        marginLeft: -20,
        marginRight: -20,
        zIndex: 20,
        alignSelf: "center"
    },
    competidor: {
        fontFamily: "Forque",
        fontSize: 40,
        paddingLeft: 15,
        paddingRight: 15,
        color: "white"
    },
    resultado: {
        color: "green"
    }


})

const MatchRotura = (props) => {
    const clases = Styles()
    const { datos, ronda, area, categorias, selectCate, cancelButton, listo, tipoCompe, handleChangePulgada, siguiente } = props

    let competidor = datos
console.log({competidor})
    const [comp, setComp] = useState(datos);
    let puntaje = { completo: 2, parcial: 1 };

    const [valor, setValor] = useState({ completo: 0, parcial: 0 })
    const [puntos, setPuntos] = useState({ completo: 0, parcial: 0, total: 0 })

    const changePulgada = (e, i) => {
        let cant = e.target.value;
        console.log("cant", cant)
        console.log("cant", i)
        if (i == 1) {
            setValor({ ...valor, completo: cant })
            setPuntos({ ...puntos, completo: cant * puntaje.completo, total: puntos.parcial + (cant * puntaje.completo) })

        } else if (i == 2) {
            setValor({ ...valor, parcial: cant })
            setPuntos({ ...puntos, parcial: cant * puntaje.parcial, total: puntos.completo + (cant * puntaje.parcial) })

        }


    }


    return (





        <div style={{ display: "flex", flexDirection: "row", width: "100%", textAlign: "center", justifyContent: "center" }}>
            <div  style={{
                    borderStyle: "solid", borderWidth: 1, width: "95%", padding: 30, marginTop: 50, background: "grey"
                }}>

                <Typography className={clases.competidor} style={{ textShadow: "-5px 5px 5px red" }}  >{competidor.nombre}</Typography>

                <div style={{ display: "flex", flexDirection: "row", width: "100%", textAlign: "center", justifyContent: "center" }} >


                    <div style={{ width: "50%", background: "transparent", alignContent: "center" }}>
                        <Typography style={{ width: "100%", textAlign: "center" }}>Completa</Typography>
                        <Select

                            style={{ width: 100, fontSize: 40,   textAlign: "center" }}
                            value={valor.completo}
                            name={"completa"}
                            onChange={(e) => changePulgada(e, 1)}
                        >

                            <MenuItem id={11} value={null}>--</MenuItem>
                            <MenuItem id={0} value={0}>0</MenuItem>
                            <MenuItem id={1} value={1}>1</MenuItem>
                            <MenuItem id={2} value={2}>2</MenuItem>
                            <MenuItem id={3} value={3}>3</MenuItem>
                            <MenuItem id={4} value={4}>4</MenuItem>
                            <MenuItem id={5} value={5}>5</MenuItem>
                            <MenuItem id={6} value={6}>6</MenuItem>
                            <MenuItem id={6} value={7}>7</MenuItem>
                            <MenuItem id={8} value={8}>8</MenuItem>
                            <MenuItem id={9} value={9}>9</MenuItem>
                            <MenuItem id={10} value={10}>10</MenuItem>

                        </Select>

                    </div>

                    <div style={{ width: "50%", background: "transparent", alignContent: "center" }}>

                        <Typography>Parcial</Typography>  <Select

                            style={{ width: 100, fontSize:40,   textAlign: "center" }}
                            value={valor.parcial}
                            name={"parcial"}
                            onChange={(e) => changePulgada(e, 2)}
                        >

                            <MenuItem id={11} value={null}>--</MenuItem>
                            <MenuItem id={0} value={0}>0</MenuItem>
                            <MenuItem id={1} value={1}>1</MenuItem>
                            <MenuItem id={2} value={2}>2</MenuItem>
                            <MenuItem id={3} value={3}>3</MenuItem>
                            <MenuItem id={4} value={4}>4</MenuItem>
                            <MenuItem id={5} value={5}>5</MenuItem>
                            <MenuItem id={6} value={6}>6</MenuItem>
                            <MenuItem id={6} value={7}>7</MenuItem>
                            <MenuItem id={8} value={8}>8</MenuItem>
                            <MenuItem id={9} value={9}>9</MenuItem>
                            <MenuItem id={10} value={10}>10</MenuItem>

                        </Select>
                    </div>

                </div>

                <Typography variant='h3'> {puntos.total}  puntos</Typography>

                <div style={{ display: "flex", flexDirection: "row", width: "100%", textAlign: "center", justifyContent: "center", marginTop: 30 }}>

                    <div style={{ width: "30%", alignContent: "center" }} >
                        <Button style={{marginRight:20, background: "red", width: 100 ,height:100}} onClick={cancelButton}   >CANCELAR </Button>
                    </div>

                    <div style={{ width: "30%", alignContent: "center" }}>

                        <Button style={{marginLeft:20, background: "GREEN", width: 100,height:100 }} onClick={() => siguiente(comp, puntos)}   >{"CONFIRMA"} </Button>
                    </div>
                </div>
            </div>


        </div>




    )
}
export default MatchRotura
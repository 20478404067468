import React, { useState, useEffect } from "react"
import Container from "../../../Components/Container/Container"
import CreateCentroHandler from "../../../Components/Formularios/Centro/CreateCentroHandler"
import { fetchResource } from "../../../Functions/Functions"
import CircularProgress from "@material-ui/core/CircularProgress"
import CentroTable from "../../../Components/Table/CentroTable"
import DefaultMessage from "../../../Components/DefaultMessage/DefaultMessage"
import ModalBody from "../../../Components/ModalBody/ModalBody"
import { Modal, TextField, Button, Typography } from "@material-ui/core"
//import { ClasesFilters } from '../../../ClasesFilters'
import Stack from "@mui/material/Stack"

import { useLocation, useNavigate } from "react-router-dom"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import { FormControl, InputAdornment, InputLabel, IconButton, OutlinedInput } from "@mui/material"

const ControlUserGym = () => {
    const location = useLocation()
    console.log({ location })
    const [listaCentros, setListaCentros] = useState([])
    const [loading, setLoading] = useState(true)
    const [visible, setVisible] = useState(false)
    const [visibleModal, setVisibleModal] = useState(false)
    const [datoDelete, setDatoDelete] = useState(null)
    const [edit, setEdit] = useState(null)
    const [showPassword, setShowPassword] = useState(false)
    const [datosUsergym, setDatosUsergym] = useState({
        email: "",
        name: "",
        centro_id: location.state.id,
        password: "",
    })

    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    useEffect(() => {
        const fetchCreateUsergym = async () => {
            const [data, error] = await fetchResource("/instructor/centros/gym/gymUser", "post", true, datosUsergym)
            if (data) {
                setLoading(false)
                setListaCentros(data.centros)
            } else {
                setLoading(false)
                // console.log(error)
            }
        }
    }, [])

    const handleEdit = (datos) => {
        let editar = listaCentros.filter((x) => x.id == datos.id)
        setEdit(editar[0])
        setVisible(true)
    }

    return (
        <div className="centros-container">
            <Container title={`${location.state.nombre} User Gym`} more={true}>
                <div
                    style={{
                        margin: 30,
                        width: "90%",
                        height: 100,
                        display: "flex",
                        alignItems: "center",
                        borderStyle: "solid",
                        borderWidth: 2,
                        borderColor: "white",
                        borderRadius: 10,
                        padding: 10,
                        paddingLeft: 100,
                        paddingRight: 100,
                    }}
                >
                    <Typography style={{ marginRight: 20 }}>CREAR NUEVO USUARIO :</Typography>

                    <FormControl sx={{ m: 1, width: "40ch", borderColor: "white", color: "white" }} variant="outlined">
                        <InputLabel sx={{ color: "white", borderColor: "white" }}>e-mail</InputLabel>
                        <OutlinedInput sx={{ color: "white" }} type="text" label="eMail" />
                    </FormControl>

                    <FormControl sx={{ m: 1, width: "40ch", borderColor: "white" }} variant="outlined">
                        <InputLabel sx={{ color: "white", borderColor: "white" }}>Password </InputLabel>
                        <OutlinedInput
                            sx={{ color: "white", borderColor: "red" }}
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                                //   <InputAdornment position="end">
                                <IconButton
                                    sx={{ color: "white" }}
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                                //   </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>

                    <Button style={{ background: "red", margin: 10, width: 80 }}>Crear</Button>
                </div>

                {/* <ControlUserGymTable/> */}

                <div
                    style={{
                        width: "90%",
                        margin: 30,
                        height: 100,
                        display: "flex",
                        alignItems: "center",
                        borderStyle: "solid",
                        borderWidth: 2,
                        borderColor: "white",
                        borderRadius: 10,
                        padding: 10,
                        paddingLeft: 100,
                        paddingRight: 100,
                    }}
                ></div>
            </Container>

            {/* <Modal open={visibleModal}      >

                <ModalBody

                    handleDelete={() => { handleDelete(datoDelete.id) }}
                    setModalOpen={(x) => { setVisibleModal(x) }}
                    centro={datoDelete}></ModalBody>

            </Modal> */}
        </div>
    )
}

export default ControlUserGym

import React, { useState, useEffect } from "react"
import Cookies from "universal-cookie"
import Container from "./Container/Container"
import Check from "../Assets/Iconos/green-check.png"
import Cross from "../Assets/Iconos/red-cross.png"
import { Box, CircularProgress, Typography } from "@material-ui/core"
import { fetchResource, usePermitions } from "../Functions/Functions"
import moment from "moment"

const CuotaLayout = () => {
    let cookie = new Cookies()
    let fecha = new Date()
    const meses = [
        { mes: "Enero", value: "01" },
        { mes: "Febrero", value: "02" },
        { mes: "Marzo", value: "03" },
        { mes: "Abril", value: "04" },
        { mes: "Mayo", value: "05" },
        { mes: "Junio", value: "06" },
        { mes: "Julio", value: "07" },
        { mes: "Agosto", value: "08" },
        { mes: "Septiembre", value: "09" },
        { mes: "Octubre", value: "10" },
        { mes: "Noviembre", value: "11" },
        { mes: "Diciembre", value: "12" },
    ]

    const [cuota, setCuota] = useState(null)

    useEffect(() => {
        const fetchCuotas = async () => {
            const [data, error] = await fetchResource("/users/cuotas/cuotasUser", "get", true, null)
            console.log("entro", data)
            if (data.code == 200) {
                let c = []
                let month = moment().format("MM")
                let year = moment().format("YYYY")
                c = data.cuotas.filter((x) => x.month == month && x.year == year)

                if (c.length > 0) {
                    console.log("ccc", c[0])
                    setCuota({ id: c[0].month, mes: nombreMes(c[0].month), year: c[0].year, estado: 1 })
                } else {
                    setCuota({ id: month, mes: nombreMes(month), year: year, estado: 0 })
                }
            } else {
                console.log(error)
            }
        }

        fetchCuotas()
    }, [])
    const nombreMes = (mes) => {
        let nombre = []

        nombre = meses.filter((x) => x.value == mes)

        return nombre[0].mes
    }

    return (
        <Container title="Cuota" moreUrl={"/usuarios/cuotas"}>
            {cuota ? (
                <>
                    <Typography variant="h6" style={{ textAlign: "center", width: "100%" }}>
                        {"Estado de la cuota de " + cuota.mes + " " + cuota.year}
                    </Typography>

                    <Box style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: 10 }}>
                        <img style={{ maxWidth: "50px" }} src={cuota.estado == 1 ? Check : Cross} />
                    </Box>
                </>
            ) : (
                <CircularProgress color={"red"}></CircularProgress>
            )}
        </Container>
    )
}

export default CuotaLayout

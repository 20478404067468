import { Button, FormControlLabel, Input, MenuItem, Select, TextField, Typography, IconButton, Tooltip, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react'
import Containter from '../../../Components/Container/Container';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
const Styles = makeStyles({
    tableContainer: {
        display: 'flex',
        minHeight: 700,
        backgroundColor: "black",
        justifyContent: 'center',
        width: "100%",
        borderStyle: "solid",
        borderWidth: 2,
        borderRadius: 10,

    },
    renglon: {
        padding: 10,
        display: 'flex',
        alignItems: "center",
        width: 400,
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 10,
        height: 50,
        color: "black",
        background: "white",
        marginLeft: 20,
    },
    renglonCalenda: {
        color: "black",
        fontSize: 20,
        fontWeight: 2,
        fontWeight: "bolder"
    },

    label: {

        margingLeft: 30,
        marginRight: 20,
        width: 150,
        fontSize: 20,
        fontWeight: "bolder",
        color: "black"

    },
    seccion: {
        display: 'flex',
        width: "100%",

        alignItems: "center",
        flexDirection: "column",
        borderStyle: "solid",
        borderWidth: 2,
        padding: 20,
        margingLeft: 30,
        marginRight: 20

    },
    seccionExterior: {
        display: 'flex',
        flexDirection: "row",

        margingLeft: 10,
        marginRight: 20

    },
    opciones: {
        display: 'flex',
        width: "100%",
        height: "auto",
        flexDirection: "column",
        borderStyle: "solid",
        borderWidth: 1,
        paddingBottom: 40,
        marginBottom: 20,




    },

    noDataContainer: {
        border: 'solid 1px white',
        padding: '0.2em 1em 0.2em 1em',
        borderRadius: '5px',
        minWidth: '90%',
    },
    filtros: {
        margin: 10,
        width: 150,
        height: 35,
        display: "flex",
        alignItems: "center"
    },

    boton: {
        margin: 20,
        marginTop: 40,
        width: 200,
        background: "red",
        textAlign: 'center',
        fontSize: 20,
    },

})

const CreaLiga = (props) => {

    const navigate = useNavigate()
    useEffect(() => {
        fetchShowLiga()
        fetchShowDisciplinas()
    }, [])

    const clases = Styles()

    const [ligaCreate, setLigaCrate] = useState(0)
    const [nombreCreate, setNombreCreate] = useState([])
    const [disciplinas, setDisciplinas] = useState({
        cant: 0,
        nombre: []
    })
    let array = []
    const [params, setParams] = useState({
        id: 2,
        dias: array,
        cantDias: 0,
        categorias: [],
        InscripcionActiva: 0,

    })

    const notify = (x, nombre) => {
        if (x == 1) {
            toast.success(nombre + '.', { style: { background: 'lightgreen', color: "Black" } });
        } else {
            toast.error(nombre + '.', { style: { background: 'red', color: "white" } });
        }
    }
    const fetchShowDisciplinas = async (categoria) => {
        try {
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/showDisciplinas`,
                {
                    method: "GET",
                    headers: { 'Content-type': 'application/json' },

                })

            let response = await res.json()

            if (response.code == 200) {

                setDisciplinas(response.disciplinas)
                console.log(response)


            } else if (response.code == 400) {


                console.log(response)
            }
        } catch (error) {
            console.log(error)
        }

    }

    const fetchShowLiga = async (categoria) => {
        try {
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/showLiga/0`,
                {
                    method: "GET",
                    headers: { 'Content-type': 'application/json' },

                })

            let response = await res.json()

            if (response.code == 200) {
                console.log("LIGA NUEVA ", response)
                let l = 0
                response.data.ligas.map((x) => {

                    if (x.id > l) {
                        l = x.id
                    }

                })
                let respo = l + 1
                setLigaCrate(respo)
                console.log("LIGA NUEVA ", respo)


            } else if (response.code == 400) {
                setLigaCrate(1)

                console.log(response)
            }
        } catch (error) {
            console.log(error)
        }

    }


    const fetchCrearLiga = async () => {
        try {
            let liga = {
                name: nombreCreate,
                fechas: params.dias
            }
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/createLiga`,
                {
                    method: "POST",
                    headers: { 'Content-type': 'application/json' },
                    body: JSON.stringify(liga)
                })

            let response = await res.json()

            if (response.code == 200) {
                setNombreCreate("")
                setParams({
                    id: 0,
                    dias: [],
                    cantDias: 0,
                    categorias: [],
                    InscripcionActiva: 0
                })
                setLigaCrate(0)
                navigate('/liga')

            } else if (response.code == 400) {


                console.log(response)
            }
        } catch (error) {
            console.log(error)
        }

    }
    const agregaDia = () => {

        if (params.cantDias == params.dias.length) {
            setParams({ ...params, cantDias: params.cantDias + 1 })
        }

    }

    const BorrarDia = () => {

        if (params.cantDias == params.dias.length) {
            let dia = []
            dia = params.dias
            dia.pop()
            setParams({ ...params, cantDias: params.cantDias - 1, dias: dia })
        } else {
            setParams({ ...params, cantDias: params.cantDias - 1 })
        }

    }

    const sumaFecha = (f, index) => {
       console.log("FEVCHA",f)
       console.log("FEVCHA1",params.dias)
        let dia = []
        dia = params.dias
      
        if (index == params.dias.length - 1) {
            dia.splice(index, 1, f)
        } else {
            dia.push(f)
        }
        console.log("DIA", dia )
        setParams({ ...params, dias: dia })
        console.log("FEVCHA3",{ ...params, dias: dia })

    }

    const muestraFechas = () => {
        let carga = []

        if (params.cantDias == 0) {
            carga.push(
                <div className={clases.seccionExterior}>
                    <div className={clases.renglon}>

                        <Typography className={clases.label} >Agregue Fecha</Typography>
                    </div>
                    <IconButton onClick={() => agregaDia()}><Tooltip title="AGREGAR"><AddCircleRoundedIcon /></Tooltip></IconButton>

                </div>

            )

        } else {

            for (let index = 0; index < params.cantDias; index++) {
                if (params.cantDias > index + 1) {
                    carga.push(
                        <div className={clases.renglon}>
                            <Typography className={clases.label}   > {`FECHA ${index + 1}:  `}</Typography>
                            <Typography className={clases.label}  > {`   ${moment(params.dias[index]).format("DD/MM/YYYY")}`}</Typography>
                        </div>)
                } else {

                    carga.push(
                        <div className={clases.seccionExterior}>
                            <div className={clases.renglon}     >
                                <Typography className={clases.label}   > {`FECHA ${index + 1} :   `}</Typography>
                                <Input
                                    className={clases.renglonCalenda}
                                    onChange={(x) =>{ sumaFecha(x.target.value, index) }}
                                    defaultValue={params.dias[index]}
                                    type='date'>
                                </Input>
                            </div>
                            {params.cantDias > 1 &&
                                <IconButton onClick={() => BorrarDia()}><Tooltip title="BORRAR"><DeleteForeverRoundedIcon /></Tooltip></IconButton>
                            }
                            <IconButton onClick={() => agregaDia()}><Tooltip title="AGREGAR"><AddCircleRoundedIcon /></Tooltip></IconButton>
                        </div>
                    )
                }

            }
        }
        return carga
    }

    const handleNombre = (x) => {

        setNombreCreate(x)

    }

    const pressCrearLiga = () => {

        if (ligaCreate + 1 < 1 || nombreCreate == "" || params.cantDias < 1) {

            notify(0, "ERROR, Faltan parametros para Crear la Liga")

            //mensaje de error
        } else {

            fetchCrearLiga()
            // ejecuta fetch




        }

    }
    return (

        <div className={clases.tableContainer}>
            <Toaster position="center-center" />
            <div className={clases.seccion}  >
                <div className={clases.opciones}   >
                    <Typography style={{ width: "100%", textAlign: "center", fontSize: 25, background: "red", color: "white" }}> FECHAS </Typography>
                    <Typography style={{ width: "100%", fontSize: 18, margin: 20 }}> {` FECHAS TOTALES : ${params.dias.length}`}</Typography>
                    {muestraFechas()}
                </div>
            </div>
            <div className={clases.seccion}  >
                <div className={clases.opciones} style={{ alignItems: "center" }} >

                    <Typography style={{ width: "100%", textAlign: "center", fontSize: 25, background: "red", color: "white" }} variant="body1">Resumen de Configuracin de Liga  </Typography>

                    <Typography variant='h6'   > </Typography>

                    <Typography variant='h6' style={{ width: "100%", paddingLeft: "10%", paddingTop: 20, textAlign: "left" }} >LIGA Nro.   {ligaCreate} </Typography>


                    <div style={{ width: "100%", paddingTop: 20, display: "flex", flexDirection: "row", alignItems: "left" }} >

                        <Typography variant='h6' style={{ paddingLeft: "10%", textAlign: "left", alignContent: "center", width: "30%" }}  >NOMBRE: </Typography>
                        <Input
                            defaultValue={nombreCreate}
                            className={clases.renglon}
                            onChange={(x) => { handleNombre(x.target.value) }}
                            type="text">
                        </Input>
                    </div>
                    <Typography style={{ width: "100%", textAlign: "left", paddingLeft: "10%", paddingTop: 20 }} variant="h6">Fechas de Competencia:  {params.cantDias}</Typography>
                    <Typography style={{ width: "100%", textAlign: "left", paddingLeft: "10%", paddingTop: 20 }} variant="h6">Disciplinas:</Typography>
                    {disciplinas.length > 0 ?
                        <>
                            {disciplinas.map((x) => {
                                return (<div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center" }}>
                                    <Typography style={{ width: "40%", textAlign: "left", paddingRight: 5 }} variant="body1">{x.id}.  {x.nombre}</Typography>
                                </div>
                                )
                            })}
                        </>
                        :
                        <Typography style={{ width: "100%", textAlign: "center", padding: 20 }} variant="body1">NO HAY DICIPLINAS REGISTRADAS</Typography>
                    }
                    <Button className={clases.boton} onClick={pressCrearLiga}   >{"CREAR LIGA"}</Button>
                </div>


            </div>

        </div>




    )
}

export default CreaLiga
import { Button, FormControlLabel, Input, MenuItem, Select, TextField, Typography, IconButton, Tooltip, CircularProgress, Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react'
import Containter from '../../../Components/Container/Container';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom';

const Styles = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.down("sm")]: {
            top: "50vh",
            left: "1vw",
        },
        position: "absolute",
        top: "40vh",
        left: "50vw",
        minWidth: 370,
        maxWidth: 400,
        padding: 10,
        background: "linear-gradient(to right,  #101010, #151515 , #202020 , #303030 , #434343)",
        boxShadow: 10,
        borderRadius: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    alertText: {
        color: "#e00",
    },
    tableContainer: {
        width: "100%",
        height: "auto",
        textAlign: "center",
    },
    rankingContainer: {
        marginTop: 30,
        textAlign: "center",
        display: "flex",
        flexDirection: "row"
    },
    button: {
        width: 80,
        backgroundColor: "#e00",
        margin: 15,
    },
    buttonSection: {
        display: "flex",
    },
}))
const RankingLiga = (props) => {
    const { handleCancelaDia, info, handleIniciaDia } = props

    const navigate = useNavigate()
    useEffect(() => {
        fetchRanking()
    }, [])

    const clases = Styles()
    const [loading, setLoading] = useState(true)
    const [ligaData, setLigaData] = useState()
    const [ligas, setLigas] = useState([])

    const [modalOpen, setModalOpen] = useState(false)
    const [diaFinalizar, setDiaFinalizar] = useState(0)



    const notify = (x, nombre) => {
        if (x == 1) {
            toast.success(nombre + '.', { style: { background: 'lightgreen', color: "Black" } });
        } else {
            toast.error(nombre + '.', { style: { background: 'red', color: "white" } });
        }
    }

    const NombreDia = (d) => {
        let dia = ""

        switch (d) {
            case 0:
                dia = "Domingo"
                break;
            case 1:
                dia = "Lunes"
                break;
            case 2:
                dia = "Martes"
                break;
            case 3:
                dia = "Miercoles"
                break;
            case 4:
                dia = "Jueves"
                break;
            case 5:
                dia = "Viernes"
                break;
            case 6:
                dia = "Sabado"
                break;

            default:
                dia = "Desconocido"
                break;
        }


        return dia
    }

    const fetchShowLiga = async (id) => {
        setLoading(true)
        try {

            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/showLiga/${id}`,
                {
                    method: "GET",
                    headers: { 'Content-type': 'application/json' },

                })

            let response = await res.json()

            if (response.code == 200) {
                let liga = response.data.ligas[0];
                let fechas = response.data.fechas

                fetchInfoLiga(id, liga, fechas);


            } else if (response.code == 400) {

                setLoading(false)
                console.log(response)
            }
        } catch (error) {
            setLoading(false)
            console.log(error)
        }

    }

    const fetchInfoLiga = async (id, liga, fechas) => {
        setLoading(true)

        try {
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/infoLiga/${id}`,
                {
                    method: "GET",
                    headers: { 'Content-type': 'application/json' },
                })

            let response = await res.json()

            if (response.code == 200) {


                setLigaData({
                    liga: response.data.liga,
                    fechas: response.data.fechas, llaves: response.data.llaves, competidores: response.data.competidores
                })
                //    console.log("INFO",{liga:liga,fechas:fechas , llaves:response.data.llaves, competidores: response.data.competidores})   

                setLoading(false)
            } else if (response.code == 400) {
                console.log("INFO NONO")
                setLoading(false)
                console.log(response)
            }
        } catch (error) {
            setLoading(false)
            console.log(error)
        }

    }
    const fetchRanking = async (id) => {
        setLoading(true)
        try {
            let comp = 0;
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/Ranking/0`,
                {
                    method: "GET",
                    headers: { 'Content-type': 'application/json' },

                })
            let response = await res.json()
            if (response.code == 200) {
                setLigas({ infantil: response.infantil, mayor: response.mayor, profesional: response.profesional })
                setLoading(false)
            } else if (response.code == 400) {
                setLoading(false)
            }
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }






    return (

        <div className={clases.tableContainer}>
            {/* <Toaster position="center-center" /> */}

            <Typography>SUPER COPA LIGA TKD</Typography>
            <div className={clases.rankingContainer}>
                <div style={{ width: "33%" }}>
                    <Typography style={{ width: "100%", color: "red", margin: 20, fontSize: 30 }} >INFANTIL</Typography>
                    {!loading ?
                        ligas?.infantil?.map((x, i) => {
                            return (
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <Typography style={{ width: "15%" }} >{i + 1}</Typography>
                                    <Typography style={{ width: "60%", textAlign: "left" }}>{x.nombre + " " + x.apellido}</Typography>
                                    <Typography style={{ width: "25%" }}>{x.puntos + " pts."}</Typography>
                                </div>
                            )


                        })
                        :
                        <CircularProgress style={{ color: "red" }} />
                    }
                </div>
                <div style={{ width: "33%" }}>
                    <Typography style={{ width: "100%", color: "red", margin: 20, fontSize: 30 }} >MAYOR</Typography>
                    {!loading ?
                        ligas?.mayor?.map((x, i) => {
                            return (
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <Typography style={{ width: "15%" }} >{i + 1}</Typography>
                                    <Typography style={{ width: "60%", textAlign: "left" }}>{x.nombre + " " + x.apellido}</Typography>
                                    <Typography style={{ width: "25%" }}>{x.puntos + " pts."}</Typography>
                                </div>
                            )
                        })
                        :
                        <CircularProgress style={{ color: "red" }} />
                    }
                </div>
                <div style={{ width: "33%" }}>
                    <Typography style={{ width: "100%", color: "red", margin: 20, fontSize: 30 }} >PROFESIONAL</Typography>
                    {!loading ?
                        ligas?.profesional?.map((x, i) => {
                            return (
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <Typography style={{ width: "15%" }} >{i + 1}</Typography>
                                    <Typography style={{ width: "60%", textAlign: "left" }}>{x.nombre + " " + x.apellido}</Typography>
                                    <Typography style={{ width: "25%" }}>{x.puntos + " pts."}</Typography>
                                </div>
                            )
                        })
                        :
                        <CircularProgress style={{ color: "red" }} />
                    }
                </div>
            </div>
        </div>




    )
}


export default RankingLiga
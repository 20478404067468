import React from "react"
import { Button, makeStyles, TextField, Typography } from "@material-ui/core"
import Checkbox from "@material-ui/core/Checkbox"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Selection from "../Select"
import Alert from "@material-ui/lab/Alert"

const Styles = makeStyles({
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 20,
        gap: "1em",
    },
    button: {
        width: "210px",
        backgroundColor: "#e00",
    },
    checkGroup: {
        alignItems: "center",
        justifyContent: "center",
    },
    registro: {
        borderTopStyle: "solid",
        borderColor: "#fffb",
        borderWidth: "1px",
        paddingTop: "5px",
        marginTop: "5px",
        marginBottom: "5px",
    },
    forgot: {
        textDecoration: "none",
        color: "#0099ff",
    },
})

const CreateClaseForm = ({ clase, centros, handleChange, handleSubmit, handleToggle, error }) => {
    const style = Styles()

    const { lunes, martes, miercoles, jueves, viernes, sabado, domingo } = clase

    return (
        <form onSubmit={handleSubmit} className={style.form} autoComplete="nope">
            {error && (
                <Alert variant="filled" color="error">
                    Verifique los datos ingresados
                </Alert>
            )}
            <Selection
                autoComplete="nope"
                id="centro_id"
                name="centro_id"
                label="Centro"
                value={clase.centro_id}
                options={centros}
                onChange={handleChange}
                color="secondary"
                required
            />
            <TextField
                autoComplete="nope"
                id="nivel"
                name="nivel"
                label="Nivel"
                value={clase.nivel}
                onChange={handleChange}
                type="text"
                variant="outlined"
                size="small"
                color="secondary"
                required
            />
            <TextField
                autoComplete="nope"
                id="descripcion"
                name="descripcion"
                label="Descripcion"
                value={clase.descripcion}
                onChange={handleChange}
                type="text"
                variant="outlined"
                size="small"
                color="secondary"
                required
            />
            <TextField
                autoComplete="nope"
                id="inicio"
                name="inicio"
                label="Horario Inicio / 24hs"
                value={clase.inicio}
                onChange={handleChange}
                type="time"
                variant="outlined"
                size="small"
                color="secondary"
                required
            />
            <TextField
                autoComplete="nope"
                id="fin"
                name="fin"
                label="Horario Fin / 24hs"
                value={clase.fin}
                onChange={handleChange}
                type="time"
                variant="outlined"
                size="small"
                color="secondary"
                required
            />
            <Typography>Dias</Typography>
            <FormGroup row className={style.checkGroup}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="lunes"
                            name="lunes"
                            checked={clase.lunes}
                            onChange={() => handleToggle("lunes")}
                        />
                    }
                    label="L"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            id="martes"
                            name="martes"
                            checked={clase.martes}
                            onChange={() => handleToggle("martes")}
                        />
                    }
                    label="M"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            id="miercoles"
                            name="miercoles"
                            checked={clase.miercoles}
                            onChange={() => handleToggle("miercoles")}
                        />
                    }
                    label="Mi"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            id="jueves"
                            name="jueves"
                            checked={clase.jueves}
                            onChange={() => handleToggle("jueves")}
                        />
                    }
                    label="J"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            id="viernes"
                            name="viernes"
                            checked={clase.viernes}
                            onChange={() => handleToggle("viernes")}
                        />
                    }
                    label="V"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            id="sabado"
                            name="sabado"
                            checked={clase.sabado}
                            onChange={() => handleToggle("sabado")}
                        />
                    }
                    label="S"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            id="domingo"
                            name="domingo"
                            checked={clase.domingo}
                            onChange={() => handleToggle("domingo")}
                        />
                    }
                    label="D"
                />
            </FormGroup>
            <Button
                type="submit"
                variant="contained"
                className={style.button}
                color="primary"
                disabled={!lunes && !martes && !miercoles && !jueves && !viernes && !sabado && !domingo}
            >
                Crear
            </Button>
        </form>
    )
}

export default CreateClaseForm


import jsPDF from 'jspdf'

import React, { useState } from 'react';
import logo from "./../../../Assets/Imagenes/Logo_SUPERCOPA.png"
import QRCode from 'qrcode'
import { useEffect } from 'react';
import moment from 'moment/moment';

const PDFCertificadosPremios = (props) => {

  const { alumnos, disciplina, categoria, listo, tipo_compe } = props;

  // let alumno = {
  //   dni: 28229051,
  //   nombre: "Martin Miguel",
  //   apellido: "Bellavita",
  //   resultado: 1,
  //   disciplina_id: 1,
  //   disciplina_name: "Formas",
  //   edad: 44,
  //   categoria_id: 16,
  //   categoria: "SEXTO DAN",
  //   dia: 1,
  //   sexo:2,
  //   fecha: "22/09/2024"
  // }
  let Certificados = [];
 let dis=""
  if (tipo_compe == 1) {
    let rM = alumnos.reduce((acc, el) => acc.ronda > el.ronda ? acc : el)
    let rondaMaxima = rM.ronda;
 
    alumnos.map((x)=>{

       if ( x.ronda==rondaMaxima && x.resultado==1){
        Certificados.push({nombre:x.nombre+" "+x.apellido,fecha:moment(x.fecha).format("DD/MM/YYYY"),dia:x.dia,puesto:1,disciplina_name:x.disciplina_name })
       }else if ( x.ronda==rondaMaxima && x.resultado==0){
        Certificados.push({nombre:x.nombre+" "+x.apellido,fecha:moment(x.fecha).format("DD/MM/YYYY"),dia:x.dia,puesto:2 ,disciplina_name:x.disciplina_name})
       }else{
        Certificados.push({nombre:x.nombre+" "+x.apellido,fecha:moment(x.fecha).format("DD/MM/YYYY"),dia:x.dia,puesto:3 ,disciplina_name:x.disciplina_name})
       }



    })



  }
  
  const sexo = (valor) => {
    let sexo = "Mixto"

    switch (valor) {
      case 1:
        sexo = "Femenino"
        break;
      case 2:
        sexo = "Masculino"
        break;
        case2:
        sexo = "Mixto"
        break;

      default:
        break;
    }
    return sexo
  }
  const titulo = (valor) => {
    let sexo = "Mixto"

    switch (valor) {
      case 1:
        sexo = "Sra."
        break;
      case 2:
        sexo = "Sr."
        break;
      case 3:
        sexo = "Sr/a"
        break;

      default:
        sexo = "Sr/a"
        break;
    }
    return sexo
  }

  const NC = (num) => {
    let categoria = "n/c"
    switch (num) {

      case 1:
        categoria = "Blanco"
        break;
      case 2:
        categoria = "Punta Amarilla"
        break;
      case 3:
        categoria = "Amarillo"
        break;
      case 4:
        categoria = "Punta Verde"
        break;
      case 5:
        categoria = "Verde"
        break;
      case 6:
        categoria = "punta Azul"
        break;
      case 7:
        categoria = "Azul"
        break;
      case 8:
        categoria = "Punta Roja"
        break;
      case 9:
        categoria = "Rojo"
        break;
      case 10:
        categoria = "Punta Negra"
        break;
      case 11:
        categoria = "1º Dan"
        break;
      case 12:
        categoria = "2º Dan"
        break;
      case 13:
        categoria = "3º Dan"
        break;
      case 14:
        categoria = "4º Dan"
        break;
      case 15:
        categoria = "5º Dan"
        break;
      case 16:
        categoria = "6º Dan"
        break;
      case 17:
        categoria = "7º Dan"
        break;
      case 18:
        categoria = "8º Dan"
        break; case 19:
        categoria = "9º Dan"
        break;


      default:
        break;
    }

    return categoria;
  }
  const mes_name = (num) => {
    let categoria = "n/c"
    switch (num) {

      case "01":
        categoria = "Enero"
        break;
      case "02":
        categoria = "Febrero"
        break;
      case "03":
        categoria = "Marzo"
        break;
      case "04":
        categoria = "Abril"
        break;
      case "05":
        categoria = "Mayo"
        break;
      case "06":
        categoria = "Junio"
        break;
      case "07":
        categoria = "Julio"
        break;
      case "08":
        categoria = "Agosto"
        break;
      case "09":
        categoria = "Septiembre"
        break;
      case "10":
        categoria = "Octubre"
        break;
      case "11":
        categoria = "Noviembre"
        break;
      case "12":
        categoria = "Diciembre"
        break;

      default:
        break;
    }

    return categoria;
  }

 let doc = new jsPDF({
      orientation: "l",
      unit: "mm",
      format: "a4",
      putOnlyUsedFonts: true,
      precision: 2,
    });


 let parte=[];
Certificados.map((x,i)=>{

    
   parte=  x.fecha.split("/")
   let dia=parte[0]
   let mes=mes_name(parte[1]);
   let anio=parte[2]
 
    doc.setFontSize(20);
    doc.setFont('Helvetica', 'bold')
    doc.text(`El ${titulo(x.sexo)} ${x.nombre} ha participado en `, 40, 75)
    doc.text(`la fecha Nro. ${x.dia} de la Liga SUPER COPA TKD `, 40, 90)
    doc.text(`obteniendo el ${x.puesto}º Puesto en ${x.disciplina_name}. `, 40, 105)
    doc.text(`el dia ${dia} de ${mes} de ${anio}`, 120,130)
 
   if(Certificados.length > i+1){
   
    doc.addPage()
   }
    


  })
    doc.output('save', `TITO.pdf`);
  // doc.save("yourDoc.pdf");

  listo(false)
  return (
    <div>
    </div>
  );
}

export default PDFCertificadosPremios;
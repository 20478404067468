import { IconButton, Tooltip } from "@material-ui/core"
import { ArrowDownward, ArrowUpward } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import EditIcon from "@mui/icons-material/Edit"
import { DataGrid } from "@mui/x-data-grid"
import React from "react"
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import MoneyOffIcon from "@mui/icons-material/MoneyOff"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"
import PriceCheckIcon from "@mui/icons-material/PriceCheck"
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt"
import ThumbUpIcon from "@mui/icons-material/ThumbUp"

const Styles = makeStyles({
    tableContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    noDataContainer: {
        border: "solid 1px white",
        padding: "0.2em 1em 0.2em 1em",
        borderRadius: "5px",
        minWidth: "90%",
    },
    noDataText: {
        textAlign: "center",
    },
})

const InstructorInscribeExamenTable = (props) => {
    const clases = Styles()

    const {
        data,
        pageData,
        changePage,
        loading,
        editRecord,
        sign,
        pays,
        examSelect,
        removeRecord = null,
        addRecord = null,
    } = props

    const columns = [
        {
            field: "dni",
            headerName: "Dni",
            width: 140,
            renderCell: (row) => (
                <div
                    style={
                        (row.row.examen_id !== examSelect) & (row.row.inscripto == true)
                            ? { color: "grey" }
                            : { color: "white" }
                    }
                >
                    {row.row.dni || "No Indicado"}
                </div>
            ),
        },
        {
            field: "nombre_completo",
            headerName: "Nombre",
            minWidth: 290,
            renderCell: (row) => (
                <div
                    style={
                        (row.row.examen_id !== examSelect) & (row.row.inscripto == true)
                            ? { color: "grey" }
                            : { color: "white" }
                    }
                >
                    {row.row.nombre_completo || "No Indicado"}
                </div>
            ),
        },
        {
            field: "edad",
            headerName: "Edad",
            minWidth: 110,
            renderCell: (row) => (
                <div
                    style={
                        (row.row.examen_id !== examSelect) & (row.row.inscripto == true)
                            ? { color: "grey" }
                            : { color: "white" }
                    }
                >
                    {row.row.edad || "No Indicado"}
                </div>
            ),
        },
        //  { field:'telefono', headerName: 'Telefono',minWidth: 120, renderCell: row => <div>{row.row.telefono || 'No Indicado'}</div>},
        {
            field: "categoria",
            headerName: "Categoria",
            minWidth: 190,
            renderCell: (row) => (
                <div
                    style={
                        (row.row.examen_id !== examSelect) & (row.row.inscripto == true)
                            ? { color: "grey" }
                            : { color: "white" }
                    }
                >
                    {row.row.categoria || "No Indicado"}
                </div>
            ),
        },
        {
            field: "instructor",
            headerName: "Instructor",
            minWidth: 170,
            renderCell: (row) => (
                <div
                    style={
                        (row.row.examen_id !== examSelect) & (row.row.inscripto == true)
                            ? { color: "grey" }
                            : { color: "white" }
                    }
                >
                    {row.row.instructor || "No Indicado"}
                </div>
            ),
        },

        {
            field: "more",
            headerName: "autoriza",
            width: 120,
            renderCell: (row) => {
                if (row.row.inscripto == true && row.row.examen_id == examSelect) {
                    return (
                        <IconButton style={{ color: "green" }} onClick={() => sign(row.row.id, 0)}>
                            <Tooltip title="NO Autorizar">
                                <CheckCircleOutlineIcon />
                            </Tooltip>
                        </IconButton>
                    )
                } else if (row.row.inscripto == false) {
                    return (
                        <IconButton style={{ color: "white" }} onClick={() => sign(row.row.id, 1)}>
                            <Tooltip title="Autorizar">
                                <RadioButtonUncheckedIcon />
                            </Tooltip>
                        </IconButton>
                    )
                } else if (row.row.inscripto == true && row.row.examen_id !== examSelect) {
                    return (
                        <IconButton style={{ color: "grey" }}>
                            <Tooltip title="inscripto en otro examen">
                                <CheckCircleOutlineIcon />
                            </Tooltip>
                        </IconButton>
                    )
                }
            },
        },
        {
            field: "actions",
            headerName: "padres",
            minWidth: 120,
            renderCell: (row) => {
                if (row.row.examen_id == examSelect || row.row.inscripto == false) {
                    if (row.row.confirmado == true) {
                        return (
                            <IconButton style={{ color: "green" }}>
                                <Tooltip title="confirmado">
                                    <ThumbUpIcon />
                                </Tooltip>
                            </IconButton>
                        )
                    } else {
                        return (
                            <IconButton style={{ color: "white" }}>
                                <Tooltip title="Sin Confirmar">
                                    <ThumbDownOffAltIcon />
                                </Tooltip>
                            </IconButton>
                        )
                    }
                } else {
                    return <div style={{ color: "grey" }}>Examen Nro {row.row.examen_id}</div>
                }
            },
        },
        {
            field: "pays",
            headerName: "pago",
            minWidth: 120,
            renderCell: (row) => {
                if (row.row.examen_id == examSelect || row.row.inscripto == false) {
                    if (row.row.pago == true) {
                        return (
                            <IconButton style={{ color: "green" }} onClick={() => pays(row.row.id, 0)}>
                                <Tooltip title="anula pago">
                                    <PriceCheckIcon />
                                </Tooltip>
                            </IconButton>
                        )
                    } else {
                        return (
                            <IconButton style={{ color: "white" }} onClick={() => pays(row.row.id, 1)}>
                                <Tooltip title="confirma pago">
                                    <AttachMoneyIcon />
                                </Tooltip>
                            </IconButton>
                        )
                    }
                }
            },
        },
    ]

    return (
        <div className={clases.tableContainer}>
            {data.length ? (
                <DataGrid
                    pagination
                    autoHeight
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={data}
                    pageSize={10}
                    loading={loading}
                    columns={columns}
                    paginationMode="server"
                    isRowSelectable={false}
                    onPageChange={changePage}
                    rowsPerPageOptions={[10]}
                    rowCount={pageData.records}
                    page={pageData.currentPage}
                />
            ) : (
                <div className={clases.noDataContainer}>
                    <h2 className={clases.noDataText}>No se encontraron alumnos</h2>
                </div>
            )}
        </div>
    )
}

export default InstructorInscribeExamenTable

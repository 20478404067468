import React from "react"

//Estilos
import "./../../styles/TableLibreta.css"

import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"
import LockIcon from "@material-ui/icons/Lock"
import LockOpenIcon from "@material-ui/icons/LockOpen"
import ListIcon from "@material-ui/icons/List"
import { minWidth } from "@mui/system"
import { VariantContext } from "@material-ui/pickers/wrappers/Wrapper"

//   const useStyles = makeStyles({
//     tabla: {
//         width: "100%",
//         display: "flex",
//         flexDirection: "row",
//         background : "red",
//         /*   flexWrap: "wrap",
//         marginTop: "30px", */
//     },

// });

const TableLibreta = ({ data }) => {
    const notas = Object.entries(data[0])
    // const classes = useStyles()

    const cantidad = (cantidad) => {
        let col1 = 0,
            col2 = 0,
            mitad = 0

        if (cantidad % 2 == 0) {
            col1 = cantidad / 2
            col2 = cantidad / 2
        } else {
            cantidad--
            console.log(cantidad)
            mitad = cantidad / 2
            col1 = mitad + 1
            col2 = mitad
        }

        return col1
    }

    return (
        <div className="tabla">
            {/* columna 1 */}
            <div style={{ flex: 1 }}>
                <TableContainer>
                    <Table aria-label="exam table" size="small">
                        {notas.map((fila, index) => {
                            if (index + 1 <= cantidad(notas.length)) {
                                //index +1 porque index empieza en 0 (cero)

                                return (
                                    <TableBody>
                                        <TableRow key={fila[0]}>
                                            <TableCell> {fila[0]} </TableCell>
                                            <TableCell align="center"> {fila[1]}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                )
                            }
                        })}
                    </Table>
                </TableContainer>
            </div>
            {/* columna 2 */}
            <div style={{ flex: 1 }}>
                <TableContainer>
                    <Table aria-label="exam table" size="small">
                        {notas.map((fila, index) => {
                            if (index + 1 > cantidad(notas.length)) {
                                return (
                                    <TableBody>
                                        <TableRow key={fila[0]}>
                                            <TableCell> {fila[0]} </TableCell>
                                            <TableCell align="center"> {fila[1]}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                )
                            }
                        })}
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default TableLibreta

import { Button, CircularProgress, Input, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import Containter from '../../../Components/Container/Container';
import Match from './Match';
import toast, { Toaster } from 'react-hot-toast';

const LLaveGruposGup = (props) => {
    const { datos,
        handleWin,
        cancelMatch,
        finalizar,
        handleAsist


    } = props
 
    
    const [area, setArea] = useState(0);
    const [showMatch, setShowMatch] = useState(false);
    const [competidores, setCompetidores] = useState([]);

    let llave = datos.llave;
    let rondas = datos.rondas;
    let estado = datos.estado;
    let altoPosicion = 40;
    let font = 13;
    let largoPosicion = 300;
    let espaciado = altoPosicion / 2
 
    const estadoEstado = (props) => {


        let x = false
        if (estado == 3) { x = true }
        return x
    }
    const posicionResultado = (llave) => {

        let primero = [];
        let segundo = [];
        let margenB = 20;

        llave.map((x) => {
            if (x.next == 0) {
                if (x.resultado == 1) {
                    primero = { ...x }
                }
                else if (x.resultado == 0) { segundo = { ...x } }
            }
        })


        console.log("ESTADO", estadoEstado())
        return (<div style={{ display: "flex", flexDirection: "column", height: "50%", paddingTop: altoPosicion * 1.5 }}>

            {primero.nombre && <Button disabled={estadoEstado()} style={{ background: "red", marginBottom: 50 }} onClick={() => finalizar(primero.categoria_id)} >{estado == 3 ? "LLAVE FINALIZADA" : "FINALIZAR"}</Button>}

            <div style={{ marginBottom: 80, display: "flex", flexDirection: "column", alignItems: "center", marginBottom: margenB, width: largoPosicion + 50, height: altoPosicion }}>
                <Typography variant='h3' style={{ textAlign: "center", color: "limegreen" }}> {"1º PUESTO "}</Typography>
                <Typography variant='h4' style={{ textAlign: "center" }}> {primero.nombre ? primero.nombre : " - -"}</Typography>

            </div>


            <div style={{ marginTop: 100, display: "flex", flexDirection: "column", alignItems: "center", marginBottom: margenB, width: largoPosicion + 50, height: altoPosicion }}>

                <Typography variant='h4' style={{ textAlign: "center", color: "limegreen" }} >  {"2º PUESTO"}</Typography>
                <Typography variant='h5' style={{ textAlign: "center" }} > {segundo.nombre ? segundo.nombre : "- -"}</Typography>

            </div>

        </div>
        )
    }
    const notify = (x, nombre) => {
        if (x == 1) {
            toast.success(nombre + '.', { style: { background: 'lightgreen', color: "Black" } });
        } else {
            toast.error(nombre + '.', { style: { background: 'red', color: "white" } });
        }
    }
    const posicion = (datos, ronda) => {
        let margenL = 30;
        let margenB = 0
        let tamañollave = altoPosicion
        let margenT = 100;
        let color = "white"
        let compe = datos.nombre;
        let bordeInferior = "solid"
        if (datos.posicion % 2 !== 1) {
            if (ronda == 1) { margenL = margenL; margenB = espaciado; margenT = 0; }
            if (ronda == 2) { margenL = margenL; margenB = espaciado * 3; tamañollave = espaciado * 5 }
            if (ronda == 3) { margenL = margenL; margenB = espaciado * 8; tamañollave = espaciado * 10 }
            if (ronda == 4) { margenL = margenL; margenB = espaciado * 21; tamañollave = espaciado * 21 }
            if (ronda == 5) { margenL = margenL; margenB = espaciado * 16; tamañollave = espaciado * 16 }

            bordeInferior = "solid"
        } else { bordeInferior = "none" }
        return (

            <div style={{ alignItems: "flex-end", borderBottomStyle: "solid", borderRightStyle: bordeInferior, display: "flex", flexDirection: "row", marginBottom: margenB, width: largoPosicion, height: tamañollave }}>
                <Button disabled={estadoEstado()} onClick={() => cancelMatch(datos)}  >{compe && (datos.ronda == 1 || datos.ronda == 2) ? "P" : "X"}</Button>
                <Typography disabled={estadoEstado()} onClick={() => { handleWin(datos); console.log("winData", datos) }} style={{ cursor: estado == 2 ? "default" : "pointer", textAlign: "initial", fontSize: font }} > {compe ? compe : "-  -"}</Typography>
            </div>

        )
    }
    const handleCancelMatch = () => {
        setShowMatch(false)

    }
    const listo = (c) => {

        handleWin(c)

        setShowMatch(false)

    }
    const compiten = (c) => {
        let competidores = c;

        if (c.length == 1) {

            let libre = {
                categoria_id: c[0].categoria_id,
                competidor_id: 0,
                dia: c[0].dia,
                id: null,
                liga_id: c[0].liga_id,
                match_id: c[0].match_id,
                next: 0,
                nombre: "COMPETIDOR LIBRE",
                posicion: 0,
                presente: 0,
                resultado: null,
                ronda: c[0].ronda,
            }
            competidores.push(libre)

        }

        setCompetidores(competidores)
        setShowMatch(true)

    }
    const finalizarGrupos = (ll) => {
        let r = true
        let cont = 0
        let total = ll.length

        ll.map((x) => {

            if (x.resultado !== null ) {
                cont++
            }
        })

        if (cont == total) {
            r = false
        }
     
        return r
    }

console.log({llave})
    return (
        <div style={{ width: "100%", height: 800, display: "flex", flexDirection: "row", alignContent: "center" }}   >
            <Toaster position="top-center" />
            {showMatch ? <Match listo={(x) => listo(x)} datos={competidores} tipoCompe={2} cancelButton={() => handleCancelMatch()} />
                :
                <div style={{ width: "100%", height: 500, alignContent: "center", justifyItems: "center" }}>

                    <Botones datos={llave}
                        estado={estado}
                        handleAsistencia={(a) => handleAsist(a)}
                        compiten={compiten}
                        notificacion={(x) => notify(0, x)}
                    />
                    <div style={{ width: "100%", marginTop: 30 }}>

                        <Button disabled={finalizarGrupos(llave)}
                            style={{ background: "red", width: 200, marginLeft: "45%", color: "white" }} onClick={() => finalizar(llave[0])}    > {estado == 3 ? "LLAVE FINALIZADA" : "FINALIZAR"}  </Button>
                    </div>
                </div>
            }
        </div>

    )
}
export default LLaveGruposGup

const Botones = (props) => {
    const { datos, handleAsistencia, compiten, notificacion, estado } = props
    let boton = []

    const competidoresMatch = (m) => {
        let c = [];

        datos.map((x) => {
            if (x.match_id == m) {
                c.push(x)
            }
        })

        if (c.length > 0) {
            compiten(c)
        } else {

            notificacion("No hay match")
        }


    }
    const viweResult = (d1, d2) => {
        let r = "VS";

        if (d1 == 2 && d2 == 2) {
            r = "E";
        }
        return r
    }
    const matchUser = datos.reduce((prev, cur) => cur.match_id > prev.match_id ? cur : prev);
    let matchMayor = matchUser.match_id;

    for (let match = 0; match <= matchMayor; match++) {

        let competidores = datos.filter((x) => x.match_id == match)
        if (match  == 0) { boton.push(<Typography style={{ width: "100%", textAlign: "center", marginBottom: 20 }}>RONDA 1</Typography>) }
        if (match-1 == matchMayor/2) { boton.push(<Typography style={{ width: "100%", textAlign: "center", marginBottom: 20 }}>RONDA 2</Typography>) }

        if (competidores.length == 2) {
            boton.push(
                <div style={{ display: "flex", flexDirection: "row", height: 60, alignItems: "center", justifyContent: "center", margin: 10 }}>
                    <Typography>Nro. {competidores[0].match_id}</Typography>


                    <Button id={competidores[0].match_id}
                        disabled={estado == 3 ? true : false}
                        onClick={(e) => competidoresMatch(e.currentTarget.id)}
                        style={{
                            height: 60,
                            background:  competidores[0].resultado > 0 ? "green" :
                                    competidores[0].resultado == 0 ? "red" :
                                        "transparent",
                            borderStyle:  "solid",
                            borderColor: "grey",
                            borderWidth: 1,
                            margin: 10,
                            width: 250,
                            color:   competidores[0].resultado == 1 ? "white" :
                                    competidores[0].resultado == 0 ? "white" :
                                        "white",
                        }
                        }>{competidores[0].nombre}</Button>
 
                    <Typography>{viweResult(competidores[0].resultado, competidores[1].resultado)}</Typography>
                    <Button id={competidores[1].match_id}
                        disabled={estado == 3 ? true : false}
                        onClick={(e) => competidoresMatch(e.currentTarget.id)}
                        style={{
                            height: 60,
                            background: competidores[1].resultado > 0 ? "green" :
                                        competidores[1].resultado == 0 ? "red" :
                                        "transparent",
                            borderStyle: "solid",
                            borderColor: "grey",
                            borderWidth: 1,
                            margin: 10,
                            width: 250,
                            color:   competidores[1].resultado == 1 ? "white" :
                                    competidores[1].resultado == 0 ? "white" :
                                        "white",
                        }}>{competidores[1].nombre}</Button>

                </div>
            )

        } else if (competidores.length == 1) {
            boton.push(
                <div style={{ display: "flex", flexDirection: "row", height: 60, alignItems: "center", justifyContent: "center" }} >
                    <Typography>Nro. {competidores[0].match_id}</Typography>

                    <Button
                        id={competidores[0].match_id}
                        disabled={estado == 3 ? true : false}
                        onClick={(e) => competidoresMatch(e.currentTarget.id)}
                        style={{
                            height: 60,
                            background:  competidores[0].resultado > 0 ? "green" :
                                         competidores[0].resultado == 0 ? "red" :
                                        "transparent",
                            borderStyle: "solid",
                            borderColor: "grey",
                            borderWidth: 1,
                            margin: 10,
                            width: 250,
                            color: competidores[0].resultado >0 ? "white" :
                                    competidores[0].resultado == 0 ? "white" :
                                        "white",
                        }}>{competidores[0].nombre}</Button>
 
                    <Typography>VS</Typography>
                    <Button style={{
                        height: 60, background: "transparent", margin: 10, width: 250, color: "darkgrey",
                        borderColor: "grey", borderWidth: 1,
                    }}>{"LIBRE"}</Button>

                </div>
            )

        } else {
            // no  hace nada
        }
    }

    return boton
}


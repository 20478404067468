import { Button, CircularProgress, Input, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react'
import Containter from '../../../Components/Container/Container';
import HeaderArea from './HeaderArea';
import { useEffect } from 'react';
import Road_Rage from "../../../Fonts/Road_Rage.otf"
import toast, { Toaster } from 'react-hot-toast';
import AreaAsistenciaCompetidor from './AreaAsistenciaCompetidor';
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie"

const Styles = makeStyles({




    letraRoad: {
        fontFamily: "Road_Rage",
        src: `
       local('Road_Rage'),
       local('Road_Rage'),
       url(${Road_Rage}) format('opentype')  `,

    },





})


const Areas = () => {
    const cookie = new Cookies()
    const navigate = useNavigate()
    const location = useLocation();



    var catLocation = 0
    if (location.state) {
        catLocation = location.state.categoria

        if (location.state.area) {
            cookie.set("area", location.state.area)
        }
    }
    let a = 0
    if (cookie.get("area")) {
        a = cookie.get("area")
    }
    const [area, setArea] = useState(a);
    const [liga, setLiga] = useState(2); // debo traerlo de algun end point  
    const [categorias, setCategorias] = useState([]);
    const [categoriaSelect, setCategoriaSelect] = useState(catLocation ? catLocation : 0);
    const [activeMatch, setActiveMatch] = useState(false)

    useEffect(() => {
        if (area > 0) {
            getCategoriaArea(area, liga)
        }
    }, [area])

    const notify = (x, nombre) => {
        if (x == 1) {
            toast.success(nombre + '.', { style: { background: 'lightgreen', color: "Black" } });
        } else {
            toast.error(nombre + '.', { style: { background: 'red', color: "white" } });
        }
    }
    const getCategoriaArea = async (area_id) => {
        try {

            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/getCategoriasArea/${area_id}`,
                {
                    method: "GET",
                    headers: { 'Content-type': 'application/json' },
                    // body: JSON.stringify(params)
                })
            let response = await res.json()
            if (response.code == 200) {

                setCategorias(response.data.categorias)
                console.log({ response })

            } else if (response.code == 400) {
                setCategorias([])
            }
        } catch (error) {
            console.log(error)
        }
    }
    const datosCategoria = (id) => {

        let d = categorias?.filter((e) => e.categoria_id == id)
        let data = d[0]

        return data
    }
    return (
        <Containter styles={{ width: "100%" }} more>
            <HeaderArea
                area={area}
                disabled={activeMatch}
                categorySelect={categoriaSelect}
                categorias={categorias}
                selectCate={(x) => navigate("/areas/AsistenciaCompetidor", { state: { categoria: datosCategoria(x) } })}
            />
            <Toaster position="top-center" />
        </Containter>
    )
}
export default Areas
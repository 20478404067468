import { Button, CircularProgress, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import AreaCentralTable from './AreasCentralTable';
import AreasCentralHeader from './AreasCentralHeader';
import toast, { Toaster } from 'react-hot-toast';

const Styles = makeStyles({

    tableContainer: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: "center",
        minWidth: "90%",
        borderStyle: "solid",

        borderRadius: 10,
        height: 100,
    },
    noDataContainer: {
        border: 'solid 1px white',
        padding: '0.2em 1em 0.2em 1em',
        borderRadius: '5px',
        minWidth: '90%',
    },
    filtros: {
        margin: 10
    },
    botonSi: {
        textAlign: 'center',
        background: "green",
        height: 50,
        margin: 10
    },
    botonNormal: {
        textAlign: 'center',
        background: "red",
        height: 50,
        margin: 10
    },
    botonNo: {
        textAlign: 'center',
        background: "red",
        height: 50,
        margin: 10
    },
    subtitulo: {
        color: "orange",
        textAlign: "center",
        width: "100%"
    },

    nombre: {
        cursor: "pointer",
    },
    text: {

        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        marginLeft: 20,
        marginRight: 20,
        height: 50,


    },
})

const AreaCentral = (props) => {
    const { notificacion, liga } = props;
    const clases = Styles()
    const [categorias, setCategorias] = useState([])
    const [datos, setDatos] = useState([])
    const [liga_id, setLiga_id] = useState(liga)
    const [dia, setDia] = useState(1)
    const [loading, setLoading] = useState(true)
    const [area, setarea] = useState(1
    )
    const [params, setParams] = useState({

        disciplina: 0,
        sexo: 0,
        categoria_desde: 1,
        categoria_hasta: 16,
        edad_desde: 0,
        edad_hasta: 99,
        liga_id: 1,
        area: 0,
        dia: 1,
        liga_id: liga.id

    })
    const [tablaPosiciones, setTablaPosiciones] = useState([])
    useEffect(() => {
        getCategoriasLiga()
    }, [params])


    const getCategoriasLiga = async () => {

        try {
            setLoading(true)
            console.log({ params })
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/central/getCategoriasLiga/${params.disciplina}&${params.sexo}&${params.area}&${params.liga_id}&${params.dia}&${params.categoria_desde}&${params.categoria_hasta}&${params.edad_desde}&${params.edad_hasta} `,
                {
                    method: "GET",
                    headers: { 'Content-type': 'application/json' },
                    // body: JSON.stringify(params)
                })
            let response = await res.json()
            if (response.code == 200) {

                setCategorias(response.categorias)
                setDatos(response.datos)
                setLoading(false)
            } else if (response.code == 400) {
                setCategorias([])
                setDatos(response.datos)
                setLoading(false)
            }


        } catch (error) {
            console.log(error)
            setLoading(false)
        }


    }

    const AsignaArea = async (a, cat_id) => {
        let categoria = { ...cat_id, liga_id: liga.id }
        try {
            let cat = { ...cat_id, dia: dia }
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/central/asignarArea/${a}`,
                {
                    method: "PUT",
                    headers: { 'Content-type': 'application/json' },
                    body: JSON.stringify(categoria)
                })
            let response = await res.json()
            if (response.code == 200) {

              //  getCategoriasLiga()
           notify(1,"Categoria Asignada al Area "+a)
            } else if (response.code == 400) {
             //   getCategoriasLiga()
                notify(0,"La categoria no pude ser asignada.")
            }
        } catch (error) {
            console.log(error)
        }


    }

    const HabilitarArea = async (categoria) => {

        try {
            let cat = { ...categoria, dia: dia }
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/central/habilitarArea`,
                {
                    method: "post",
                    headers: { 'Content-type': 'application/json' },
                    body: JSON.stringify(cat)
                })
            let response = await res.json()
            if (response.code == 200) {

                getCategoriasLiga()

            } else if (response.code == 400) {
                getCategoriasLiga()
            }
        } catch (error) {
            console.log(error)
        }

    }
    const handleFilter = (e) => {
        const field = e.target.name
        const value = e.target.value
        switch (field) {
            case 'disciplina':
                setParams({ ...params, disciplina: value })
                break;
            case 'sexo':
                setParams({ ...params, sexo: value })
                break;
            case 'area':
                setParams({ ...params, area: value })
                break;
            case 'liga':
                setParams({ ...params, liga_id: value })
                break;
            case 'categoriaDesde':
                setParams({ ...params, categoria_desde: value })
                break;
            case 'categoriaHasta':
                setParams({ ...params, categoria_hasta: value })
                break;
            case 'edadDesde':
                setParams({ ...params, edadDesde: value })
                break;
            case 'edadHasta':
                setParams({ ...params, edadHasta: value })
                break;
            case 'dia':
                setParams({ ...params, dia: value })
                break;
            default:
                break;
        }
    }
    const notify = (x, nombre) => {
        if (x == 1) {
            toast.success(nombre + '.', { style: { background: 'lightgreen', color: "Black" } });
        } else {
            toast.error(nombre + '.', { style: { background: 'red', color: "white" } });
        }
    }
    return ( // cambiar para que no se refresque la pantalla en areas central


        <div style={{ width: "100%" }}>
 <Toaster position="top-center" />
            {loading ? <CircularProgress /> : <>

                <AreasCentralHeader datos={datos} params={params} handleFilter={(e) => handleFilter(e)} />

                <AreaCentralTable

                    notificacion={(x, m) => notificacion(x, m)}
                    data={categorias}
                    loading={loading}
                    areas={datos.areas}
                    changeArea={(e, c) => { AsignaArea(e, c) }}
                    habilitaArea={(x) => { HabilitarArea(x) }}
                />

            </>}
        </div>



    )
}

export default AreaCentral
import { Navigate, Outlet } from "react-router"
import Cookies from "universal-cookie"

const PrivateRoutes = () => {
    const cookie = new Cookies()
    console.log("private", Boolean(!cookie.get("user")))
    if (!cookie.get("user")) return <Navigate to="/login" />

    return (
        <>
            <Outlet />
        </>
    )
}

export default PrivateRoutes

import React from "react"
import { Avatar, makeStyles } from "@material-ui/core"
import PerfilDefault from "../../Assets/Imagenes/perfil_default_tkd.png"

const Styles = makeStyles({
    avatar: {
        maxWidth: "30px",
        maxHeight: "30px",
    },
})

const CentralMenu = ({ user }) => {
    const style = Styles()
    return (
        <>
            {user.logedIn && (
                <Avatar
                    className={style.avatar}
                    alt={PerfilDefault}
                    src={user.foto ? process.env.REACT_APP_BACKEND_BASE_IMAGE_URL + user.foto : PerfilDefault}
                />
            )}
        </>
    )
    // }
}

export default CentralMenu

import { Button, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import Containter from '../../Components/Container/Container';

const Styles = makeStyles({
    tableContainer: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: "center",
        minWidth: "90%",
        borderStyle: "solid",

        borderRadius: 10,
        height: 100,
    },
    noDataContainer: {
        border: 'solid 1px white',
        padding: '0.2em 1em 0.2em 1em',
        borderRadius: '5px',
        minWidth: '90%',
    },
    filtros: {
        margin: 10
    },
    botonSi: {
        textAlign: 'center',
        background: "green",
        height: 50,
        margin: 10
    },
    botonNormal: {
        textAlign: 'center',
        background: "red",
        height: 50,
        margin: 10
    },
    botonNo: {
        textAlign: 'center',
        background: "red",
        height: 50,
        margin: 10
    },
    subtitulo: {
        color: "orange",
        textAlign: "center",
        width: "100%"
    },

    nombre: {
        cursor: "pointer",
    },
    text: {

        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        marginLeft: 20,
        marginRight: 20,
        height: 50,


    },
})

const LigaEncuentro = (props) => { 

    const clases = Styles()
    const [categorias, setCategorias] = useState([])
     const[liga_id,setLiga_id]= useState(1)
     const[dia,setDia]= useState(1)
  
     const [params, setParams] = useState([{
        id: 1,
        disciplina: "",
        sexo: "",
        categoria_desde: "",
        categoria_hasta: "",
        edad_desde: 0,
        edade_hasta: 99,
        altura_desde: 50,
        altura_hasta: 200,
        peso_desde: 1,
        peso_hasta: 180,

    }])
    const [tablaPosiciones, setTablaPosiciones] = useState([])
    
     

    useEffect(() => {
        getCategoriasLiga()
    }, [])

  
  const getCategoriasLiga= async ()=>{

    try {

        let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/central/getCategoriasLiga/${liga_id}&${dia} `,
            {
                method: "GET",
                headers: { 'Content-type': 'application/json' },
                // body: JSON.stringify(params)
            })
        let response = await res.json()
        if (response.code == 200) {
           
            setCategorias(response.categorias)
       
        } else if (response.code == 400) {
            setCategorias([])
        }
    } catch (error) {
        console.log(error)
    }


  }
  
  const AsignaArea= async ()=>{

    try {

        let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/central/getCategoriasLiga/${liga_id}&${dia} `,
            {
                method: "GET",
                headers: { 'Content-type': 'application/json' },
                // body: JSON.stringify(params)
            })
        let response = await res.json()
        if (response.code == 200) {
           
            setCategorias(response.categorias)
       
        } else if (response.code == 400) {
            setCategorias([])
        }
    } catch (error) {
        console.log(error)
    }


  }
 

 

    return (

        <div>

             {categorias?.map((c)=>{
                    return(<Typography>{`ID:${c.id}      ${c.disciplina} ${c.nombre}  ESTADO: ${c.estado} ${c.area>=1?"area"+c.area:"sin asignar"}`}</Typography> )
             })}


 

        </div>

     

    )
}

export default LigaEncuentro
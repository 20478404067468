import { Button, IconButton, Input, MenuItem, Select, Tooltip, Typography, TextField, Modal, CircularProgress, FormControl, InputLabel, } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { DataGrid } from '@mui/x-data-grid'
import React, { useState, useEffect } from 'react'
// import Containter from '../../Components/Container/Container';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import Edit_Inscriptos from './Edit_Inscriptos';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import BadgeIcon from '@mui/icons-material/Badge';
import RefreshIcon from '@mui/icons-material/Refresh';
import PDFCredenciales from '../PDFplanillas/PDFCredenciales';
import PDFInscriptos from '../PDFplanillas/PDFInscriptos';
import toast, { Toaster } from 'react-hot-toast';
const Styles = makeStyles({
    tableContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: "90%",
        // borderStyle: "solid",
        alignContent:"center",
        borderWidth: 2,
        borderRadius: 10,
        //height: 100,
    },
    renglon: {
        padding: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center",
        width: "100%",
        borderStyle: "solid",
        borderWidth: 2,
        borderRadius: 10,
        height: 90,
    },
    seccionNumero: {
        display: 'flex',
        flexDirection: "column",
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 10,
        borderColor: "grey",
        alignItems: "center",
        justifyContent: "center",
        height: 70,
        width: 180,
        margin: 5


    },
    seccion: {
        display: 'flex',
        flexDirection: "column",
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 10,
        borderColor: "grey",
        alignItems: "center",
        justifyContent: "center",
        height: 70,
        width: 400,
        margin: 5


    },
    seccionB: {
        display: 'flex',
        alignItems: "center",

    },
    textoB: {
        width: "100%",
        textAlign: "center",
        height: 10,
        fontSize: 12,

    },
    noDataContainer: {
        border: 'solid 1px white',
        padding: '0.2em 1em 0.2em 1em',
        borderRadius: '5px',
        minWidth: '90%',
        justifyContent:"center",
        alignContent:"center"
    },
    filtros: {
        margin: 10,
        width: 150,
        height: 35,
        display: "flex",
        alignItems: "center"
    },
    filtrosNumero: {
        margin: 10,
        backgroundColor: "white",
        borderRadius: '5px',
        color: "black",
        width: 60,
        height: 35,
        display: "flex",
        alignItems: "center",

        paddingLeft: 15,
    },
    boton: {
        background: "red",
        textAlign: 'center',
    },
    noDataText: {
        textAlign: 'center',
    },
})

const Inscriptos = ({ datos, deleteInscripto, changePage, fechas, refresh, addCompetidor, infoLiga }) => {

    const clases = Styles()

    let info = infoLiga;
    const [credencial, setCredencial] = useState({ open: false, datos: "", dni: "" });
    const [PDFI, setPDFI] = useState({ open: false, datos: "" });
    const [modalConfirma, setModalConfirma] = useState({ open: false, competidor: "" });
    const [datosCredenciales, setDatosCredenciales] = useState([]);
    const [loading, setLoading] = useState(false)
    const [modalState, setModalState] = useState(false);
    const [total, setTotal] = useState(0);
    const [inscriptos, setInscriptos] = useState([]);

    const [values, setValues] = useState({
        dni: '',
        password: '',
        nombre: '',
        apellido: '',
        nacionalidad: '',
        sexo: '',
        categoria_id: '',
        email: '',
        nacimiento: '',
        telefono: '',
    })

    const [params, setParams] = useState({
        nombre: '',
        apellido: '',
        sexo: 3,
        edad: 0,
        categoria_id: 0,
        instructor_id: 0,
        page: 0,
    })



    useEffect(() => {

        fetchShowCompetidores()

    }, [params])


    const handleModalState = (e, value) => {

        setValues(value)

        setModalState(e);

    }
    const determinaInicioFecha = () => {

        let fecha = "NONOsi";
        let cont = 0;
        let desactivar = false;
        let CompetidoresSinLLave = false;

        info.llaves.map((x) => {
            if (x.sin_llave > 0) {
                CompetidoresSinLLave = true;
            }
        })


        fechas.sort((a, b) => {
            if (a.dia_num < b.dia_num) {
                return -1
            }
            if (a.dia_num > b.dia_num) {
                return 1
            }
            return 0
        });

        fechas.map((x) => {
            if (cont == 0 && x.terminado == 0 && x.iniciada == 0) {
                cont = cont + 1
                fecha = x.dia_num
                desactivar = false
            } else if (cont == 0 && x.terminado == 0 && x.iniciada == 1) {
                cont = cont + 1
                fecha = x.dia_num
                desactivar = true
            }

        })

        let d = false
        if (CompetidoresSinLLave == true || desactivar == true) { d = true }

        return { dia: fecha, activar: d }
    }
    const determinaPermiteEditar = () => {

        let fecha = "NONOsi";
        let cont = 0;
        let desactivar = false;
        let CompetidoresSinLLave = false;

        info.llaves.map((x) => {
            if (x.con_llave > 0) {
                CompetidoresSinLLave = true;
            }
        })


        fechas.sort((a, b) => {
            if (a.dia_num < b.dia_num) {
                return -1
            }
            if (a.dia_num > b.dia_num) {
                return 1
            }
            return 0
        });

        fechas.map((x) => {
            if (cont == 0 && x.terminado == 0 && x.iniciada == 0) {
                cont = cont + 1
                fecha = x.dia_num
                desactivar = false
            } else if (cont == 0 && x.terminado == 0 && x.iniciada == 1) {
                cont = cont + 1
                fecha = x.dia_num
                desactivar = true
            }

        })

        let d = false
        if (CompetidoresSinLLave == true || desactivar == true) { d = true }

        return { dia: fecha, activar: d }
    }

    const fetchShowCompetidores = async () => {
        setLoading(true)
        try {
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/showCompetidores`,
                {
                    method: "post",
                    headers: { 'Content-type': 'application/json' },
                    body: JSON.stringify(params)
                })
            let response = await res.json()
            if (response.code == 200) {
                setInscriptos(response.res)
                setTotal(response.total)
                setLoading(false)
            } else if (response.code == 400) {
                console.log("error")
                setLoading(false)
            }
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    const fetchAddCompetidor = async (datos) => {
        setLoading(true)
        try {
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/addCompetidorInv`,
                {
                    method: "POST",
                    headers: { 'Content-type': 'application/json' },
                    body: JSON.stringify(datos)
                })
            let response = await res.json()
            if (response.code == 200) {
                fetchShowCompetidores();
                notify(1, response.mensaje)
            } else {
                setLoading(false)
                notify(0, response.mensaje)
            }
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    const handleCredencialPrint = (dni) => {
        if (!dni) {
            setDatosCredenciales(datos.res)
        } else {
            let d = datos.res.filter((x) => x.dni == dni)
            setDatosCredenciales(d[0]);

        }
        setCredencial(true)
    }

    const fetchDatosFullpdf = async (tipo, dni) => {
        try {
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/getPDFFullData `,
                {
                    method: "GET",
                    headers: { 'Content-type': 'application/json' },

                })
            let response = await res.json()
            if (response.code == 200) {
                if (tipo == 1) {
                    setPDFI({ open: true, datos: response.inscriptos })
                } else {
                    setCredencial({ open: true, datos: response.inscriptos, dni: dni })
                }
            } else if (response.code == 400) {

            }
        } catch (error) {
            console.log(error)
        }
    }


    const ConfirmaBorrar = (competidor) => {
        setModalConfirma({ open: true, competidor: competidor })
    }

    const filter = (x) => {
        let nombre = x.target.name
        let valor = x.target.value
        switch (nombre) {

            case "sexo":
                setParams({ ...params, sexo: valor, page: 0 })
                break;
            case "categoria_id":
                setParams({ ...params, categoria_id: valor, page: 0 })
                break;
            case "nombre":
                setParams({ ...params, nombre: valor, page: 0 })
                break;
            case "apellido":
                setParams({ ...params, apellido: valor, page: 0 })
                break;
            case "edad":
                setParams({ ...params, edad: valor, page: 0 })
                break;

            default:
                break;
        }

    }
    const handleChangePage = (d) => {

        setParams({ ...params, page: d });
    };

    const clearFilters = () => {
        setParams({ ...params, nombre: '', apellido: '', sexo: 3, categoria_id: 0, instructor_id: 0, edad: 0 })
    }
    const notify = (x, nombre) => {
        if (x == 1) {
            toast.success(nombre + '.', { style: { background: 'lightgreen', color: "Black" } });
        } else {
            toast.error(nombre + '.', { style: { background: 'red', color: "white" } });
        }
    }

    const columns = [
        {
            field: 'nombre_completo', headerName: 'Nombre', width: 250,
            renderCell: row => { return (<Typography style={{ fontSize: 13 }} > {row.row.nombre} {row.row.apellido}</Typography>) }
        },
        { field: 'dni', headerName: 'DNI', minWidth: 180 },
        {
            field: 'categoria', headerName: 'Categoria', width: 150,
            renderCell: row => { return (<Typography style={{ fontSize: 10 }} > {row.row.categoria}  </Typography>) }
        },
        { field: 'instructor', headerName: 'Instructo', minWidth: 150 },
        { field: 'edad', headerName: 'Edad', minWidth: 80 },
        {
            field: 'Editar', headerName: 'Editar', width: 110,
            renderCell: row => {
                return (<IconButton
                    //  disabled={determinaPermiteEditar().activar}
                    style={{ color: "white", width: "100%" }}
                    onClick={() => handleModalState(true, row.row)}>
                    <Tooltip title="datos competidor">
                        <CreateOutlinedIcon fontSize="medium" />
                    </Tooltip>
                </IconButton>
                )
            }
        },
        { field: 'edad', headerName: 'Edad', minWidth: 80 },
        {
            field: 'credencial', headerName: 'Credencial', width: 110,
            renderCell: row => {
                return (

                    <IconButton

                        style={{ color: "white", width: "100%" }}
                        onClick={() => { fetchDatosFullpdf(2, row.row.dni) }}>

                        <Tooltip title="Imprimir Credencial">
                            <BadgeIcon fontSize="medium" />
                        </Tooltip>
                    </IconButton>
                    // </a>
                )
            }
        },
        {
            field: 'desvincular', headerName: 'Desvincular', width: 110,
            renderCell: row => {
                return (<IconButton
                    disabled={determinaPermiteEditar().activar}
                    style={{ color: "white", width: "100%" }}
                    onClick={() => { ConfirmaBorrar(row.row) }}>
                    <Tooltip title="Quitar ">
                        <PlaylistRemoveIcon fontSize="medium" />
                    </Tooltip>
                </IconButton>
                )
            }
        },
    ]


    return (
        <div>
            <Toaster position="top-center" />
            {inscriptos ? <>

                {credencial.open == true && <PDFCredenciales listo={() => setCredencial({ ...credencial, open: false })} dni={credencial.dni} alumnos={credencial.datos} />}

                {PDFI.open == true && <PDFInscriptos listo={() => setPDFI({ ...PDFI, open: false })} alumnos={PDFI.datos} />}


                <Button

                    style={{ background: "green", margin: 20, color: "black" }}
                    onClick={() => setModalState(true)}
                >+ Competidor</Button>

                <Button onClick={() => fetchDatosFullpdf(2)} style={{ background: "red", margin: 20 }}  >IMPRIMIR CREDENCIAL</Button>


                <Button
                    style={{ background: "darkgreen", margin: 20 }}
                    onClick={() => refresh()}
                ><Tooltip title="Actualizar">
                        <RefreshIcon fontSize="medium" />
                    </Tooltip></Button>
                <Button

                    style={{ background: "green", margin: 20, color: "black" }}
                    onClick={() => fetchDatosFullpdf(1)}
                >DESCARGAR</Button>

                <div style={{ width: "90%", borderStyle: "solid", borderWidth: 0.5, padding: 10, backgroundColor: "darkred" }}>

                    <FormControl variant='outlined' style={{ minWidth: 120, margin: 5, paddingBottom: 0 }} size="small" >
                        <InputLabel id="disciplina" style={{ color: 'white' }}>Disciplina</InputLabel>
                        <Select
                            label="categoria"
                            name='categoria'
                            id='categoria'
                            defaultValue={params.categoria_id}
                            value={params.categoria_id}
                            variant='outlined'
                            onChange={(x) => filter(x)}
                        >
                            <MenuItem key={0} value={0}>Todas</MenuItem>
                            {datos?.disciplinas?.map((x) => {
                                return (<MenuItem key={x.id} value={x.id}>{x.nombre}</MenuItem>)
                            })
                            }
                        </Select>
                    </FormControl>


                    <TextField
                        style={{ minWidth: 120, margin: 5, paddingBottom: 0 }}
                        InputProps={{ inputProps: { min: 0 } }}
                        variant='outlined'
                        size='small'
                        label='Nombre'
                        name='nombre'
                        value={params.nombre}
                        onChange={(x) => filter(x)}
                    />
                    <TextField
                        style={{ minWidth: 120, margin: 5, paddingBottom: 0 }}
                        InputProps={{ inputProps: { min: 0 } }}
                        variant='outlined'
                        size='small'
                        label='Apellido'
                        name='apellido'

                        value={params.apellido}
                        onChange={(x) => filter(x)}
                    />

                    <FormControl variant='outlined' style={{ minWidth: 120, margin: 5, paddingBottom: 0 }} size="small" >
                        <InputLabel id="disciplina" style={{ color: 'white' }}>Sexo</InputLabel>
                        <Select
                            label="sexo"
                            name='sexo'
                            id='sexo'
                            defaultValue={params.sexo}
                            value={params.sexo}
                            variant='outlined'
                            onChange={(x) => filter(x)}
                        >
                            <MenuItem key={3} value={3}>Mixto</MenuItem>
                            <MenuItem key={1} value={1}>Femenino</MenuItem>
                            <MenuItem key={2} value={2}>Masculino</MenuItem>
                        </Select>
                    </FormControl>
                    <Button onClick={clearFilters} style={{ background: "red", minWidth: 120, margin: 5, paddingBottom: 0, height: 40 }}>LIMPIAR</Button>

                </div>

                <div className={clases.tableContainer}>
                    {
                        inscriptos.length ?
                            <DataGrid
                                autoHeight={true}
                                disableColumnMenu
                                disableColumnFilter
                                disableSelectionOnClick
                                rows={inscriptos}
                                pageSize={15}
                                loading={loading}
                                columns={columns}
                                paginationMode='server'
                                isRowSelectable={false}
                                onPageChange={handleChangePage}
                                //  rowsPerPageOptions={[10, 15, 20]}
                                rowCount={total}
                                page={params.page}
                            />
                            :
                            <div className={clases.noDataContainer}>
                              <div style={{width:"100%",display:"flex"}}>
                                 <CircularProgress style={{color:"red"}}/>
                                </div>
                            
                                <h2 className={clases.noDataText}>Buscando Inscriptos....</h2>
                            </div>
                    }
                </div>

                <Modal open={modalState} style={{ alignContent: "center" }}>

                    <Edit_Inscriptos
                        handleSubmit={(x) => fetchAddCompetidor(x)}
                        value={values}
                        cerrar={(x) => handleModalState(false)}
                    />

                </Modal>

            </>
                :
                <>
                    <CircularProgress />
                    <Typography>BUSCANDO INSCRIPTOS....</Typography>
                </>


            }


            <Modal open={modalConfirma.open} style={{ alignContent: "center", justifyContent: "center" }}>

                <div style={{ width: "20%", marginLeft: "40%", height: "40%", background: "black", alignContent: "center", justifyItems: "center" }}  >
                    <Typography style={{ textAlign: "center", marginBottom: 30 }} variant='h4'  >ATENCION!!</Typography>

                    <Typography style={{ textAlign: "center" }}  >Desea Quitar  a {modalConfirma.competidor.nombre} {modalConfirma.competidor.apellido}??</Typography>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <Button style={{ background: "red", width: 100, margin: 20 }} onClick={() => { setModalConfirma({ ...modalConfirma, open: false }); deleteInscripto(modalConfirma.competidor.dni) }}   > Confirmar</Button>
                        <Button style={{ background: "red", width: 100, margin: 20 }} onClick={() => setModalConfirma({ ...modalConfirma, open: false })}   > Cancelar</Button>
                    </div>

                </div>

            </Modal>



        </div>



    )
}

export default Inscriptos
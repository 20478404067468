import React, { useEffect, useState } from "react"
import Container from "../../Components/Container/Container"
import { fetchResource, usePermitions } from "../../Functions/Functions"
import CircularProgress from "@material-ui/core/CircularProgress"
import DefaultMessage from "../../Components/DefaultMessage/DefaultMessage"
import Modal from "@material-ui/core/Modal"
import ModalBody from "../../Components/ModalBody/ModalBody"
import { Button, makeStyles, TextField } from "@material-ui/core"
import toast, { Toaster } from "react-hot-toast"
import { useSelector } from "react-redux"
import { MatriculadirectorTopInformation } from "./MatriDirTopInfo"
import { MatriDataFilters } from "./MatriDataFilter"
import MatriTable from "./MatriTable"
import { useNavigate } from "react-router-dom"

const Styles = makeStyles({
    filtersContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        minWidth: "100%",
        flexWrap: "wrap",
    },
    filterInput: {
        alignSelf: "flex-end",
    },
    recordCounter: {
        alignSelf: "end",
    },
    ageInputs: {
        maxWidth: "5em",
    },
    ctaButton: {
        backgroundColor: "red",
    },
    h2: {
        backgroundColor: "red",
    },
})

const MatriculaDirector = () => {
    const state = useSelector((state) => ({ ...state["basicDataReducer"] }))

    const { basicData } = state
    const { role_id } = usePermitions()
    const paramsInitialValues = {
        page: 0,
        nombre: "",
        apellido: "",
        escuela: "",
        asociacion: "",
        categoria: "",
        instructor: "",
        edadDesde: "",
        edadHasta: "",
    }
    const navigate = useNavigate()
    const [records, setRecords] = useState(0)
    const [params, setParams] = useState(paramsInitialValues)
    const [modalOpen, setModalOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [matriculas, setmatriculas] = useState([])
    const [alumnos, setAlumnos] = useState([])
    const [noInst, setNoInst] = useState([])
    const [filtro, setFiltro] = useState("")
    const [filteredAlumnos, setFilteredAlumnos] = useState([])
    const [name, setName] = useState("")
    const [filteredData, setFilteredData] = useState([])
    const [modal, setModal] = useState(false)

    const [page, setPage] = useState(1)
    const [ultimaPage, setultimaPage] = useState(0)
    const a = new Date()
    const anio = a.getFullYear()
    const [cant, setCant] = useState({
        activos: 250,
        pago: 20,
        nopago: 24,
    })

    useEffect(() => {
        const fetchMatriculas = async () => {
            const [data, error] = await fetchResource(
                `/instructor/matriculas/showAlumnosInstructor?page=${params.page + 1}&nombre=${params.nombre}&apellido=${params.apellido}&escuela=${params.escuela}&asociacion=${params.asociacion}&categoria=${params.categoria}&instructor=${params.instructor}&edadDesde=${params.edadDesde}&edadHasta=${params.edadHasta}&actives=1`,
                "get",
                true,
                null
            )

            if (data) {
                let reg = []

                if (data.code == 200) {
                    const d = data.data.alumnos
                    d.map((x) => {
                        reg.push({ ...x, nombre: x.nombre + " " + x.apellido })
                    })
                    setRecords(data.data.total_records)
                    setCant({
                        activos: data.data.total_records,
                        pago: data.data.matriculas_pagas,
                        nopago: data.data.matriculas_impagas,
                    })
                }
                if (data.code == 404) {
                    reg = 0
                }
                setmatriculas(reg)
                setLoading(false)
            } else {
                setLoading(false)
                setmatriculas([])
                console.log(error)
            }
        }

        fetchMatriculas()
    }, [params])

    const handleChange = (e) => {
        setFiltro(e.target.value)
    }

    const notify = (x, nombre) => {
        if (x == 1) {
            toast.success("Registro Correcto de " + nombre + ".", {
                style: { background: "lightgreen", color: "Black" },
            })
        } else {
            toast.error("Registro Eliminado de " + nombre + ".", { style: { background: "red", color: "white" } })
        }
    }

    const handleRemove = async (id) => {
        let parametro = { year: anio, users: [id] }
        const [data, error] = await fetchResource("/instructor/matriculas/delete", "delete", true, parametro)

        if (data.code == 200) {
            const nombre = matriculas.filter((x) => x.id == id)
            notify(0, nombre[0].apellido)
            setCant({ ...cant, pago: cant.pago - 1, nopago: cant.nopago + 1 })
        } else {
            alert("error borrado Matricula")
        }
    }

    const RegMat = (id) => {
        matriculas.map((x) => {
            if (x.id == id) {
                if (x.value == true) {
                    Fdelete(id)
                    //   setModalOpen(true)
                } else {
                    Fsave(id)
                }
            } else {
                //nada
            }
        })
    }

    const Fsave = async (id) => {
        const body = { year: anio, users: [id] }
        const [data, error] = await fetchResource(`/instructor/matriculas/registerSpecificYear`, "post", true, body)

        if (data.code == 200) {
            changeMatricula(id)
            const nombre = matriculas.filter((x) => x.id == id)
            notify(1, nombre[0].apellido)
            setCant({ ...cant, pago: cant.pago + 1, nopago: cant.nopago - 1 })
        } else {
            alert("error")
        }
    }

    const Fdelete = (id) => {
        handleRemove(id)

        changeMatricula(id)
    }

    const handlePageChange = (page) => {
        setParams({ ...params, page: page })
    }

    const changeMatricula = (id) => {
        const reg = []

        matriculas.map((x) => {
            if (x.id == id) {
                reg.push({ ...x, current: !x.current })
            } else {
                reg.push({ ...x, current: x.current })
            }
        })
        setmatriculas(reg)
    }

    const handleDelete = async (id) => {
        setModalOpen(false)
    }

    const clearFilters = () => {
        setParams(paramsInitialValues)
    }

    const handleFilter = (e) => {
        const field = e.target.name
        const value = e.target.value
        switch (field) {
            case "nombre":
                setParams({ ...params, nombre: value })
                break
            case "apellido":
                setParams({ ...params, apellido: value })
                break
            case "categoria":
                setParams({ ...params, categoria: value })
                break
            case "escuela":
                setParams({ ...params, escuela: value })
                break
            case "instructor":
                setParams({ ...params, instructor: value })
                break
            case "asociacion":
                setParams({ ...params, asociacion: value })
                break
            case "edadDesde":
                setParams({ ...params, edadDesde: value })
                break
            case "edadHasta":
                setParams({ ...params, edadHasta: value })
                break
            default:
                break
        }
    }
    const handleFilterChange = (e) => {
        const field = e.target.name
        const value = e.target.value

        switch (field) {
            case "name":
                setName(value)
                break

            default:
                break
        }
    }

    const handleClose = () => {
        setModalOpen(false)
    }

    return (
        <div className="alumnos-container">
            <Container title="Matricula Anual" more>
                <MatriculadirectorTopInformation total={cant.activos} pago={cant.pago} impago={cant.nopago}>
                    {" "}
                </MatriculadirectorTopInformation>
                <MatriDataFilters
                    basicData={basicData}
                    handleFilter={handleFilter}
                    clearFilters={clearFilters}
                    params={params}
                    userRole={role_id}
                />
                <Toaster position="center-center" />

                {matriculas.length > 0 ? (
                    <MatriTable
                        data={matriculas}
                        changePage={handlePageChange}
                        pageData={{ currentPage: params.page, records: records }}
                        loading={loading}
                        add={Fsave}
                        remove={Fdelete}
                    >
                        {" "}
                    </MatriTable>
                ) : (
                    <DefaultMessage title={"No tiene alumnos registrados"} logo />
                )}
            </Container>

            <Modal
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <ModalBody handleDelete={handleDelete} setModalOpen={setModalOpen} modalOpen={modalOpen} />
            </Modal>
        </div>
    )
}

export default MatriculaDirector

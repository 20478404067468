import Container from "../Components/Formularios/Container"
import React, { useState } from "react"
import BodyLayout from "../Components/Layouts/BodyLayout"
import PresetHandler from "../Components/Formularios/PasswordReset/PresetHandler"
import Logo from "../Assets/Imagenes/Logo_fit.png"
import { useParams } from "react-router-dom"

const PasswordResetWithToken = (props) => {
    const { token } = useParams()

    return (
        <BodyLayout>
            <Container logo={Logo}>
                <h2>Ingresa una nueva contraseña</h2>
                <PresetHandler token={token} />
            </Container>
        </BodyLayout>
    )
}

export default PasswordResetWithToken

import { createMuiTheme, Paper } from "@material-ui/core"
import { blue } from "@material-ui/core/colors"

const plataformaTkdTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#0f0f0e",
        },
        secondary: {
            main: "#fff",
        },
        error: {
            main: "#f00",
        },
        info: {
            main: "#494949",
        },
        success: {
            main: "#00ff00",
        },
        action: {
            active: "#fff",
            main: "#000f",
            hover: "#f00",
            selected: "#fbb",
            disabled: "#999",
            // disabled: '#6b6667',
            // disabledBackground: '#ffd',
        },
        background: {
            default: "#fff",
            paper: "#000d",
        },
        divider: "#424242",
        text: {
            menu: "#000",
            primary: "#fff",
            secondary: "#fff",
            error: "#f00",
        },
    },
    overrides: {
        MuiAppBar: {
            root: {
                height: "50px",
                display: "flex",
                justifyContent: "center",
            },
        },
        MuiToolbar: {
            root: {
                height: "40px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            },
        },
        MuiIconButton: {
            root: {
                borderRadius: "1px",
            },
        },
        MuiButton: {
            root: {
                // margin:'8px',
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
            },
            contained: {
                disabled: {
                    backgroundColor: "grey",
                },
            },
        },
        MuiFormControl: {
            root: {
                width: 201,
            },
        },
        MuiTextField: {
            root: {
                backgroundColor: "#111a",
                // margin: '8px',
                width: 201,
            },
        },
        MuiSelect: {
            root: {
                backgroundColor: "#111a",
            },
        },
    },
})

export default plataformaTkdTheme

import React, { useEffect, useState } from "react"
import Container from "../Components/Container/Container"
import { fetchResource, useDataFilter, useRageFilter } from "../Functions/Functions"
import CircularProgress from "@material-ui/core/CircularProgress"
import AlumnosExamenTable from "../Components/Table/AlumnosExamenTable"
import DefaultMessage from "../Components/DefaultMessage/DefaultMessage"
import Modal from "@material-ui/core/Modal"
import ModalBody from "../Components/ModalBody/ModalBody"
import Button from "@material-ui/core/Button"
import { useNavigate, useParams } from "react-router-dom"
import { makeStyles } from "@material-ui/styles"
import { TextField, Typography } from "@material-ui/core"
import Selection from "../Components/Formularios/Select"
import { useSelector } from "react-redux"
import { Toaster } from "react-hot-toast"
import toast from "react-hot-toast"

const useStyles = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.down("sm")]: {
            top: "50vh",
            left: "1vw",
        },
        position: "absolute",
        top: "40vh",
        left: "50vw",
        minWidth: 370,
        maxWidth: 400,
        padding: 10,
        background: "linear-gradient(to right,  #101010, #151515 , #202020 , #303030 , #434343)",
        boxShadow: 10,
        borderRadius: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    alertText: {
        color: "#e00",
    },
    text: {
        textAlign: "center",
    },
    button: {
        margin: 4,
        backgroundColor: "#e00",
    },
    buttonSection: {
        display: "flex",
    },
    sideButtonSection: {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
    },
}))

const DirectorInscriptos = ({ user }) => {
    const style = useStyles()
    const params = useParams("examen_id")
    const navigate = useNavigate()
    const { examen_id } = params

    const state = useSelector((state) => ({ ...state["basicDataReducer"] }))

    const { categorias } = state.basicData

    const [loading, setLoading] = useState(true)
    const [modalOpen, setModalOpen] = useState(null)
    const [promote, setPromote] = useState(false)
    const [alumnos, setAlumnos] = useState(null)
    const [alumnoToUnsign, setAlumnoToUnsign] = useState(null)

    const [setNameFilter, nameFilterValue, filteredNameData] = useDataFilter(alumnos, "fullName")
    const [setFrom, setTo, from, to, filteredAgeData] = useRageFilter(filteredNameData, "edad")
    const [setCategoriaFilter, categoriaFilterValue, filteredCategoriaData] = useDataFilter(
        filteredAgeData,
        "categoria",
        "exact"
    )

    useEffect(() => {
        const fetchAlumnos = async () => {
            const [data, error] = await fetchResource(
                `/director/examen/showAllInscriptos/${examen_id}`,
                "get",
                true,
                null
            )

            if (data) {
                setLoading(false)

                if (data.code == 200) {
                    setAlumnos(
                        data.inscriptos.map((alumno) => {
                            return {
                                ...alumno,
                                fullName: `${alumno.nombre} ${alumno.apellido}`,
                                categoria: alumno.categoria.nombre,
                                escuela: alumno.escuela?.abreviacion,
                            }
                        })
                    )
                }
            } else {
                setLoading(false)
            }
        }
        fetchAlumnos()
    }, [])

    const unsignToEvent = async (id) => {
        const parametro = {
            user_id: id,
            examen_id: examen_id,
        }
        const [data, error] = await fetchResource("/instructor/examen/unsignToExam", "post", true, parametro)

        if (data) {
            setLoading(false)
            let filterAlumnos = alumnos.filter((alumno) => alumno.id !== id)
            if (filterAlumnos.length) {
                setAlumnos(filterAlumnos)
            } else {
                setAlumnos(null)
            }
        } else {
            setLoading(false)
        }
        setModalOpen(false)
        setAlumnoToUnsign(null)
    }

    const handleDelete = (id) => {
        let filterAlumno = alumnos.find((alumno) => alumno.id === id)
        if (filterAlumno)
            setAlumnoToUnsign({
                id: filterAlumno.id,
                fullName: `${filterAlumno.nombre}`,
            })
        setModalOpen(false)
    }

    const hadndlePromote = async () => {
        const [data, error] = await fetchResource(`/director/examen/promoteCategories/${examen_id}`, "put", true)
        if (data) {
            toast.success("Categorias promovidas con exito", { style: { background: "lightgreen", color: "Black" } })
            setPromote(false)
            return
        }
        toast.error("Ocurrio un error.\n Intentelo nuevamente mas tarde", {
            style: { background: "red", color: "Black", textAlign: "center" },
        })
        console.log(error)
    }

    // Modal

    const handleClose = () => {
        setModalOpen(false)
        setPromote(false)
    }

    // Modal

    return (
        <div className="alumnos-container">
            <Container title="Alumnos Inscriptos" more={true}>
                {alumnos && (
                    <>
                        <Button
                            style={{ backgroundColor: "red", marginTop: "20px" }}
                            onClick={() => {
                                window
                                    .open(
                                        `http://prod.somacontact.com/certificado.php?examen_id=${examen_id}`,
                                        "Certificados"
                                    )
                                    .focus()
                            }}
                        >
                            Imprimir Todo
                        </Button>
                        <div style={{ display: "flex", marginTop: "1em" }}>
                            <TextField
                                type="text"
                                name="nombre"
                                label="Nombre o Apellido"
                                value={nameFilterValue}
                                onChange={setNameFilter}
                                size="small"
                                variant="outlined"
                            />
                            <Selection
                                label="Categoria"
                                name="categoria"
                                value={categoriaFilterValue}
                                onChange={setCategoriaFilter}
                                options={categorias}
                            />
                            <TextField
                                variant="outlined"
                                size="small"
                                label="Edad-Desde"
                                name="from"
                                type="number"
                                value={from}
                                onChange={setFrom}
                            />
                            <TextField
                                variant="outlined"
                                size="small"
                                label="Edad-Hasta"
                                name="to"
                                type="number"
                                value={to}
                                onChange={setTo}
                            />
                        </div>
                        <div className={style.sideButtonSection}>
                            {/* <Button onClick={() => setPromote(true)} className={style.button}>
                                PROMOVER CATEGORIAS
                            </Button> */}
                            <Button onClick={() => navigate(-1)} className={style.button}>
                                Volver
                            </Button>
                        </div>
                    </>
                )}
                <Toaster position="center-center" />

                {loading ? (
                    <CircularProgress color="secondary" />
                ) : (
                    <>
                        {filteredCategoriaData ? (
                            <AlumnosExamenTable
                                director
                                setModalOpen={handleDelete}
                                data={filteredCategoriaData}
                                examen_id={examen_id}
                                notas
                            />
                        ) : (
                            <DefaultMessage logo title={"No hay alumnos inscriptos"} />
                        )}
                    </>
                )}
            </Container>
            <Modal
                open={alumnoToUnsign}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <UnsignExamenModalBody
                    setAlumnoToUnsign={setAlumnoToUnsign}
                    deleteCallback={unsignToEvent}
                    student={alumnoToUnsign}
                />
            </Modal>
            <Modal
                open={promote}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <PromoteCategoryModalBody setPromote={setPromote} promoteCallback={hadndlePromote} />
            </Modal>
        </div>
    )
}

export default DirectorInscriptos

export const UnsignExamenModalBody = ({ setAlumnoToUnsign, deleteCallback, student }) => {
    const style = useStyles()

    return (
        <div className={style.paper}>
            <Typography variant="h5" className={style.alertText}>
                Atencion
            </Typography>
            <>
                <Typography
                    variant="h6"
                    className={style.text}
                >{`¿Desea eliminar del examen al alumno ${student.fullName}?`}</Typography>
                <section className={style.buttonSection}>
                    <Button className={style.button} onClick={() => deleteCallback(student.id)} size="small">
                        Borrar
                    </Button>
                    <Button
                        className={style.button}
                        onClick={() => {
                            setAlumnoToUnsign(null)
                        }}
                        size="small"
                    >
                        Cancelar
                    </Button>
                </section>
            </>
        </div>
    )
}

export const PromoteCategoryModalBody = ({ setPromote, promoteCallback }) => {
    const style = useStyles()

    return (
        <div className={style.paper}>
            <Typography variant="h5" className={style.alertText}>
                Atencion
            </Typography>
            <>
                <Typography
                    variant="h6"
                    className={style.text}
                >{`¿Desea promober las categorias del examen?`}</Typography>
                <section className={style.buttonSection}>
                    <Button className={style.button} onClick={() => promoteCallback()} size="small">
                        Promover
                    </Button>
                    <Button
                        className={style.button}
                        onClick={() => {
                            setPromote(null)
                        }}
                        size="small"
                    >
                        Cancelar
                    </Button>
                </section>
            </>
        </div>
    )
}

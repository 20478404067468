
import { Button, Input, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';

import React, { useState } from 'react'

const Styles = makeStyles({
    renglon: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        marginTop: "10px"
    },
    data: {
        width: "33%",
        textAlign: "center",
        fontSize:20
    },
    data2: {
        width: "25%",
        textAlign: "center",
        fontSize: 19
    }


})


const DataCategoty = (props) => {
    const { data, titulo } = props
    const clases = Styles();
    return (
        <div style={{ borderStyle: "solid", borderWidth: 1, borderRadius: 5, width: "100%", textAlign: "center", padding: 30 }}>
            {titulo && <Typography variant="h6"> {titulo.toUpperCase()}</Typography>}
            <div style={{ width: "100%" }}>
                <div className={clases.renglon}>
                    <Typography className={clases.data} > {data.sexo_name?.toUpperCase()}</Typography>
                    <Typography className={clases.data} >  {data.disciplina_name?.toUpperCase()}</Typography>
                    <Typography className={clases.data} > {data.categoria_desde_name + " a " + data.categoria_hasta_name}</Typography>
                </div>
                <div className={clases.renglon}>
                <Typography className={clases.data2}> Categoria Nro: {data.categoria_id  }</Typography>
          <Typography className={clases.data2}> Edad: {data.edad_desde + " a " + data.edad_hasta + " años."}</Typography>
                    <Typography className={clases.data2}> Peso: {data.peso_desde + " a " + data.peso_hasta + " kg."}</Typography>
                    <Typography className={clases.data2}> Altura: {data.altura_desde + " a " + data.altura_hasta + " cm."}</Typography>
                </div>
            </div>
        </div>
    )
}
export default DataCategoty
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { DataGrid } from "@mui/x-data-grid"
import React from "react"

const Styles = makeStyles({
    tableContainer: {
        display: "flex",
        justifyContent: "center",
        width: 622,
    },
    noDataContainer: {
        border: "solid 1px white",
        padding: "0.2em 1em 0.2em 1em",
        borderRadius: "5px",
        minWidth: "90%",
    },
    noDataText: {
        textAlign: "center",
    },
})

const AsistenciaUserTable = (props) => {
    const clases = Styles()

    const {
        desvincular,
        data,
        changeAsist,
        modal,
        pageData,
        changePage,
        loading,
        danes,
        tipo,
        maximize,
        handleSelect,
        removeRecord = null,
        addRecord = null,
        select,
        tandaSelect,
    } = props

    console.log({ data })
    const columns = [
        {
            field: "id",
            headerName: "Nro",
            minWidth: 50,
            renderCell: (row) => <div style={{ textAlign: "center", width: "100%" }}>{row.row.id}</div>,
        },
        //    { field: 'edad', headerName: 'Edad', minWidth: 110, renderCell: row => <div>{row.row.edad || 'No Indicado'}</div> },
        //  { field:'telefono', headerName: 'Telefono',minWidth: 120, renderCell: row => <div>{row.row.telefono || 'No Indicado'}</div>},
        { field: "dia", headerName: "Dia", minWidth: 190 },
        { field: "fecha", headerName: "Fecha", minWidth: 190 },

        {
            field: "estado",
            headerName: "Asistencia",
            width: 140,
            renderCell: (row) => {
                if (row.row.estado == 1) {
                    return <Typography style={{ color: "lightgreen", fontWeight: "bold" }}>Presente</Typography>
                } else if (row.row.estado == 0) {
                    return <Typography style={{ color: "orange" }}>Ausente</Typography>
                } else if (row.row.estado == 3) {
                    return <Typography style={{ color: "white", textAlign: "center" }}> - - </Typography>
                } else {
                    return <Typography style={{ color: "grey", textAlign: "center" }}> Sin Clases </Typography>
                }
            },
        },
    ]

    return (
        <div className={clases.tableContainer}>
            {data.length > 0 ? (
                <DataGrid
                    autoHeight
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={data}
                    pageSize={90}
                    loading={loading}
                    columns={columns}
                    paginationMode="server"
                    isRowSelectable={false}
                    hideFooter
                    hideFooterPagination
                    rowCount={data.length}
                />
            ) : (
                <div className={clases.noDataContainer}>
                    <h2 className={clases.noDataText}>Cargando Informacion....</h2>
                </div>
            )}
        </div>
    )
}

export default AsistenciaUserTable

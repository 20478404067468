import { makeStyles } from "@material-ui/styles"
import { borderRadius } from "@material-ui/system"
import React from "react"

const styles = makeStyles({
    container: {
        minWidth: "90%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        margin: "1em",
    },
    recordsCount: {
        fontSize: 18,
        textAlign: "center",
        padding: "0.6em",
        // Border
        border: "solid 2px white",
        borderRadius: "10px 5px",
    },
})

export const MatriculadirectorTopInformation = ({ records, total, pago, impago }) => {
    const clases = styles()

    return (
        <div className={clases.container}>
            <div style={{ marginTop: 20, width: "100%", display: "flex", flexDirection: "row" }}>
                <div
                    style={{
                        height: 100,
                        width: "33.33%",
                        borderTopLeftRadius: 10,
                        backgroundColor: "transparent",
                        borderWidth: 2,
                        borderStyle: "solid",
                        borderColor: "grey",
                    }}
                >
                    <div
                        style={{
                            height: "30%",
                            width: "100%",
                            fontSize: 25,
                            justifyContent: "center",
                            textAlign: "center",
                        }}
                    >
                        Alumnos Activos
                    </div>
                    <div
                        style={{
                            height: "70%",
                            width: "100%",
                            fontSize: 50,
                            verticalAlign: "center",
                            textAlign: "center",
                        }}
                    >
                        {" "}
                        {total}{" "}
                    </div>
                </div>

                <div
                    style={{
                        height: 100,
                        width: "33.33%",
                        backgroundColor: "transparent",
                        borderWidth: 2,
                        borderStyle: "solid",
                        borderColor: "grey",
                    }}
                >
                    <div
                        style={{
                            height: "30%",
                            width: "100%",
                            fontSize: 25,
                            justifyContent: "center",
                            textAlign: "center",
                        }}
                    >
                        Matriculas Pagas
                    </div>
                    <div
                        style={{
                            height: "70%",
                            width: "100%",
                            fontSize: 50,
                            verticalAlign: "center",
                            textAlign: "center",
                        }}
                    >
                        {" "}
                        {pago}{" "}
                    </div>
                </div>
                <div
                    style={{
                        height: 100,
                        width: "33.33%",
                        borderBottomRightRadius: 10,
                        backgroundColor: "transparent",
                        borderWidth: 2,
                        borderStyle: "solid",
                        borderColor: "grey",
                    }}
                >
                    <div
                        style={{
                            height: "30%",
                            width: "100%",
                            fontSize: 25,
                            justifyContent: "center",
                            textAlign: "center",
                        }}
                    >
                        No Pagas
                    </div>

                    <div
                        style={{
                            height: "70%",
                            width: "100%",
                            fontSize: 50,
                            verticalAlign: "center",
                            textAlign: "center",
                        }}
                    >
                        {" "}
                        {impago}{" "}
                    </div>
                </div>
            </div>
        </div>
    )
}

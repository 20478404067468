import React from "react"
import Container from "../Components/Formularios/Container"
import BodyLayout from "../Components/Layouts/BodyLayout"
import Logo from "../Assets/Imagenes/Logo_fit.png"
import LoginHandler from "../Components/Formularios/Login/LoginHandler"

const Login = (props) => {
    return (
        <BodyLayout>
            <Container logo={Logo}>
                <LoginHandler updateUser={props.updateUser} />
            </Container>
        </BodyLayout>
    )
}

export default Login

import React, { useEffect, useState } from "react"
import Container from "../../../Components/Container/Container"
import { fetchResource, usePermitions } from "../../../Functions/Functions"
import CircularProgress from "@material-ui/core/CircularProgress"
import Modal from "@material-ui/core/Modal"
import ModalBody from "../../../Components/ModalBody/ModalBody"
import { makeStyles } from "@material-ui/core"
import toast, { Toaster } from "react-hot-toast"
import { useSelector } from "react-redux"
import ActividadesTable from "./ActividadesTable"
import CreaActividad from "./CreaActividad"
import { ActividadesFilters } from "./ActividadesFilters"

const Styles = makeStyles({
    filtersContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        minWidth: "100%",
        flexWrap: "wrap",
    },
    filterInput: {
        alignSelf: "flex-end",
    },
    recordCounter: {
        alignSelf: "end",
    },
    ageInputs: {
        maxWidth: "5em",
    },
    ctaButton: {
        backgroundColor: "red",
    },
    h2: {
        backgroundColor: "red",
    },
})

const ActividadDirector = () => {
    const classes = Styles()
    const state = useSelector((state) => ({ ...state["basicDataReducer"] }))
    const { basicData } = state
    const { role_id } = usePermitions()
    const paramsInitialValues = {
        page: 0,
        nombre: "",
        status: 1,
        actives: 1,
        anterior: 0,
        fecha: "",
    }
    const [records, setRecords] = useState(0)
    const [params, setParams] = useState(paramsInitialValues)
    const [modalOpen, setModalOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [actividades, setActividades] = useState([])
    const [eventoNuevo, setEventoNuevo] = useState({
        nombre: "",
        fecha: "",
        lugar: "",
        cupo_maximo_locales: "",
        cupo_maximo_invitados: "",
        edad_desde: 1,
        edad_hasta: 99,
        categoria_id_desde: 1,
        categoria_id_hasta: 19,
        id: "",
    })
    const [page, setPage] = useState(1)
    const [ultimaPage, setultimaPage] = useState(0)
    const a = new Date()
    const anio = a.getFullYear()

    const [errors, setErrors] = useState(false)
    const [visible, setVisible] = useState(false)
    const [eliminar, setEliminar] = useState({ nombre: "", id: "" })
    const [modo, setModo] = useState(1) // 0 es crear actividad,  1 es modificar

    useEffect(() => {
        const fetchActividades = async () => {
            setLoading(true)
            const [data, error] = await fetchResource(
                `/director/actividades/all?activo=${params.actives}&nombre=${params.nombre}&fecha=${params.fecha}&page=${params.page}&anterior=${params.anterior}&status=${params.status} `,
                "get",
                true,
                null
            )

            if (data) {
                setActividades(data.data)
                setRecords(data.total_records)
                setLoading(false)
            } else {
                setLoading(false)
                setActividades(data)
            }
        }
        fetchActividades()
    }, [params])

    const notify = (x) => {
        if (x == 1) {
            toast.success("Actividad Creada Correctamente  ", { style: { background: "lightgreen", color: "Black" } })
        } else {
            toast.error("Actividad Eliminada !! ", { style: { background: "red", color: "white" } })
        }
    }

    const handleDelete = async (id) => {
        const [data, error] = await fetchResource(`/director/actividades/delete/${id}`, "delete", true, null)
        if (data) {
            setModalOpen(false)
            notify(0)
            setParams({ ...params })
        } else {
            console.error(error)
        }
    }

    const handleUpdate = async (id) => {
        setLoading(true)
        const [data, error] = await fetchResource(
            `/director/actividades/updateInscripcion/${id.target.id}`,
            "put",
            true,
            null
        )
        if (data) {
            let val
            if (params.status == 2) {
                val = 1
            } else {
                val = params.status
            }

            setParams({ ...params, status: val })
            setLoading(false)
        } else {
            setLoading(false)
            console.error(error)
        }
    }

    const handlePageChange = (page) => {
        setParams({ ...params, page: page })
    }

    const clearFilters = () => {
        setParams({ ...params, nombre: "", fecha: "" })
    }

    const handleFilter = (e) => {
        const field = e.target.name
        const value = e.target.value
        switch (field) {
            case "nombre":
                setParams({ ...params, nombre: value, page: 1 })
                break
            case "fecha":
                setParams({ ...params, fecha: value, page: 1 })
                break
            case "page":
                setParams({ ...params, page: value, page: 1 })
                break
            case "actives":
                if (value == 1) {
                    setParams({ ...params, actives: 1, anterior: 1, status: 1, page: 0 })
                }
                if (value == 2) {
                    setParams({ ...params, actives: 2, anterior: 0, status: 2, page: 0 }) //inactiva
                }
                if (value == 3) {
                    setParams({ ...params, actives: 3, anterior: 0, status: 3, page: 0 }) //
                }
                break
            default:
                break
        }
    }

    const handleClose = () => {
        setModalOpen(false)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(true)

        if (modo == 0) {
            const [data, error] = await fetchResource("/director/actividades/create", "post", true, eventoNuevo)
            if (data) {
                setLoading(false)
                setParams({ ...params })
                setVisible(false)
                notify(1)
            } else {
                setLoading(false)
                setErrors(true)
            }
        } else {
            const [data, error] = await fetchResource(
                `/director/actividades/update/${eventoNuevo.id}`,
                "put",
                true,
                eventoNuevo
            )
            if (data) {
                setLoading(false)
                setParams({ ...params })
                setVisible(false)
                notify(1)
            } else {
                setLoading(false)
                setErrors(true)
            }
        }
    }

    const hacerVisible = () => {
        setModo(!modo)

        setVisible(!visible)
    }

    const handleChange = (e) => {
        setEventoNuevo({
            ...eventoNuevo,
            [e.target.name]: e.target.value,
        })
    }

    const remove = (datos) => {
        setEliminar({ nombre: datos.nombre, id: datos.id })
        setModalOpen(true)
    }

    const handleEdit = (e) => {
        setModo(1)
        setVisible(true)
        setEventoNuevo({
            ...eventoNuevo,
            nombre: e.nombre,
            fecha: e.fecha,
            lugar: e.lugar,
            categoria_id_desde: e.categoria_id_desde,
            categoria_id_hasta: e.categoria_id_hasta,
            categoria_desde: e.categoria_desde,
            categoria_hasta: e.categoria_hasta,
            cupo_maximo_invitados: e.cupo_maximo_invitados,
            cupo_maximo_locales: e.cupo_maximo_locales,
            edad_desde: e.edad_desde,
            edad_hasta: e.edad_hasta,
            id: e.id,
        })
    }

    return (
        <div>
            {visible ? (
                <Container title={modo == 0 ? "CREAR ACTIVIDADES" : "EDITAR ACTIVIDADES"} more>
                    <CreaActividad
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        datos={basicData}
                        error={errors}
                        evento={eventoNuevo}
                        type={modo}
                    />
                </Container>
            ) : (
                <></>
            )}
            <Container
                title={
                    params.actives ? (
                        "EVENTOS ACTIVOS"
                    ) : (
                        <> {params.anterior == 0 ? "EVENTOS INACTIVOS" : "EVENTOS TERMINADOS"}</>
                    )
                }
                more
            >
                <ActividadesFilters
                    basicData={basicData}
                    handleFilter={handleFilter}
                    clearFilters={clearFilters}
                    params={params}
                    userRole={role_id}
                    create={hacerVisible}
                    modo={modo}
                />
                <Toaster position="center-center" />
                <>
                    {actividades?.length < 0 ? (
                        <CircularProgress></CircularProgress>
                    ) : (
                        <ActividadesTable
                            data={actividades}
                            changePage={handlePageChange}
                            pageData={{ currentPage: params.page, records: records }}
                            loading={loading}
                            update={handleUpdate}
                            remove={remove}
                            anterior={params.anterior}
                            edit={handleEdit}
                            status={params.status}
                        />
                    )}
                </>
            </Container>
            <Modal
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <ModalBody
                    nombre={eliminar}
                    handleDelete={handleDelete}
                    setModalOpen={setModalOpen}
                    modalOpen={modalOpen}
                />
            </Modal>
        </div>
    )
}

export default ActividadDirector

import React from "react"
import { Button, Input, makeStyles, MenuItem, TextField, Typography } from "@material-ui/core"
import Selection from "../Select"
import Alert from "@material-ui/lab/Alert"

const Styles = makeStyles({
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 20,
        gap: "1em",
    },
    button: {
        width: "210px",
        backgroundColor: "#e00",
    },
    registro: {
        borderTopStyle: "solid",
        borderColor: "#fffb",
        borderWidth: "1px",
        paddingTop: "5px",
        marginTop: "5px",
        marginBottom: "5px",
    },
    forgot: {
        textDecoration: "none",
        color: "#0099ff",
    },
})

const CreateExamForm = ({ examen, handleChange, handleSubmit, tipoExamen, error }) => {
    const style = Styles()

    return (
        <form onSubmit={handleSubmit} className={style.form} autoComplete="nope">
            {error && (
                <Alert variant="filled" color="error">
                    Verifique los datos ingresados
                </Alert>
            )}
            <TextField
                autoComplete="nope"
                id="fecha"
                name="fecha"
                value={examen.fecha}
                onChange={handleChange}
                type="date"
                label="Fecha"
                variant="outlined"
                size="small"
                color="secondary"
                required
            />
            <TextField
                autoComplete="nope"
                id="valor_examen"
                name="valor_examen"
                label="Valor Examen"
                value={examen.valor}
                onChange={handleChange}
                type="text"
                variant="outlined"
                size="small"
                color="secondary"
                required
            />
            <TextField
                autoComplete="nope"
                id="lugar"
                name="lugar"
                label="Lugar"
                value={examen.lugar}
                onChange={handleChange}
                type="text"
                variant="outlined"
                size="small"
                color="secondary"
                required
            />
            <TextField
                autoComplete="nope"
                id="direccion"
                name="direccion_calle"
                label="direccion"
                value={examen.direccion_calle}
                onChange={handleChange}
                type="text"
                variant="outlined"
                size="small"
                color="secondary"
                required
            />
            <TextField
                autoComplete="nope"
                id="numero"
                name="direccion_numero"
                label="numero"
                value={examen.direccion_numero}
                onChange={handleChange}
                type="number"
                variant="outlined"
                size="small"
                color="secondary"
                required
            />
            <TextField
                autoComplete="nope"
                id="localidad"
                name="direccion_localidad"
                label="localidad"
                value={examen.direccion_localidad}
                onChange={handleChange}
                type="text"
                variant="outlined"
                size="small"
                color="secondary"
                required
            />
            <TextField
                autoComplete="nope"
                id="detalle"
                name="direccion_detalle"
                label="detalle"
                value={examen.direccion_detalle}
                onChange={handleChange}
                type="text"
                variant="outlined"
                size="small"
                color="secondary"
                required
            />

            <Selection
                autoComplete="nope"
                id="tipo"
                name="tipo"
                label="Tipo Examen"
                value={examen.tipo}
                options={tipoExamen}
                onChange={handleChange}
                color="secondary"
                required
            />
            <Button type="submit" variant="contained" className={style.button} color="primary">
                Crear
            </Button>
        </form>
    )
}

export default CreateExamForm

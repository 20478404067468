import React from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import KeyIcon from "@mui/icons-material/Key"

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    editField: {
        height: 30,
        justifyContent: "center",

        background: "white",
    },
})

const CentroTable = ({ data, handleDelete, handleActive, handleEdit, handleAccess }) => {
    const classes = useStyles()

    return (
        <TableContainer component={Paper} style={{ marginTop: "30px" }}>
            <Table className={classes.table} aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell align="center">Nombre</TableCell>
                        <TableCell align="center">Direccion</TableCell>
                        <TableCell align="center">Localidad</TableCell>
                        <TableCell align="center">Zona</TableCell>
                        <TableCell align="center">Telefono</TableCell>
                        <TableCell align="center">Encargado</TableCell>
                        <TableCell align="center">Mail</TableCell>
                        <TableCell align="center">Acceso</TableCell>
                        <TableCell align="center">Edita</TableCell>
                        <TableCell align="center">Borrar</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((fila) => (
                        <TableRow key={fila.id}>
                            <TableCell component="th" scope="row">
                                {fila.id}
                            </TableCell>
                            <TableCell align="center"> {fila.nombre} </TableCell>
                            <TableCell align="center">{fila.direccion}</TableCell>
                            <TableCell align="center">{fila.localidad}</TableCell>
                            <TableCell align="center">{fila.zona ? fila.zona : `No disponible`}</TableCell>
                            <TableCell align="center">{fila.telefono ? fila.telefono : `No disponible`}</TableCell>
                            <TableCell align="center">{fila.contacto ? fila.contacto : `No disponible`}</TableCell>
                            <TableCell align="center">{fila.email ? fila.email : `No disponible`}</TableCell>

                            {/* <TableCell align="right"><IconButton onClick={()=>handleAccess({id:fila.id,nombre:fila.nombre})} ><KeyOffIcon/></IconButton></TableCell> */}
                            <TableCell align="right">
                                <IconButton onClick={() => handleAccess({ id: fila.id, nombre: fila.nombre })}>
                                    <KeyIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell align="right">
                                <IconButton onClick={() => handleEdit({ id: fila.id, nombre: fila.nombre })}>
                                    <ModeEditIcon />
                                </IconButton>
                            </TableCell>

                            {/* <TableCell align="center">{fila.inscripcion_activa ? <span style={{color:'green'}}>Activa</span> : <span style={{color:'red'}}>Inactiva</span>}</TableCell> */}
                            {/* <TableCell align="center"><IconButton onClick={()=>handleActive(fila.id)}>{fila.inscripcion_activa ? <LockOpenIcon/> : <LockIcon/>}</IconButton></TableCell> */}
                            <TableCell align="right">
                                <IconButton onClick={() => handleDelete({ id: fila.id, nombre: fila.nombre })}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default CentroTable

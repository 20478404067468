import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/core"
import { classExpression } from "@babel/types"

const styles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 10,
    },
}))

const Loading = ({ text }) => {
    const classes = styles()

    return (
        <div className={classes.container}>
            <CircularProgress color="secondary" />
            <h4>
                <i>{text}</i>
            </h4>
        </div>
    )
}

export default Loading

import React from "react"
import { Typography, makeStyles, Link } from "@material-ui/core"

const styles = makeStyles({
    img: {
        maxWidth: "55px",
        marginRight: "5px",
        paddingRight: "8px",
    },
    text: {
        borderLeftStyle: "solid",
        borderWidth: "1px",
        paddingLeft: "8px",
    },
})

const LinkLogo = ({ rutaInicial, titulo, logo }) => {
    const style = styles()

    return (
        <Link
            href={rutaInicial}
            underline="none"
            style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "white" }}
        >
            <img alt="" className={style.img} src={logo} />
            <Typography className={style.text} edge="start">
                {titulo}
            </Typography>
        </Link>
    )
}

export default LinkLogo

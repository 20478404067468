import React, { useEffect, useState } from "react"
import { fetchResource, usePermitions } from "../../../Functions/Functions"
import CircularProgress from "@material-ui/core/CircularProgress"
import Modal from "@material-ui/core/Modal"
import { useSelector } from "react-redux"
import { makeStyles, Select, MenuItem, option, Typography, TextField, Button } from "@material-ui/core"
import toast, { Toaster } from "react-hot-toast"
import { useLocation, useNavigate } from "react-router-dom"
import moment from "moment"
import Containter from "../../../Components/Container/Container"
import ClasesAsistenciaTable from "./ClasesAsistenciaTable"
import ClasesAsistenciaTopInfo from "./ClasesAsistenciaTopInfo"
import DefaultMessage from "../../../Components/DefaultMessage/DefaultMessage"

const Styles = makeStyles({
    filtersContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        minWidth: "100%",
        flexWrap: "wrap",
    },
    filterInput: {
        alignSelf: "flex-end",
    },
    recordCounter: {
        alignSelf: "end",
    },
    ageInputs: {
        maxWidth: "5em",
    },
    ctaButton: {
        backgroundColor: "red",
    },
})

const ClasesAsistencia = () => {
    const state = useSelector((state) => ({ ...state["basicDataReducer"] }))
    const { basicData } = state
    const { role_id } = usePermitions()
    const location = useLocation()
    const navigate = useNavigate()

    const [claseConfig, setClaseConfig] = useState({ inicio: "2024-02-01", fin: "2024-12-15" })
    const [loading, setLoading] = useState(true)
    const [alumnos, setAlumnos] = useState([]) // guarda los id y nombres de los alumnos
    const [claseActiva, setClaseActiva] = useState(location.state) // guarda todas las clases que va a mostrar en Select
    const [asistencias, setAsistencias] = useState("")
    const [records, setRecords] = useState(0)
    const [modalOpen, setModalOpen] = useState({ estado: false, alumno: "" })
    const [diaPermitido, setDiaPermitido] = useState(false)
    const year = moment().format("YYYY")
    const month = moment().format("MM")
    // cambiar el mes y año al que se selecciona no al que es en la actualidad
    const [dia, setDia] = useState(moment().format("YYYY-MM-DD"))

    const paramsInitialValues = {
        page: 0,
        nombre: "",
        apellido: "",
        escuela: "",
        asociacion: "",
        categoria: "",
        instructor: "",
        edadDesde: "",
        edadHasta: "",
        actives: true,
        claseSelected: "",
        rowsPerPage: 10,
    }
    const [params, setParams] = useState(paramsInitialValues)

    useEffect(() => {
        fetchAlumnos()
        permitirDia()
    }, [params, dia])

    const fetchAlumnos = async () => {
        // trae la lista de alumnos de la clase seleccionada
        setLoading(true)
        let body = { clase_id: claseActiva.id }
        const [data, error] = await fetchResource(`/instructor/clases/showAlumnosClass `, "post", true, body)
        if (data && data.code == 200) {

            setAlumnos(data.Alumnos)
            setRecords(data.Alumnos.lenght)
            fetchAsistencias(data.Alumnos)
        } else {
            setAlumnos(null)
            setLoading(false)
        }
    }

    const fetchAsistencias = async (datos) => {
        // trae la lista de asistencias de la clase

        let body = { clase_id: claseActiva.id }
        const [data, error] = await fetchResource("/instructor/asistencia/showClass", "post", true, body)
        if (data && data.code == 200) {
            let asist = []

            datos.map((x) => {
                let coincide = data.asistencia.filter((a) => a.fecha == dia && a.user_id == x.id)

                if (coincide.length > 0) {
                    asist.push({
                        id: x.id,
                        nombre_completo: x.nombre + " " + x.apellido,
                        categoria: categoria(x.categoria_id),
                        edad: edad(x.nacimiento),
                        estado: coincide[0].estado,
                        fecha: coincide[0].fecha,
                    })
                } else {
                    asist.push({
                        id: x.id,
                        nombre_completo: x.nombre + " " + x.apellido,
                        categoria: categoria(x.categoria_id),
                        edad: edad(x.nacimiento),
                        estado: 0,
                        fecha: dia,
                    })
                }
            })
            console.log({ asist })
            setAsistencias(asist)
            fetchPagosCuotas(asist)
        } else {
            setLoading(false)
            // console.log(error)
        }
    }

    const fetchPagosCuotas = async (datos) => {
        const [data, error] = await fetchResource("/instructor/cuotas/showAlumnos", "get", true, null)

        if (data && data.code == 200) {
            let listaCuotas = []

            datos.map((a) => {
                let d = { ...a, cuota: 0, fechaCuota: null }

                let coincide = data.cuotas.filter((c) => c.user_id == a.id)

                if (coincide.length > 0) {
                    coincide[0].cuotas.map((r) => {
                        if (
                            r.year == moment(dia).format("YYYY") &&
                            r.month == moment(dia).format("MM") &&
                            r.clase_id == claseActiva.id
                        ) {
                            d = { ...d, cuota: 1, fechaCuota: r.fecha }
                        }
                    })
                } else {
                    d = { ...d, cuota: 0, fechaCuota: null }
                }
                listaCuotas.push({ ...d })
            })
            setAsistencias(listaCuotas)
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const notify = (x, nombre) => {
        let mensaje = ""

        if (x == 1) {
            if (nombre) {
                mensaje = nombre
            } else {
                mensaje = "Registro Correcto."
            }
            toast.success(mensaje, { style: { background: "lightgreen", color: "Black" } })
        } else {
            if (nombre) {
                mensaje = nombre
            } else {
                mensaje = "Registro Eliminado"
            }
            toast.error(mensaje, { style: { background: "red", color: "white" } })
        }
    }
    const edad = (nac) => {
        let anios = "--"

        if (nac !== "" && nac !== "0000-00-00") {
            let nacimiento = moment(nac)
            let hoy = moment()
            anios = hoy.diff(nacimiento, "years")
        }
        return anios
    }

    const categoria = (cat_id) => {
        let categoria = [
            "Blanco",
            "Punta Amarilla",
            "Amarillo",
            "Punta Verde",
            "Verde",
            "Punta Azul",
            "Azul",
            "Punta Roja",
            "Rojo",
            "Punta Negra",
            "Primer Dan",
            "Segundo Dan",
            "tercer Dan",
            "cuarto Dan",
            "quinto Dan",
            "sexto dan",
            "septimo Dan",
            "octavo Dan",
            "noveno dan",
        ]
        let r = ""

        r = categoria[cat_id - 1].toUpperCase()

        return r
    }
    /*---------------------------*/

    const handlePageChange = (page1) => {
        setParams({ ...params, page: page1 })
    }

    const handleRemove = async (id) => {
        let parametro = { id: id }
        const [data, error] = await fetchResource("/instructor/alumnos/dettach", "post", true, parametro)

        if (error) console.error(error)
        let alumnosFiltrado = alumnos.filter((data) => data.id !== id)
        setAlumnos(alumnosFiltrado)
    }

    const clearFilters = () => {
        setParams(paramsInitialValues)
    }

    const handleFilter = (e) => {
        const field = e.target.name
        const value = e.target.value
        switch (field) {
            case "nombre":
                setParams({ ...params, nombre: value, page: 0 })
                break
            case "apellido":
                setParams({ ...params, apellido: value, page: 0 })
                break
            case "categoria":
                setParams({ ...params, categoria: value, page: 0 })
                break
            case "escuela":
                setParams({ ...params, escuela: value, page: 0 })
                break
            case "instructor":
                setParams({ ...params, instructor: value, page: 0 })
                break
            case "asociacion":
                setParams({ ...params, asociacion: value, page: 0 })
                break
            case "edadDesde":
                setParams({ ...params, edadDesde: value, page: 0 })
                break
            case "edadHasta":
                setParams({ ...params, edadHasta: value, page: 0 })
                break
            case "actives":
                setParams({ ...params, actives: !params.actives })
                break
            default:
                break
        }
    }

    const handleClaseSelect = (value) => {
        if (value.target.value == 0) {
            setParams({ ...params, claseSelected: "", page: 0 })
        } else {
            setParams({ ...params, claseSelected: value.target.value, page: 0 })
        }
    }


    const handleClose = () => {
        setModalOpen(false)
    }

    const handleFecha = (fecha) => {
        setDia(fecha)
    }

    const changeAsistencia = async (id2, asist2) => {
        let body = [
            {
                user_id: id2,
                clase_id: claseActiva.id,
                fecha: dia,
                estado: !asist2,
            },
        ]
        console.log({ body })
        const [data, error] = await fetchResource("/instructor/asistencia/register", "post", true, body)

        if (data && data.code == 200) {
            setLoading(false)
            let a = []

            asistencias.map((x) => {
                if (x.id == id2) {
                    a.push({ ...x, estado: !asist2 })
                } else {
                    a.push({ ...x })
                }
            })

            setAsistencias(a)
        } else {
            setLoading(false)
            // console.log(error)
        }
    }

    const permitirDia = () => {
        let hoy = moment().format("YYYY-MM-DD")
        let diaActivo = moment(dia).format("YYYY-MM-DD")
        let dianumero = moment(dia).day() //0 =domingo
        let domingo = claseActiva.domingo
        let lunes = claseActiva.lunes
        let martes = claseActiva.martes
        let miercoles = claseActiva.miercoles
        let jueves = claseActiva.jueves
        let viernes = claseActiva.viernes
        let sabado = claseActiva.sabado

        if (
            (domingo == 1 && dianumero == 0) ||
            (lunes == 1 && dianumero == 1) ||
            (martes == 1 && dianumero == 2) ||
            (miercoles == 1 && dianumero == 3) ||
            (jueves == 1 && dianumero == 4) ||
            (viernes == 1 && dianumero == 5) ||
            (sabado == 1 && dianumero == 6)
        ) {
            if (claseConfig.inicio <= diaActivo && hoy >= diaActivo) {
                setDiaPermitido(true)
            } else {
                setDiaPermitido(false)
            }
        } else {
            setDiaPermitido(false)
        }
    }

    const handleModal = (e, n) => {
        setModalOpen({ estado: e, nombre: n.nombre_completo, id: n.id })
    }

    const desvincular = async () => {
        let body = [{ id: modalOpen.id, clase_id: claseActiva.id }]

        const [data, error] = await fetchResource("/instructor/alumnos/dettachFromClass", "post", true, body)

        if (data && data.code == 200) {
            fetchAlumnos()
            setModalOpen({ ...modalOpen, estado: false })
        } else {
            console.log(error)
        }
    }

    const classes = Styles()

    return (
        <Containter title={alumnos?.length ? "Asistencia de Clase" : "Asistencia "+ location.state.nombre} more>
            {asistencias?.length > 0 ? (
                <>
                    <ClasesAsistenciaTopInfo
                        btnvolver={() => navigate("/clases")}
                        btnalumnos={() => navigate("/alumnos", { actives: false })}
                        changeFecha={(x) => handleFecha(x)}
                        nombreClase={location.state.nombre}
                        fecha={dia}
                        datos={asistencias}
                        permitido={diaPermitido}
                        config={claseConfig}
                    />
                    {diaPermitido == true ? (
                        <ClasesAsistenciaTable
                            data={asistencias}
                            changeAsist={(i, a) => changeAsistencia(i, a)}
                            modal={(e, n) => handleModal(e, n)}
                            loading={loading}
                            desvincular={(id, nombre) => desvincular(id, nombre)}
                        />
                    ) : (
                        <DefaultMessage title="NO SE PUEDE TOMAR ASISTENCIA, HOY NO SE DICTAN CLASES EN ESTE LUGAR." />
                    )}
                </>
            ) : (
                <>
                    {alumnos == null ?
                        <DefaultMessage title={"NO HAY ALUMNOS REGISTRADOS A LA CLASE "} logo ></DefaultMessage>
                        :
                        <>
                            <CircularProgress color="red"> </CircularProgress>
                            <Typography>Cargando Alumnos....</Typography>
                        </>
                    }
                </>
            )}

            <Modal
                open={modalOpen.estado}
                style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}
            >
                <Containter title="DESVINCULAR ALUMNO" styles={{ width: "50%", height: "50%" }} more>
                    <Typography variant="h5" style={{ textAlign: "center" }}>
                        ¿Desea Desvincular a {modalOpen.nombre} de {claseActiva.nombre}?{" "}
                    </Typography>

                    <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center" }}>
                        <Button onClick={() => desvincular()} style={{ background: "red", margin: 10 }}>
                            Confirmar
                        </Button>

                        <Button
                            onClick={() => setModalOpen({ ...modalOpen, estado: false })}
                            style={{ background: "red", margin: 10 }}
                        >
                            Cancelar
                        </Button>
                    </div>
                </Containter>
            </Modal>
        </Containter>
    )
}

export default ClasesAsistencia

import { createStore, combineReducers } from "redux"
import userReducer from "./user/reducer"
import basicDataReducer from "./basicData/reducer"

const reducers = combineReducers({
    userReducer,
    basicDataReducer,
})

const store = createStore(reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

export default store

import { IconButton, MenuItem, Select, Tooltip, Typography } from "@material-ui/core"
import { ArrowDownward, ArrowUpward } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import EditIcon from "@mui/icons-material/Edit"
import { DataGrid } from "@mui/x-data-grid"
import React from "react"
import moment from "moment"
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral"
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied"
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt"

const Styles = makeStyles({
    tableContainer: {
        display: "flex",
        justifyContent: "center",
        width: 802,
    },
    noDataContainer: {
        border: "solid 1px white",
        padding: "0.2em 1em 0.2em 1em",
        borderRadius: "5px",
        minWidth: "90%",
    },
    noDataText: {
        textAlign: "center",
    },
})

const CuotaUserControlTable = (props) => {
    const clases = Styles()

    const { mes, data, loading } = props
    console.log("TABLE", data)
    console.log("mes", mes)
    const columns = [
        {
            field: "cuota",
            headerName: "Cuota",
            minWidth: 100,
            renderCell: (row) => {
                return (
                    <Typography style={{ fontWeight: "bold", fontSize: 20, textAlign: "center", width: "100%" }}>
                        {row.row.cuota}
                    </Typography>
                )
            },
        },

        {
            field: "mes",
            headerName: "Mes",
            minWidth: 250,
            renderCell: (row) => {
                return (
                    <Typography style={{ fontWeight: "bold", fontSize: 20, textAlign: "center", width: "100%" }}>
                        {row.row.mes}
                    </Typography>
                )
            },
        },

        {
            field: "estado",
            headerName: "Estado",
            width: 200,
            renderCell: (row) => {
                if (row.row.estado == 1) {
                    return (
                        <Typography style={{ color: "lightgreen", textAlign: "center", width: "100%" }}>
                            {" "}
                            <SentimentSatisfiedAltIcon style={{ fontSize: 40, width: "100%" }} />{" "}
                        </Typography>
                    )
                } else if (row.row.estado == 0 && row.row.id <= mes) {
                    return (
                        <Typography style={{ color: "red", textAlign: "center", width: "100%" }}>
                            <SentimentVeryDissatisfiedIcon style={{ fontSize: 40, width: "100%" }} />
                        </Typography>
                    )
                } else {
                    return (
                        <Typography style={{ color: "grey", textAlign: "center", width: "100%" }}>
                            <SentimentNeutralIcon style={{ fontSize: 40, width: "100%" }} />
                        </Typography>
                    )
                }
            },
        },
        {
            field: "fecha",
            headerName: "Fecha de Pago",
            minWidth: 250,
            renderCell: (row) => {
                return (
                    <Typography
                        style={
                            row.row.estado == 1
                                ? { color: "white", fontSize: 15, textAlign: "center", width: "100%" }
                                : { color: "grey", fontSize: 15, textAlign: "center", width: "100%" }
                        }
                    >
                        {row.row.estado == 1 ? moment(row.row.fecha).format("DD-MM-YYYY") : row.row.fecha}
                    </Typography>
                )
            },
        },
    ]

    return (
        <div className={clases.tableContainer}>
            {data?.length ? (
                <DataGrid
                    autoHeight={true}
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={data}
                    pageSize={90}
                    loading={loading}
                    columns={columns}
                    paginationMode="server"
                    isRowSelectable={false}
                    rowCount={data.length}
                    hideFooter
                    hideFooterPagination
                />
            ) : (
                <div className={clases.noDataContainer}>
                    <h2 className={clases.noDataText}>Cargando Informacion....</h2>
                </div>
            )}
        </div>
    )
}

export default CuotaUserControlTable

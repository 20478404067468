import React from "react"
import DefaultImage from "../../../Assets/Imagenes/perfil_default_tkd.png"
import Cookies from "universal-cookie"
import { useSelector, useDispatch } from "react-redux"
import updateUser from "../../../Store/user/actions"

const ProfilePic = () => {
    let cookie = new Cookies()

    const dispatch = useDispatch()
    const user = useSelector((state) => state.userReducer.user)

    const updateImage = async (e) => {
        e.preventDefault()
        const files = e.target.files
        let data = new FormData()
        data.append("fotoPerf", files[0])
        data.keys("fotoPerf")

        let config = {
            method: "POST",
            headers: {
                Authorization: "Bearer " + cookie.get("token"),
            },
            body: data,
        }
        try {
            let response = await fetch(process.env.REACT_APP_BACKEND_BASE_URL + "/users/updateImage", config)

            let resp = await response.json()

            dispatch(
                updateUser({
                    ...user,
                    foto: resp.foto,
                })
            )

            let usuario = cookie.get("user")
            cookie.set("user", { ...usuario, foto: resp.foto })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div style={{ padding: 20, display: "flex", flexDirection: "column" }}>
            <img
                alt="Profile Picture"
                style={{ maxWidth: 200 }}
                src={user.foto ? process.env.REACT_APP_BACKEND_BASE_IMAGE_URL + user.foto : DefaultImage}
            />
            <input type="file" name="fotoPerf" variant="outlined" onChange={updateImage} />
            {/* <IconButton style={{backgroundColor:'#666', borderRadius:'50%',alignSelf:'flex-end', width:30,height:30}} type="submit"><EditIcon/></IconButton> */}
        </div>
    )
}

export default ProfilePic

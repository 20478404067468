import { Button, CircularProgress, InputLabel, MenuItem, Select } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import Containter from "../Components/Container/Container"
import { makeStyles } from "@material-ui/styles"
import { fetchResource } from "../Functions/Functions"
import { Alert } from "@mui/material"
import centros from "../MockData/centros"
import FormControl from "@material-ui/core/FormControl"
import { useNavigate } from "react-router-dom"

const Styles = makeStyles({
    button: {
        width: "210px",
        backgroundColor: "#e00",
    },
    form: {
        marginTop: "2em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "0.3em",
    },
})

const InscripcionExamen = () => {
    const [examenes, setExamenes] = useState([
        // {id:29,nombre:'2022-12-28'}
    ])

    //Todo: Hacer funcion para que traiga los examenes activos para poder anotarse
    useEffect(() => {
        const getExamenes = async () => {
            const [data, error] = await fetchResource("/users/examen/activeExams", "get", true, null)
            if (data.data.length) {
                setExamenes(data.data.map((examen) => ({ id: examen.id, nombre: `${examen.fecha} - ${examen.lugar}` })))
            } else {
                console.error(error)
            }
        }
        getExamenes()
    }, [])
    // const examenes = [
    //     {id:24,nombre:'2022-12-03'},
    //     // {id:25,nombre:'2022-12-10'},
    //     // {id:26,nombre:'2022-12-15'},
    //     // {id:27,nombre:'2022-12-16'},
    //     // {id:28,nombre:'2022-12-14'},
    // ]

    const classes = Styles()

    const navigate = useNavigate()

    const [examToSuscribe, setExamToSuscribe] = useState(0)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {
        const examen_id = parseInt(e.target.value)
        setExamToSuscribe(examen_id)

        // if (centro_id <= 7 || centro_id >= 18) setExamToSuscribe(14) //9 de julio
        // if (centro_id === 8) setExamToSuscribe(15) //11 de julio
        // if (centro_id > 8 && centro_id <= 10) setExamToSuscribe(16) // 14 de julio
        // if (centro_id > 10 && centro_id <= 14) setExamToSuscribe(17) //15 de julio
        // if (centro_id > 14 && centro_id <= 17) setExamToSuscribe(18) //16 de julio
    }

    const handleExamInscription = async () => {
        setLoading(true)

        let dataToSend = {
            examen_id: examToSuscribe,
        }
        const [data, error] = await fetchResource("/users/examen/signToExam", "post", true, dataToSend)

        if (data) {
            setSuccess(true)
            setTimeout(() => {
                navigate("/")
                setLoading(false)
            }, 2000)
            console.log("Registrado con exito")
        } else {
            setError(true)
            setLoading(false)
            console.log("Ocurrio un error")
        }
    }

    return (
        <Containter more title={"Inscripcion a Examen"}>
            {success && (
                <Alert variant="filled" color="success">
                    ¡Registrado al Examen exitosamente!
                </Alert>
            )}
            {error && (
                <Alert variant="filled" color="error">
                    ¡Ocurrio un error, Consulta a tu instructor!
                </Alert>
            )}
            <form className={classes.form}>
                <label htmlFor="centros">Seleccione el examen</label>
                <Selection
                    autoComplete="nope"
                    id="id"
                    name="examenes"
                    options={examenes}
                    onChange={handleChange}
                    label="Examenes"
                    color="secondary"
                    required
                />
                <Button
                    className={classes.button}
                    onClick={handleExamInscription}
                    disabled={error || success || loading || !examToSuscribe}
                >
                    {loading ? <CircularProgress /> : "Inscribirse"}
                </Button>
            </form>
        </Containter>
    )
}

export default InscripcionExamen

const Selection = ({ label, name, value, onChange, options, id }) => {
    const style = Styles()

    return (
        <FormControl variant="outlined" className={style.formControl} size="small">
            <InputLabel id="select" color="secondary">
                {label}
            </InputLabel>
            <Select
                name={name}
                labelId="select"
                id="select"
                value={value}
                onChange={onChange}
                label={label}
                color="secondary"
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {options.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.nombre}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

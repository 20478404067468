import React from "react"
import "./../../styles/HeaderExamen.css"
import logo from "../../Assets/Imagenes/Logo_fit.png"

import { Hidden, Typography } from "@material-ui/core"

const HeaderExamen = ({ props, datos }) => {
    const dato = {
        categoria: "BLANCO PUNTA AMARILLA",
        id_categoria: 9,
        fecha: "12/12/2023",
        certif: "01-1438/23",
        evaluador: "Tito Besone",
        estado: 2,
        promedio: "MB",
    }

    const color = (estado) => {
        switch (estado) {
            case 0:
                return "red"
                break
            case 1:
                return "green"
                break
            case 2:
                return "yellow"
                break

            default:
                break
        }
    }

    const idCategoria = (id) => {
        if (id <= 10) {
            var valor = id + "º Gup"
            return valor
        } else if (id >= 11) {
            var valor = id + "º Dan"
            return valor
        }
    }

    const estado = (estado) => {
        switch (estado) {
            case 0:
                return "No Aprobado"
                break
            case 1:
                return "Aprobado"
                break
            case 2:
                return "Sin Evaluar"
                break
            default:
                break
        }
    }

    return (
        <div className="cuadroGeneral">
            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <div
                    style={{
                        border: "1px solid white",
                        height: "67%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                >
                    <div className="categoria"> {dato.categoria} </div>
                    <div className="gup"> {idCategoria(dato.id_categoria)} </div>
                </div>

                <div className="fecha" style={{ border: "1px solid white" }}>
                    {" "}
                    04/12/2022{" "}
                </div>
            </div>

            <div className="caja2">
                <div className="certificacion" style={{ border: "1px solid white" }}>
                    Certificacion Nro.: {dato.certif}{" "}
                </div>

                <div className="certificacion" style={{ border: "1px solid white" }}>
                    Evaluador: {dato.evaluador}
                </div>

                <div
                    className="certificacion"
                    style={{ fontSize: 25, fontWeight: "bold", color: color(dato.estado), border: "1px solid white" }}
                >
                    {estado(dato.estado)}
                </div>
            </div>
        </div>
    )
}

export default HeaderExamen

import React, { useState } from "react"
import { Menu, MenuItem, IconButton, Button, Typography } from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import { useNavigate } from "react-router-dom"

const HamburgerMenu = ({ user, logout, ...props }) => {
    // const navigate =useNavigate()
    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const menuLogedIn = [
        <MenuItem key={1}>
            <Typography color="textSecondary">{user.nombre + " " + user.apellido}</Typography>
        </MenuItem>,
        <MenuItem
            key={2}
            onClick={() => {
                handleClose()
                navigate("/")
            }}
        >
            <Typography color="textSecondary">Inicio</Typography>
        </MenuItem>,
        <MenuItem
            key={3}
            onClick={() => {
                handleClose()
                navigate("/nosotros")
            }}
        >
            <Typography color="textSecondary">Nosotros</Typography>
        </MenuItem>,
        <MenuItem
            key={4}
            onClick={() => {
                handleClose()
                navigate("/formas")
            }}
        >
            <Typography color="textSecondary">Formas</Typography>
        </MenuItem>,
        <MenuItem
            key={5}
            onClick={() => {
                handleClose()
                logout()
            }}
        >
            <Typography color="textSecondary">Salir 🚪 </Typography>
        </MenuItem>,
    ]

    return (
        <div>
            {user.logedIn ? (
                <>
                    <IconButton color="inherit" aria-haspopup="true" onClick={props.handleOpen}>
                        <MenuIcon />
                    </IconButton>
                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                        {menuLogedIn}
                    </Menu>
                </>
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        navigate("/login")
                    }}
                >
                    Ingresa
                </Button>
            )}
        </div>
    )
}

export default HamburgerMenu

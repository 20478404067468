 
import jsPDF from 'jspdf';
import React from 'react';
 import logo from "./../../../Assets/Imagenes/Logo_SUPERCOPA.png"




const PDFPlanillaSalto = (props) => {

  const { alumnos, disciplina, categoria,listo} = props;
 
  const sexo=(valor)=>{
    let sexo="Mixto"

    switch (valor) {
      case 1:
        sexo="Femenino"
        break;
        case 2:
          sexo="Masculino"
          break;
          case2:
            sexo="Mixto"
            break;
    
      default:
        break;
    }
    return sexo
  }
  const NC=(num)=>{
    let categoria="n/c"
    switch (num) {
     
      case 1:
        categoria="Blanco"
        break;
        case 2:
          categoria="Punta Amarilla"
          break;
          case 3:
            categoria="Amarillo"
            break;
            case 4:
              categoria="Punta Verde"
              break;
              case 5:
                categoria="Verde"
                break;
                case 6:
                  categoria="punta Azul"
                  break;
                  case 7:
                    categoria="Azul"
                    break;
                    case 8:
                      categoria="Punta Roja"
                      break;
                      case 9:
                        categoria="Rojo"
                        break;
                    case 10:
                      categoria="Punta Negra"
                      break;
                      case 11:
                        categoria="1º Dan"
                        break;
                        case 12:
                          categoria="2º Dan"
                          break;
                          case 13:
                            categoria="3º Dan"
                            break;
                            case 14:
                              categoria="4º Dan"
                              break;
                              case 15:
                                categoria="5º Dan"
                                break;
                                case 16:
                                  categoria="6º Dan"
                                  break;
                                  case 17:
                                    categoria="7º Dan"
                                    break;
                                    case 18:
                                      categoria="8º Dan"
                                      break; case 19:
                                      categoria="9º Dan"
                                      break;

    
      default:
        break;
    }

  return categoria;
  }

  let x = 10;
  let y = 10;


  const doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "a4"
  });

   doc.cell(x,y,190,30,"  ") //cuadrado encabezado
   doc.setFontSize(17)
   doc.text( ` ${disciplina.toUpperCase()} (${sexo(categoria.sexo)})`,100,17)     // x==>>
   
   doc.setFontSize(10)
      doc.text(`2024`,180,37)
    doc.addImage(logo, "PNG", 170,10, 25, 25);
  
   doc.text(`Categoria: de ${ NC(categoria.categoria_desde) } a ${ NC(categoria.categoria_hasta) }.`,15,25)
   doc.text(`Categoria Nro: ${categoria.id }`,130,25)
  
   
   doc.text(`Edad:    ${categoria.edad_desde } a ${categoria.edad_hasta } años.`,15,35)
   doc.text(`Altura:  de ${categoria.altura_desde } a ${categoria.altura_hasta } cm.`,80,35)
   doc.text(`Peso:  de ${categoria.peso_desde } a ${categoria.peso_hasta } Kg.`,130,35)
  //  doc.text(`Categoria Nro: ${categoria[0].categoria_id }`,15,40)
   

   
   y=45
   x = 10;
    
     
    
    doc.setFontSize(7)
    doc.setFillColor('#FF3432');
    doc.cell ( x, y, 10, 10, "Nro" )
    x = x + 10
    doc.cell(x, y, 10, 10, "Asist")
    x = x + 10
    doc.setFontSize(15)
    doc.cell(x, y, 100, 10, "Nombre")
    x = x + 100
    doc.setFontSize(7)
    doc.cell(x, y, 30, 10, "Categoria")
    x = x + 30
    doc.setFontSize(10)
    doc.cell(x, y,  20, 10,"Edad" )
    x = x + 20
    doc.cell( x,y,20,10,"Puesto" )
  y= y+10;
   for (let index = 0; index < alumnos.length; index++) {
    x = 10;
    doc.setFontSize(7)
    doc.cell(x, y, 10, 10, `${index+1}`)
    x = x + 10
    doc.cell(x, y, 10, 10, " ")
    x = x + 10
    doc.setFontSize(10)
    doc.cell(x, y, 100, 10, alumnos[index].nombre+" "+alumnos[index].apellido)
    x = x + 100
    doc.setFontSize(7)
    doc.cell(x, y, 30, 10, alumnos[index].categoria)
    x = x + 30
    doc.setFontSize(10)
    
    doc.cell(x, y,  20, 10,`${alumnos[index].edad}` )
    x = x + 20

    doc.cell( x,y,20,10," " )

    y = y + 10;
  }

  let nombrePDF= `${disciplina.toUpperCase()}_${categoria.edad_desde}a${categoria.edad_hasta}_${NC(categoria.categoria_desde)}a${NC(categoria.categoria_hasta)}.`

  doc.output('save', `${nombrePDF}.pdf`);
 // doc.save("yourDoc.pdf");

  listo()
  return (
    <div>
    </div>
  );
}

export default PDFPlanillaSalto;
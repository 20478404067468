import React from "react"
import {
    useCategoryFilter,
    useFilter,
    useFirstLastNameFilter,
    useDataFilter,
    useRageFilter,
} from "../../Functions/Functions"
import { Button, makeStyles } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import SearchIcon from "@mui/icons-material/Search"
import { useTableRecordEdit } from "../../Functions/Functions"
import { MenuItem, Select, InputLabel } from "@mui/material"
import { useSelector } from "react-redux"
import Selection from "../Formularios/Select"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import * as xlsx from "xlsx"

const Styles = makeStyles({
    filtersContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        minWidth: "100%",
        flexWrap: "wrap",
    },
    filterInput: {
        alignSelf: "flex-end",
    },
    recordCounter: {
        alignSelf: "end",
    },
    ageInputs: {
        maxWidth: "5em",
    },
    ctaButton: {
        backgroundColor: "red",
    },
})

const ActionsTable = ({ data, columns, ...props }) => {
    const state = useSelector((state) => ({ ...state["basicDataReducer"] }))
    const user = useSelector((state) => state.userReducer.user)

    const { categorias, centros, escuelas, instructores } = state.basicData

    const classes = Styles()

    const escuelasOption = () => {
        const escuelasOptions = escuelas.map((escuela) => {
            return { id: escuela.id, nombre: escuela.abreviacion }
        })

        return escuelasOptions
    }

    // const [setEscuelaFilter, escuelaFilterValue, filteredEscuelaData] = useDataFilter(data, 'escuela');
    const [setNameFilter, nameFilterValue, filteredNameData] = useFirstLastNameFilter(data)
    // // const [setFrom, setTo, from, to, filteredAgeData] = useRageFilter(filteredNameData, 'edad');
    const [setCategoriaFilter, categoriaFilterValue, filteredCategoriaData] = useDataFilter(
        filteredNameData,
        "categoria",
        "exact"
    )

    const { handleRecordEdit } = useTableRecordEdit("/editar/registro")

    const handleAddOrRemove = (recordId) => {}
    // let filteredCategoriaData = filteredNameData
    let recordCount = filteredCategoriaData.length

    const exportDataToExel = () => {
        const dataToExport = filteredCategoriaData.map((row) => ({
            Dni: row.dni,
            Nombre: row.nombre,
            Apellido: row.apellido,
            Edad: row.edad,
            Telefono: row.telefono,
            Categoria: row.categoria,
            Instructor: row.instructor,
            Escuela: row.escuela,
        }))

        let book = xlsx.utils.book_new()
        let sheet = xlsx.utils.json_to_sheet(dataToExport)
        xlsx.utils.book_append_sheet(book, sheet, "alumnos")
        xlsx.writeFile(book, "alumnos.xlsx")
    }

    console.log("filtrado", filteredCategoriaData)
    return (
        <>
            <div className={classes.filtersContainer}>
                {/* <SearchIcon/>*/}
                {filteredCategoriaData.length > 0 && user.role_id <= 2 && (
                    <Button onClick={() => exportDataToExel()} className={classes.ctaButton}>
                        Exportar
                        <FileDownloadIcon />
                    </Button>
                )}
                <TextField
                    variant="outlined"
                    size="small"
                    label="Nombre"
                    name="filter"
                    type="text"
                    value={nameFilterValue}
                    onChange={setNameFilter}
                />
                <Selection
                    label="Categoria"
                    name="categoria"
                    value={categoriaFilterValue}
                    onChange={setCategoriaFilter}
                    options={categorias}
                />
                {/*
                <TextField
                    variant="outlined"
                    size="small"
                    label="Edad-Desde"
                    name="from"
                    type="number"
                    value={from}
                    onChange={setFrom}
                />
                <TextField
                    variant="outlined"
                    size="small"
                    label="Edad-Hasta"
                    name="to"
                    type="number"
                    value={to}
                    onChange={setTo}
                />
                <Selection
                    label="Escuela"
                    name="escuela"
                    value={escuelaFilterValue}
                    onChange={setEscuelaFilter}
                    options={escuelasOption()}
                /> */}

                {/* <button onClick={()=>clearFilter()}>Limpiar</button> */}
            </div>
            {/* {props.children({filteredData,columns,handleAddOrRemove})} */}
            {props.children({ filteredCategoriaData, columns, handleAddOrRemove })}
            <p className={classes.recordCounter}>Cantidad de Registros: {recordCount}</p>
        </>
    )
}

export default ActionsTable

import React, { useEffect, useState } from "react"
import Container from "../../Components/Container/Container"
import { fetchResource, usePermitions } from "../../Functions/Functions"
import CircularProgress from "@material-ui/core/CircularProgress"
import Modal from "@material-ui/core/Modal"
import ModalBody from "../../Components/ModalBody/ModalBody"
import ActionsTable from "../../Components/Table/ActionsTable"
import { useSelector } from "react-redux"
import { makeStyles, Select, MenuItem, option, Typography } from "@material-ui/core"
import toast, { Toaster } from "react-hot-toast"
import InstructorInscribeExamenTable from "./InstructorInscribeExamenTable"
import { InscripcionExamenDataFilters } from "./InscripcionExamenDataFilters"
import { useLocation } from "react-router-dom"
import moment from "moment"

const Styles = makeStyles({
    filtersContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        minWidth: "100%",
        flexWrap: "wrap",
    },
    filterInput: {
        alignSelf: "flex-end",
    },
    recordCounter: {
        alignSelf: "end",
    },
    ageInputs: {
        maxWidth: "5em",
    },
    ctaButton: {
        backgroundColor: "red",
    },
})

const InscripcionExamen = () => {
    const state = useSelector((state) => ({ ...state["basicDataReducer"] }))
    const { basicData } = state
    const { role_id } = usePermitions()

    const [modalOpen, setModalOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [examenes, setExamenes] = useState("") // guarda los id de los examenes activos
    const [alumnos, setAlumnos] = useState([]) // guarda los id y nombres de los alumnos
    const [clases, setClases] = useState("") // guarda todas las clases que va a mostrar en Select
    const [examenSelected, setExamenSelected] = useState("") //guarda id de examen seleccionado
    const [inscriptosExamen, setInscriptosExamen] = useState([]) //guarda los id de los alumnos inscriptos al examen seleccionado
    const [filtro, setFiltro] = useState("")
    const [records, setRecords] = useState(0)
    const [modal, setModal] = useState(false)
    const paramsInitialValues = {
        page: 0,
        nombre: "",
        apellido: "",
        escuela: "",
        asociacion: "",
        categoria: "",
        instructor: "",
        edadDesde: "",
        edadHasta: "",
        actives: true,
        claseSelected: "",
        rowsPerPage: 10,
    }
    const [params, setParams] = useState(paramsInitialValues)

    useEffect(() => {
        fetchClases()
        fetchExamenes()
    }, [])

    useEffect(() => {
        console.log({ params })
        const fetchAlumnos = async () => {
            setLoading(true)
            const [data, error] = await fetchResource(
                `/instructor/examen/showAlumnosForExam?page=${params.page + 1}&clase=${params.claseSelected}&instructor=1&rowsPerPage=${params.rowsPerPage}&nombre=${params.nombre}&edadDesde=${params.edadDesde}&edadHasta=${params.edadHasta} &categoria=${params.categoria}&apellido=${params.apellido} `,
                "GET",
                true,
                null
            )
            if (data) {
                setLoading(false)
                setAlumnos(data.data)
                setRecords(data.total_records)
                console.log({ data })
            } else {
                setLoading(false)
            }
        }

        fetchAlumnos()
    }, [params])

    const fetchClases = async () => {
        const [data, error] = await fetchResource(`/menu/clases`, "GET", true, null)

        if (data) {
            setLoading(false)
            setClases(data.data)
        } else {
            setLoading(false)
            // console.log(error)
        }
    }

    const fetchExamenes = async () => {
        const [data, error] = await fetchResource("/users/examen/activeExams", "get", true)
        if (data.data) {
            // cuando viene vacio no trae "data"

            setExamenes(data.data)
            setLoading(false)
        }
    }

    const notify = (x, nombre) => {
        let mensaje = ""

        if (x == 1) {
            if (nombre) {
                mensaje = nombre
            } else {
                mensaje = "Registro Correcto."
            }
            toast.success(mensaje, { style: { background: "lightgreen", color: "Black" } })
        } else {
            if (nombre) {
                mensaje = nombre
            } else {
                mensaje = "Registro Eliminado"
            }
            toast.error(mensaje, { style: { background: "red", color: "white" } })
        }
    }

    /*---------------------------*/

    const handlePageChange = (page1) => {
        setParams({ ...params, page: page1 })
    }

    const handleRemove = async (id) => {
        let parametro = { id: id }
        const [data, error] = await fetchResource("/instructor/alumnos/dettach", "post", true, parametro)

        if (error) console.error(error)
        let alumnosFiltrado = alumnos.filter((data) => data.id !== id)
        setAlumnos(alumnosFiltrado)
    }

    const clearFilters = () => {
        setParams(paramsInitialValues)
    }

    const handleFilter = (e) => {
        const field = e.target.name
        const value = e.target.value
        switch (field) {
            case "nombre":
                setParams({ ...params, nombre: value, page: 0 })
                break
            case "apellido":
                setParams({ ...params, apellido: value, page: 0 })
                break
            case "categoria":
                setParams({ ...params, categoria: value, page: 0 })
                break
            case "escuela":
                setParams({ ...params, escuela: value, page: 0 })
                break
            case "instructor":
                setParams({ ...params, instructor: value, page: 0 })
                break
            case "asociacion":
                setParams({ ...params, asociacion: value, page: 0 })
                break
            case "edadDesde":
                setParams({ ...params, edadDesde: value, page: 0 })
                break
            case "edadHasta":
                setParams({ ...params, edadHasta: value, page: 0 })
                break
            case "actives":
                setParams({ ...params, actives: !params.actives })
                break
            default:
                break
        }
    }

    const Sign = async (user_id, tipo) => {
        //tipo=0 Borra inscripcion
        //tipo=1 Inscribe

        const body = [user_id]
        if (tipo == 1) {
            const [data, error] = await fetchResource(
                `/instructor/examen/signAlumnosToExam/${examenSelected}`,
                "put",
                true,
                body
            )
            if (data) {
                setAlumnos(
                    alumnos.map((a) => {
                        if (a.id == user_id) {
                            return { ...a, inscripto: true, examen_id: examenSelected }
                        } else {
                            return { ...a }
                        }
                    })
                )
                notify(tipo)
            } else {
                alert("error")
            }
        }
        if (tipo == 0) {
            const [data, error] = await fetchResource(
                `/instructor/examen/unSignAlumnosToExam/${examenSelected}`,
                "put",
                true,
                body
            )
            if (data) {
                setAlumnos(
                    alumnos.map((a) => {
                        if (a.id == user_id) {
                            return { ...a, inscripto: false, confirmado: false, pago: false, examen_id: null }
                        } else {
                            return { ...a }
                        }
                    })
                )
                notify(tipo)
            } else {
                alert("error")
            }
        }
    }

    const Pays = async (user_id, tipo) => {
        //tipo=0 No inscribe
        //tipo=1 Inscribe
        const body = [user_id]
        if (tipo == 1) {
            const [data, error] = await fetchResource(
                `/instructor/examen/markAlumnosExamAsPayed/${examenSelected}`,
                "put",
                true,
                body
            )
            if (data) {
                setAlumnos(
                    alumnos.map((a) => {
                        if (a.id == user_id) {
                            return { ...a, pago: true }
                        } else {
                            return { ...a }
                        }
                    })
                )
                notify(tipo)
            } else {
                notify(0, "Imposible registrar el pago")
                //  alert("error")
            }
        }
        if (tipo == 0) {
            const [data, error] = await fetchResource(
                `/instructor/examen/markAlumnosExamAsUnpayed/${examenSelected}`,
                "put",
                true,
                body
            )
            if (data) {
                setAlumnos(
                    alumnos.map((a) => {
                        if (a.id == user_id) {
                            return { ...a, pago: false }
                        } else {
                            return { ...a }
                        }
                    })
                )
                notify(tipo)
            } else {
                alert("error")
            }
        }
    }

    const handleSelect = (value) => {
        setExamenSelected(value.target.value)
        setParams({ ...params, page: 0 })
    }

    const handleClaseSelect = (value) => {
        if (value.target.value == 0) {
            setParams({ ...params, claseSelected: "", page: 0 })
        } else {
            setParams({ ...params, claseSelected: value.target.value, page: 0 })
        }
    }

    const handleDelete = async (id) => {
        // const parametro = {
        //     user_id: id,
        //     examen_id: examen.id
        // }
        // const [data, error] = await fetchResource('/instructor/examen/unsignToExam', 'post', true, parametro);

        // if (data) {
        //     setLoading(false)
        //     let filterAlumnos = alumnos.filter(alumno => (alumno.id !== id))
        //     if (filterAlumnos.length) {
        //         setAlumnos(filterAlumnos)
        //     } else {
        //         setAlumnos(null)
        //     }
        // } else {
        //     setLoading(false)
        // }
        setModalOpen(false)
    }

    const handleClose = () => {
        setModalOpen(false)
    }
    let fecha = new Date("2024-02-01")

    let day = fecha.getDate()
    let mont = fecha.getMonth()
    let year = fecha.getFullYear()
    console.log("dia ", day, "-", mont + 1, "-", year)

    const classes = Styles()

    return (
        <div className="alumnos-container">
            <Container title="INSCRIPCION EXAMEN" styles={{ display: "flex" }} more>
                <Toaster position="center-center" />

                <div
                    style={{
                        marginTop: 20,
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "white",
                        height: 80,
                        width: "100%",
                        borderRadius: 5,
                        padding: 10,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {examenes ? (
                        <Select
                            defaultValue={0}
                            onChange={handleSelect}
                            option={examenes}
                            id="examen"
                            variant="outlined"
                        >
                            <MenuItem disabled value={0}>
                                Seleccione Examen
                            </MenuItem>
                            {examenes.map((ex) => (
                                <MenuItem key={ex.id} value={ex.id} name={ex.fecha}>
                                    {" "}
                                    {"Nro: " +
                                        ex.id +
                                        "     " +
                                        ex.lugar +
                                        "    " +
                                        moment(ex.fecha).format("DD-MM-YYYY")}{" "}
                                </MenuItem>
                            ))}
                        </Select>
                    ) : (
                        <CircularProgress></CircularProgress>
                    )}
                </div>

                {examenSelected > 0 && (
                    <>
                        {/* <AlumnosTopInformation
                                    activesSwitch
                                    records={records}
                                    params={params}
                                    handleFilter={handleFilter}
                                />*/}
                        <InscripcionExamenDataFilters
                            basicData={basicData}
                            handleFilter={handleFilter}
                            clearFilters={clearFilters}
                            params={params}
                            handleClaseSelect={handleClaseSelect}
                            Nomclases={clases}
                            userRole={role_id}
                        />

                        <InstructorInscribeExamenTable
                            data={alumnos}
                            changePage={handlePageChange}
                            pageData={{ currentPage: params.page, records: records }}
                            loading={loading}
                            pays={Pays}
                            examSelect={examenSelected}
                            removeRecord={handleRemove}
                            sign={Sign}

                            //edit
                            //  edit={props.handleRecordEdit}
                            // addAndRemove={props.handleRecordEdit}
                            //  addAndRemove={handleRemove}
                        />
                    </>
                )}
            </Container>

            <Modal
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <ModalBody handleDelete={handleDelete} setModalOpen={setModalOpen} modalOpen={modalOpen} />
            </Modal>
        </div>
    )
}

export default InscripcionExamen

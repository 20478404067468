import React, { useState } from "react"
import * as Yup from "yup"
import Cookies from "universal-cookie"
import { useNavigate } from "react-router-dom"
import LoginForm from "./LoginForm"
import { handleErrors } from "../../../Functions/Functions"
import { CircularProgress } from "@material-ui/core"
import { fetchResource } from "../../../Functions/Functions"

const LoginHandler = ({ updateUser }) => {
    const navigate = useNavigate()
    const cookie = new Cookies()

    const [errors, setErrors] = useState(false)
    const [loading, setLoading] = useState(false)

    const initialValues = {
        dni: "",
        password: "",
        gettoken: true,
    }

    const validationSchema = Yup.object({
        dni: Yup.string().required("Campo requerido"),
        password: Yup.string().required("Campo requerido"),
    })

    const handleSubmit = async (values) => {
        setLoading(true)
        const [data, error] = await fetchResource("/users/login", "post", false, values)
        setErrors(error)

        if (error) {
            setLoading(false)
            handleErrors(error)
        } else {
            cookie.set("token", data.token)
            const [userData, userError] = await fetchResource("/users/show", "get", true, null)
            if (userData) {
                const logedUser = {
                    ...userData.user,
                    role_id: data.user.role_id,
                    logedIn: true,
                }
                cookie.set("user", logedUser)
                updateUser(logedUser)
                setLoading(false)
                navigate("/")
            } else {
                setLoading(false)
                console.error(userError)
            }
        }
    }

    if (loading) {
        return <CircularProgress color="secondary" />
    } else {
        return (
            <>
                <LoginForm
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    handleSubmit={handleSubmit}
                    errors={errors}
                />
            </>
        )
    }
}

export default LoginHandler

import React from "react"
import BodyLayout from "../Components/Layouts/BodyLayout"
import Container from "../Components/Formularios/Container"
import Logo from "../Assets/Imagenes/Logo_fit.png"
import AskResetHandler from "../Components/Formularios/PasswordReset/AskResetHandler"

const AskPasswordReset = () => {
    return (
        <BodyLayout>
            <Container logo={Logo}>
                <h2>Ingresa tu numero de Dni</h2>
                <AskResetHandler />
            </Container>
        </BodyLayout>
    )
}

export default AskPasswordReset

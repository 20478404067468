import React from "react"
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const pageStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "0.8em",
        margin: "1em",
        maxWidth: "90%",
    },
    topFiltersContainer: {
        display: "flex",
        justifyContent: "center",
        gap: "0.8em",
        flexWrap: "wrap",
    },
    bottomFiltersContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "0.8em",
    },
    button: {
        backgroundColor: "red",
    },
})

export const ActividadesFilters = (props) => {
    const clases = pageStyles()

    const { params, handleFilter, clearFilters, create, modo } = props

    return (
        <div className={clases.container}>
            <div className={clases.topFiltersContainer}>
                <TextField
                    variant="outlined"
                    size="small"
                    label="Nombre"
                    name="nombre"
                    type="text"
                    value={params.nombre}
                    onChange={handleFilter}
                />
                <TextField
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    size="small"
                    label="Fecha"
                    name="fecha"
                    type="date"
                    value={params.fecha}
                    onChange={handleFilter}
                />
                <FormControl
                    variant="outlined"
                    style={{ minWidth: 120, marginBottom: 0, paddingBottom: 0 }}
                    size="small"
                >
                    <InputLabel id="estado_inscripcion" style={{ color: "white" }}>
                        Inscripciones
                    </InputLabel>
                    <Select
                        label="estado"
                        name="actives"
                        id="estado_inscripcion"
                        defaultValue={params.actives}
                        variant="outlined"
                        onChange={handleFilter}
                    >
                        {console.log(params.actives)}
                        <MenuItem key={1} value={1}>
                            Activas
                        </MenuItem>
                        <MenuItem key={2} value={2}>
                            Inactivas
                        </MenuItem>
                        <MenuItem key={3} value={3}>
                            Terminadas
                        </MenuItem>
                    </Select>
                </FormControl>
                <div className={clases.bottomFiltersContainer}>
                    <Button onClick={clearFilters} className={clases.button}>
                        {" "}
                        Limpiar Filtros{" "}
                    </Button>
                </div>
                <Button onClick={create} className={clases.button}>
                    {" "}
                    {modo == false ? "-" : "+"}{" "}
                </Button>
            </div>
        </div>
    )
}

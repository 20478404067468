
import { Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react'
import Timer from './Timer/Timer';



const Styles = makeStyles({

    letraRoad: {
        width: 60,
        fontFamily: "Road_Rage",
        color: "red",
        textShadow: " -3px 3px 1px   black",
        marginLeft: -20,
        marginRight: -20,
        zIndex: 20,
        alignSelf: "center"
    },
    competidor: {
        fontFamily: "Forque",
        fontSize: 40,
        paddingLeft: 15,
        paddingRight: 15,
        color: "white"
    },
    resultado: {
        color: "green"
    }


})

const Match = (props) => {
    const clases = Styles()
    const { datos, area, categorias, selectCate, cancelButton, listo, tipoCompe } = props
    let competidor1 = datos[0]
    let competidor2 = datos[1]
   
    const [ganador, setGanador] = useState(
        {
            competidor1:  datos[0],
            competidor2: datos[1],
              ganador:null,
              perdedor:null,
              empate:null,
              win:null
            
        })

    const fallo = (gana) => {
       
        if (gana==2){
             setGanador({ ...ganador, empate: 2 ,win:2}) // empate
        }else if (datos[0].competidor_id == gana) {
              setGanador({ ...ganador,ganador: datos[0],perdedor:datos[1] ,win: datos[0]})
        }else{
            setGanador({ ...ganador,ganador: datos[1],perdedor:datos[0], win:datos[1] })
        }

    }

 console.log({ganador})  
    return (
        <div style={{ borderStyle: "solid", borderWidth: 1, borderRadius: 5, width: "100%", textAlign: "center", padding: "1%" }}>


            <div style={{ display: "flex", flexDirection: "row", borderStyle: "solid", borderWidth: 1, width: "100%", padding: 10, marginBottom: 20 }}>
                <div style={{ width: "30%", alignContent: "center" }} >
                    <Button style={{ background: "red" }} onClick={cancelButton}   >CANCELAR </Button>
                </div>

                <div style={{ width: "70%" }}>
                    <Timer duracion={{ m: 0, s: 5 }} />
                </div>

                <div style={{ width: "30%", alignContent: "center" }}>
                    <Button disabled={ganador.win !== null ? false : true} style={{ background: "GREEN" }} onClick={() => listo(ganador)}   >LISTO </Button>
                </div>
            </div>


            <div style={{ display: "flex", flexDirection: "row", width: "100%", textAlign: "center", justifyContent: "center" }}>
             
                <div id={datos[0].competidor_id} onClick={   (e) => {competidor1.competidor_id > 0 ?  fallo(e.currentTarget.id): fallo(0)  }  } 
                style={{ cursor: "pointer", borderStyle: "solid", borderWidth: 1, width: "50%", backgroundColor: ganador.win?.competidor_id == competidor1.competidor_id ? "red" : "transparent" }}>
                    <Typography className={clases.competidor} style={{ textShadow: "-5px 5px 5px red" }}  >{competidor1.nombre}</Typography>
                    <Typography className={clases.competidor} >{ganador.win?.competidor_id  == competidor1.competidor_id ? "GANADOR" : "--"}</Typography>
                </div>

                <Typography variant='h2' className={clases.letraRoad}>VS</Typography>

                <div id={datos[1].competidor_id} onClick={(e) => {competidor2.competidor_id > 0 ?  fallo(e.currentTarget.id): fallo(0) }} 
                style={{ cursor: "pointer", borderStyle: "solid", borderWidth: 1, width: "50%", 
                backgroundColor: ganador.win?.competidor_id == competidor2.competidor_id   ? "blue" : "transparent" }}>
                    <Typography className={clases.competidor} style={{ textShadow: "-5px 5px 5px blue" }}   >{competidor2.nombre}</Typography>
                    <Typography 
                      className={clases.competidor}>{  ganador.win?.competidor_id == competidor2.competidor_id  ? "GANADOR" : "--"}</Typography>
                </div>

            </div>

      {tipoCompe==2 &&
            <div id={0}
                disabled={true}
                onClick={() =>   fallo(2)  }
                style={{ cursor: "pointer", borderStyle: "solid", borderWidth: 1, width: "100%", marginTop: 20,
                 background: ganador.win == 2?"green":"transparent" }}>
                <Typography className={clases.competidor}> EMPATE  </Typography>
            </div>
}
        </div>
    )
}
export default Match
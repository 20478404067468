import { Button, IconButton, Input, MenuItem, Select, TextField, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import Containter from '../../../Components/Container/Container';
import LigaEncuentro from '../LigaEncuentro';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import toast, { Toaster } from 'react-hot-toast';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const Styles = makeStyles({
    tableContainer: {
        display: 'flex',
        justifyContent: 'center',
        minWidth: "90%",
        // borderStyle: "solid",
        borderWidth: 2,
        borderRadius: 10,
        height: 100,
    },
    renglon: {
        padding: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center",
        width: "100%",
        borderStyle: "solid",
        borderWidth: 2,
        borderRadius: 10,
        height: 90,
    },
    seccionNumero: {
        display: 'flex',
        flexDirection: "column",
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 10,
        borderColor: "grey",
        alignItems: "center",
        justifyContent: "center",
        height: 70,
        width: 180,
        margin: 5


    },
    seccion: {
        display: 'flex',
        flexDirection: "column",
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 10,
        borderColor: "grey",
        alignItems: "center",
        justifyContent: "center",
        height: 70,
        width: 400,
        margin: 5


    },
    seccionB: {
        display: 'flex',
        alignItems: "center",

    },
    textoB: {
        width: "100%",
        textAlign: "center",
        height: 10,
        fontSize: 12,

    },
    noDataContainer: {
        border: 'solid 1px white',
        padding: '0.2em 1em 0.2em 1em',
        borderRadius: '5px',
        minWidth: '90%',
    },
    filtros: {
        margin: 10,
        width: 150,
        height: 35,
        display: "flex",
        alignItems: "center"
    },
    filtrosNumero: {
        margin: 10,
        backgroundColor: "white",
        borderRadius: '5px',
        color: "black",
        width: 60,
        height: 35,
        display: "flex",
        alignItems: "center",

        paddingLeft: 15,
    },
    boton: {
        background: "red",
        textAlign: 'center',
    },
    noDataText: {
        textAlign: 'center',
    },
})

const CreaCategoria = ({ agregar, eliminarRenglon, disciplina, handleFilter, handleCompe, liga, dia, estado, pdf }) => {

    const clases = Styles()
    const [categorias, setCategorias] = useState([]);
    const [competidores, setCompetidores] = useState([]);
    const [alumnos, setAlumnos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [params, setParams] = useState({
        disciplina_id: "",
        sexo: 3,
        categoria_desde: 1,
        categoria_hasta: 14,
        edad_desde: 1,
        edad_hasta: 99,
        altura_desde: 10,
        altura_hasta: 250,
        peso_desde: 1,
        peso_hasta: 250,
        tipo_compe: 0,
        page: 0,
        liga_id: liga,
        dia: dia
    })

    useEffect(() => {
        fetchShowCategorias()
    }, [params])

console.log("DATOS dia cate",dia, liga)
 
    const filter = (x) => {
        let nombre = x.target.name
        let valor = x.target.value
        switch (nombre) {
            case "disciplinas":
                setParams({ ...params, disciplina_id: +valor, sexo: 3, tipo_compe: 0 })
                break;
            case "sexo":
                setParams({ ...params, sexo: parseInt(valor) })
                break;
            case "categoria_desde":
                setParams({ ...params, categoria_desde: parseInt(valor) })
                break;
            case "categoria_hasta":
                setParams({ ...params, categoria_hasta: parseInt(valor) })
                break;
            case "edad_desde":
                setParams({ ...params, edad_desde: parseInt(valor) })
                break;
            case "edad_hasta":
                setParams({ ...params, edad_hasta: parseInt(valor) })
                break;
            case "peso_desde":
                setParams({ ...params, peso_desde: parseInt(valor) })
                break;
            case "peso_hasta":
                setParams({ ...params, peso_hasta: parseInt(valor) })
                break;
            case "altura_desde":
                setParams({ ...params, altura_desde: Math.round(valor) })
                break;
            case "altura_hasta":
                setParams({ ...params, altura_hasta: Math.round(valor) })
                break;
            case "tipo":
                setParams({ ...params, tipo_compe: parseInt(valor) })
                break;
            default:
                break;
        }

    }

    const fetchShowCategorias = async () => {
        try {
            setLoading(true)
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/filterCategory`,
                {
                    method: "POST",
                    headers: { 'Content-type': 'application/json' },
                    body: JSON.stringify(params)
                })
            let response = await res.json()
            if (response.code == 200) {
                console.log({ response })
                setCategorias(response.categorias)
                setCompetidores(response.lista)
                setAlumnos({
                    ...alumnos, disponibles: response.alumnos_disponibles,
                    ocupados: response.alumnos_ocupados
                })
                setLoading(false)
            } else if (response.code == 400) {
                setCategorias([])
                // setNombreDisc(response.disciplina)
                setCompetidores(response.lista)
                setAlumnos({
                    ...alumnos, disponibles: response.alumnos_disponibles,
                    ocupados: response.alumnos_ocupados
                })
                setLoading(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const agregarRenglon = (p) => {

        if (p.disciplina_id > 0 && p.tipo_compe > 0) {

            fetchAgregaCategorias(p)

        } else {
            notify(0, "Falta datos Importantes, INGRESE disciplinia y tipo de competencia")
        }

    }

    const fetchAgregaCategorias = async (categoria) => {
        try {
            console.log({ categoria })
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/addCategory`,
                {
                    method: "POST",
                    headers: { 'Content-type': 'application/json' },
                    body: JSON.stringify(categoria)
                })

            let response = await res.json()

            if (response.code == 200) {

                setCategorias(response.categorias)
                setAlumnos({
                    ...alumnos, disponibles: response.alumnos_disponibles,
                    ocupados: response.alumnos_ocupados
                })
             //   setDel(!del)
                notify(1, "Categoria Agregada correctamente.")

            } else {

                setAlumnos({
                    ...alumnos, disponibles: response.alumnos_disponibles,
                    ocupados: response.alumnos_ocupados
                })

                notify(0, response.mensaje)
            }
        } catch (error) {
            console.log(error)
        }

    }
    const notify = (x, nombre) => {
        if (x == 1) {
            toast.success(nombre + '.', { style: { background: 'lightgreen', color: "Black" } });
        } else {
            toast.error(nombre + '.', { style: { background: 'red', color: "white" } });
        }
    }
    const columnsCompetidores = [
        { field: 'id', headerName: 'id', minWidth: 40 },
        { field: 'nombre', headerName: 'Nombre', minWidth: 250, renderCell: row => <div>{row.row.nombre + " " + row.row.apellido || 'No Indicado'}</div> },
        // {
        //     field: 'PDF', headerName: 'PDF', width: 50,
        //     renderCell: row => {
        //         return (
        //             <IconButton style={{ color: "red", width: "70%" }}

        //                 onClick={() => pdf(row.row.id)}>
        //                 <Tooltip title="IMPRIMIR PLANILLA">
        //                     <PictureAsPdfIcon fontSize="medium" />
        //                 </Tooltip>
        //             </IconButton>
        //         )
        //     }
        // },
        { field: 'sexo', headerName: 'Sexo', width: 110, renderCell: row => <div>{nombreSexo(row.row.sexo) || 'No Indicado'}</div> },
        { field: 'categoria', headerName: 'Categorias', width: 150, renderCell: row => <Typography style={{ wordSpacing: 7, width: "100%", textAlign: "center", fontSize: 9 }}> {row.row.categoria}      </Typography> },
        { field: 'edad', headerName: 'Edad', width: 150, renderCell: row => <Typography style={{ width: "100%", textAlign: "center" }}>{row.row.edad || 'No Indicado'}</Typography> },
        { field: 'altura', headerName: 'Altura (cm)', width: 150, renderCell: row => <Typography style={{ width: "100%", textAlign: "center" }}>{row.row.altura || 'No Indicado'}</Typography> },
        { field: 'peso', headerName: 'Peso (mt)', width: 150, renderCell: row => <Typography style={{ width: "100%", textAlign: "center" }}>{row.row.peso || 'No Indicado'}</Typography> },
        // { field: 'cantidad', headerName: 'Competidores', minWidth: 170, renderCell: row => <div>{row.row.cantidad}</div> },
        // {
        //     field: 'borrar', headerName: 'Eliminar', width: 130,
        //     renderCell: row => {
        //         return (
        //             <IconButton style={{ color: "white", width: "70%" }}
        //                 disabled={estado == 1 ? true : false}
        //                 onClick={() => eliminarRenglon(row.row.id)}>
        //                 <Tooltip title="Eliminar Categoria">
        //                     <DeleteForeverRoundedIcon fontSize="medium" />
        //                 </Tooltip>
        //             </IconButton>
        //         )
        //     }
        // },
        // {
        //     field: 'tipo', headerName: 'Compe', width: 130,
        //     renderCell: row => {
        //         return (<Typography>{row.row.tipo_compe == 1 ? "Eliminacion" : row.row.tipo_compe == 2 ? "Grupo" : row.row.tipo_compe == 3 ? "Planilla" : "sin seleccion"}</Typography>

        //         )
        //     }
        // },
    ]
    const columns = [
        { field: 'id', headerName: 'id', minWidth: 50 },
        {
            field: 'PDF', headerName: 'PDF', width: 10,
            renderCell: row => {
                return (
                    <IconButton style={{ color: "red", width: "70%" }}
                        onClick={() => pdf(row.row.id)}>
                        <Tooltip title="IMPRIMIR PLANILLA">
                            <PictureAsPdfIcon fontSize="medium" />
                        </Tooltip>
                    </IconButton>
                )
            }
        },
        { field: 'sexo', headerName: 'Sexo', width: 110, renderCell: row => <div>{nombreSexo(row.row.sexo) || 'No Indicado'}</div> },
        { field: 'categoria', headerName: 'Categorias', width: 200, renderCell: row => <Typography style={{ wordSpacing: 7, width: "100%", textAlign: "center" }}> {nombreCategoria(row.row.categoria_desde)}  a  {nombreCategoria(row.row.categoria_hasta)}   </Typography> },
        { field: 'edad', headerName: 'Edad', width: 110, renderCell: row => <Typography style={{ width: "100%", textAlign: "center" }}>{`${row.row.edad_desde}  a  ${row.row.edad_hasta}` || 'No Indicado'}</Typography> },
        { field: 'altura', headerName: 'Altura (cm)', width: 150, renderCell: row => <Typography style={{ width: "100%", textAlign: "center" }}>{` ${row.row.altura_desde} -  ${row.row.altura_hasta}  ` || 'No Indicado'}</Typography> },
        { field: 'peso', headerName: 'Peso (Kg)', width: 140, renderCell: row => <Typography style={{ width: "100%", textAlign: "center" }}>{` ${row.row.peso_desde} -  ${row.row.peso_hasta}  ` || 'No Indicado'}</Typography> },
        { field: 'cantidad', headerName: 'Cant.', minWidth: 120, renderCell: row => <div>{row.row.cantidad}</div> },
        {
            field: 'borrar', headerName: 'Eliminar', width: 110,
            renderCell: row => {
                return (
                    <IconButton style={{ color: "white", width: "70%" }}
                       // disabled={estado == 1 ? true : false}
                        onClick={() => eliminarRenglon(row.row.id)}>
                        <Tooltip title="Eliminar Categoria">
                            <DeleteForeverRoundedIcon fontSize="medium" />
                        </Tooltip>
                    </IconButton>
                )
            }
        },
        {
            field: 'tipo', headerName: 'Compe', width: 130,
            renderCell: row => {
                return (<Typography>{row.row.tipo_compe == 1 ? "Eliminacion" : row.row.tipo_compe == 2 ? "Grupo" : row.row.tipo_compe == 3 ? "Planilla" : "sin seleccion"}</Typography>

                )
            }
        },
    ]
    const nombreSexo = (s) => {
        let sex = ""
        if (s == 1) { sex = "Femenino" } else if (s == 2) { sex = "Masculino" } else { sex = "Mixto" }
        return sex
    }
    const nombreCategoria = (c) => {
        let cat = ""
        switch (c) {
            case 1:
                cat = "Blanco";
                break;
            case 2:
                cat = "p/Amarilla";
                break;
            case 3:
                cat = "Amarillo";
                break;
            case 4:
                cat = "p/Verde";
                break;
            case 5:
                cat = "Verde";
                break;
            case 6:
                cat = "p/Azul";
                break;
            case 7:
                cat = "Azul";
                break;
            case 8:
                cat = "p/Roja";
                break;
            case 9:
                cat = "Rojo";
                break;
            case 10:
                cat = "p/Negra";
                break;
            case 6:
                cat = "p/Azul";
                break;
            case 11:
                cat = "1º Dan";
                break;
            case 12:
                cat = "2º Dan";
                break;
            case 13:
                cat = "3º Dan";
                break;
            case 14:
                cat = "4º Dan";
                break;
            case 15:
                cat = "5º Dan";
                break;


            default:
                cat = "  full"
                break;
        }
        return cat
    }

    return (
        <div  >
  <Toaster position="top-center" />
            <div className={clases.renglon}>
          
                <Select
                    onChange={(e) => { filter(e) }}
                    className={clases.filtros}
                    value={params.disciplina_id ? params.disciplina_id : 0}
                    name={"disciplinas"}
                >
                    <MenuItem disabled id={0} value={0}>Seleccione Disciplina</MenuItem>
                    <MenuItem id={1} value={1} name={"FORMAS"}>FORMAS</MenuItem>
                    <MenuItem id={2} value={2} name={"LUCHA LIBRE"}  >LUCHA LIBRE</MenuItem>
                    <MenuItem id={3} value={3} name={"ROTURA POTENCIA"}  >ROTURA POTENCIA</MenuItem>
                    <MenuItem id={4} value={4} name={"SALTO"}  >SALTO</MenuItem>
                </Select>
                <Select
                    onChange={(e) => filter(e)}
                    className={clases.filtros}
                    value={params.sexo ? params.sexo : 3}
                    name={"sexo"}
                >
                    <MenuItem disabled id={0} value={0}>sexos</MenuItem>
                    <MenuItem id={1} value={1} label={"Femenino"}>Femenino</MenuItem>
                    <MenuItem id={2} value={2} name={"Masculino"}  >Masculino</MenuItem>
                    <MenuItem id={3} value={3} name={"Mixto"}  >Mixto</MenuItem>
                </Select>
                <Select
                    onChange={(e) => filter(e)}
                    className={clases.filtros}
                    value={params.tipo_compe ? params.tipo_compe : 0}
                    name={"tipo"}
                >
                    <MenuItem disabled id={0} value={0}>Seleccione Tipo</MenuItem>
                    {params.disciplina_id < 3 && <MenuItem id={1} value={1} label={"Eliminacion Directa"}>Eliminacion Directa</MenuItem>}
                    {params.disciplina_id < 3 && <MenuItem id={2} value={2} name={"Grupos 2 rondas"}  >Grupos 2 rondas</MenuItem>}
                    {params.disciplina_id > 2 && <MenuItem id={3} value={3} name={"Planilla"}  >Planilla</MenuItem>}
                </Select>
                <div style={{ display: "flex", flexDirection: "column", marginLeft: 50 }}>
                    <Typography variant='h6' style={{textAlign:"center"}}> DIA: {dia}</Typography>
                    <Typography>  Competidores sin Categoria: {alumnos.disponibles}</Typography>
                    <Typography>  competidores en categoria propuesta: {alumnos.ocupados}</Typography>
                </div>


            </div>
            <div className={clases.tableContainer}>
                <div className={clases.renglon}>

                    <div className={clases.seccion}>
                        <Typography className={clases.textoB}>Categoria</Typography>

                        <div className={clases.seccionB} >
                            <Select
                                onChange={(e) => filter(e)}
                                className={clases.filtros}
                                name={"categoria_desde"}
                                defaultValue={params.categoria_desde ? params.categoria_desde : 0}>
                                <MenuItem disabled id={0} value={0}>categoria desde</MenuItem>
                                <MenuItem id={1} value={1}>Blanco</MenuItem>
                                <MenuItem id={2} value={2}>P/Amarilla</MenuItem>
                                <MenuItem id={3} value={3}>Amarillo</MenuItem>
                                <MenuItem id={4} value={4}>P/Verde</MenuItem>
                                <MenuItem id={5} value={5}>Verde</MenuItem>
                                <MenuItem id={6} value={6}>P/Azul</MenuItem>
                                <MenuItem id={7} value={7}>Azul</MenuItem>
                                <MenuItem id={8} value={8}>P/Roja</MenuItem>
                                <MenuItem id={9} value={9}>Rojo</MenuItem>
                                <MenuItem id={10} value={10}>P/Negra</MenuItem>
                                <MenuItem id={11} value={11}>1er Dan</MenuItem>
                                <MenuItem id={12} value={12}>2do Dan</MenuItem>
                                <MenuItem id={13} value={13}>3er Dan</MenuItem>
                                <MenuItem id={14} value={14}>4to Dan</MenuItem>

                            </Select>
                            <Typography>a</Typography>
                            <Select
                                onChange={(e) => filter(e)}
                                className={clases.filtros}
                                name={"categoria_hasta"}
                                defaultValue={params.categoria_hasta ? params.categoria_hasta : 0}>
                                <MenuItem disabled id={0} value={0}>categoria hasta</MenuItem>
                                <MenuItem id={1} value={1}>Blanco</MenuItem>
                                <MenuItem id={2} value={2}>P/Amarilla</MenuItem>
                                <MenuItem id={3} value={3}>Amarillo</MenuItem>
                                <MenuItem id={4} value={4}>P/Verde</MenuItem>
                                <MenuItem id={5} value={5}>Verde</MenuItem>
                                <MenuItem id={6} value={6}>P/Azul</MenuItem>
                                <MenuItem id={7} value={7}>Azul</MenuItem>
                                <MenuItem id={8} value={8}>P/Roja</MenuItem>
                                <MenuItem id={9} value={9}>Rojo</MenuItem>
                                <MenuItem id={10} value={10}>P/Negra</MenuItem>
                                <MenuItem id={11} value={11}>1er Dan</MenuItem>
                                <MenuItem id={12} value={12}>2do Dan</MenuItem>
                                <MenuItem id={13} value={13}>3er Dan</MenuItem>
                                <MenuItem id={14} value={14}>4to Dan</MenuItem>
                            </Select>
                        </div>
                    </div>
                    <div className={clases.seccionNumero}>

                        <Typography className={clases.textoB}>edad</Typography>

                        <div className={clases.seccionB} >
                            <Input
                                className={clases.filtrosNumero}
                                type='number'
                                value={params.edad_desde}
                                onChange={(e) => { filter(e); setParams({ ...params, edad_desde: e.target.value }) }}
                                name={"edad_desde"}
                            ></Input>
                            <Typography>a</Typography>
                            <Input
                                className={clases.filtrosNumero}
                                type='number'
                                defaultValue={params.edad_hasta}
                                onChange={(e) => { filter(e); setParams({ ...params, edad_hasta: e.target.value }) }}
                                name={"edad_hasta"}
                            />
                        </div>

                    </div>

                    <div className={clases.seccionNumero}>
                        <Typography className={clases.textoB}>Peso (kg.)</Typography>
                        <div className={clases.seccionB} >
                            <Input
                                className={clases.filtrosNumero}
                                type='number'
                                value={params.peso_desde}
                                onChange={(e) => { filter(e); setParams({ ...params, peso_desde: e.target.value }) }}
                                name={"peso_desde"}
                            ></Input>
                            <Typography>a</Typography>
                            <Input
                                className={clases.filtrosNumero}
                                type='number'
                                value={params.peso_hasta}
                                onChange={(e) => { filter(e); setParams({ ...params, peso_hasta: e.target.value }) }}
                                name={"edad_hasta"}
                            />
                        </div>
                    </div>

                    <div className={clases.seccionNumero}>
                        <Typography className={clases.textoB}>Altura (centimetros)</Typography>

                        <div className={clases.seccionB} >
                            <Input
                                className={clases.filtrosNumero}
                                style={{ width: 60 }}
                                type='number'
                                value={params.altura_desde}
                                onChange={(e) => { filter(e); setParams({ ...params, altura_desde: Math.round(e.target.value) }) }}
                                name={"altura_desde"}
                            ></Input>
                            <Typography>a</Typography>
                            <Input
                                className={clases.filtrosNumero}
                                type='number'
                                value={params.altura_hasta}
                                onChange={(e) => { filter(e); setParams({ ...params, altura_hasta: e.target.value }) }}
                                name={"altura_hasta"}
                            />
                        </div>
                    </div>
                    <Button  className={clases.boton} onClick={() => agregarRenglon(params)}   >agregar</Button>

                </div>
            </div>

            <div>   {/* Tabla */}
                <DataGrid
                    rows={categorias}
                    autoHeight
                    columns={columns}
                    pageSizeOptions={[5]}
                    hideFooter
                    loading={loading}

                />

            </div>

            <Typography variant='h4' style={{ textAlign: "center", marginTop: "40px", marginBottom: "10px" }}>COMPETIDORES </Typography>
            <div>   {/* Tabla COMPETIDORES SELECCIONADOS */}
                <DataGrid
                    rows={competidores}
                    autoHeight
                    columns={columnsCompetidores}
                    pageSizeOptions={[5]}
                    hideFooter
                    loading={loading}
                />

            </div>

        </div>




    )
}

export default CreaCategoria
import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react'
import moment from 'moment';

const Styles = makeStyles({
    tableContainer: {
        width: "100%",
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: "center",
        marginBottom: 30,
        borderStyle: "solid",
        borderRadius: 10,
        borderWidth:0.5,
        padding: 10,
        backgroundColor:"darkred"

    },
    noDataContainer: {
        border: 'solid 1px white',
        padding: '0.2em 1em 0.2em 1em',
        borderRadius: '5px',
        minWidth: '90%',
    },
    filtros: {
        margin: 10
    },
    botonSi: {
        textAlign: 'center',
        background: "green",
        height: 50,
        margin: 10
    },
    botonNormal: {
        textAlign: 'center',
        background: "red",
        height: 50,
        margin: 10
    },
    botonNo: {
        textAlign: 'center',
        background: "red",
        height: 50,
        margin: 10
    },
    subtitulo: {
        color: "orange",
        textAlign: "center",
        width: "100%"
    },

    nombre: {
        cursor: "pointer",
    },
    text: {

        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        marginLeft: 20,
        marginRight: 20,
        height: 50,


    },
})

const AreasCentralHeader = (props) => {

    const {
        datos,
        params,
        handleFilter
    } = props;
    
    const clases = Styles()
    const [categorias, setCategorias] = useState([])
    const [liga_id, setLiga_id] = useState(1)
    const [dia, setDia] = useState(1)

    const [tablaPosiciones, setTablaPosiciones] = useState([])


    console.log({ datos })


    useEffect(() => {

    }, [])

    return (
    <div className={clases.tableContainer}>

       
            <div  >





                <FormControl variant='outlined' style={{ minWidth: 120, margin: 5, paddingBottom: 0 }} size="small" >
                    <InputLabel id="disciplina" style={{ color: 'white' }}>Disciplina</InputLabel>
                    <Select
                        label="disciplina"
                        name='disciplina'
                        id='disciplina'
                        defaultValue={params.disciplina}
                        value={params.disciplina}
                        variant='outlined'
                        onChange={handleFilter}
                    >
                        <MenuItem key={0} value={0}>Todas</MenuItem>
                        {datos?.disciplinas?.map((x) => {
                            return (<MenuItem key={x.id} value={x.id}>{x.nombre}</MenuItem>)
                        })
                        }
                    </Select>
                </FormControl>

                <FormControl variant='outlined' style={{ minWidth: 120, margin: 5, paddingBottom: 0 }} size="small" >
                    <InputLabel id="sexo" style={{ color: 'white' }}>sexo</InputLabel>
                    <Select
                        label="sexo"
                        name='sexo'
                        id='sexo'
                        defaultValue={params.sexo}
                        variant='outlined'
                        onChange={handleFilter}
                    >
                        <MenuItem key={0} value={0}>Todos</MenuItem>
                        {datos?.sexo?.map((x) => {
                            return (<MenuItem key={x.id} value={x.id}>{x.sexo}</MenuItem>)
                        })
                        }
                    </Select>
                </FormControl>

                <FormControl variant='outlined' style={{ minWidth: 120, margin: 5, paddingBottom: 0 }} size="small" >
                    <InputLabel id="area" style={{ color: 'white' }}>Area</InputLabel>
                    <Select
                        label="area"
                        name='area'
                        id='area'
                        defaultValue={params.area}
                        variant='outlined'
                        onChange={handleFilter}
                    >
                        <MenuItem key={0} value={0}>Todas</MenuItem>
                        {datos?.areas?.map((x) => {
                            return (<MenuItem key={x.id} value={x.id}>{x.area}</MenuItem>)
                        })
                        }
                    </Select>
                </FormControl>

                <FormControl variant='outlined' style={{ minWidth: 120, margin: 5, paddingBottom: 0 }} size="small" >
                    <InputLabel id="liga" style={{ color: 'white' }}>Liga</InputLabel>
                    <Select
                        label="liga"
                        name='liga'
                        id='liga'
                        defaultValue={params.liga_id}

                        variant='outlined'
                        onChange={handleFilter}
                    >
                        <MenuItem key={0} value={0}>Todas</MenuItem>
                        {datos?.areas?.map((x) => {
                            return (<MenuItem key={x.id} value={x.id}>{x.area}</MenuItem>)
                        })
                        }
                    </Select>
                </FormControl>
                <FormControl variant='outlined' style={{ minWidth: 120, margin: 5, paddingBottom: 0 }} size="small" >
                    <InputLabel id="dia" style={{ color: 'white' }}>Dia</InputLabel>
                    <Select
                        label="dia"
                        name='dia'
                        id='dia'
                        defaultValue={params.dia}
                        variant='outlined'
                        onChange={handleFilter}
                    >

                        {datos?.fechas?.map((x) => {
                            return (<MenuItem key={x.dia_num} value={x.dia_num}>{"Dia " + x.dia_num + " (" + moment(x.fecha).format("DD-MM-YYYY") + ")"}</MenuItem>)
                        })
                        }
                    </Select>
                </FormControl>
            </div>

            <div>

                <FormControl variant='outlined' style={{ minWidth: 120, margin: 5, paddingBottom: 0 }} size="small" >
                    <InputLabel id="area" style={{ color: 'white' }}>Categoria Desde</InputLabel>
                    <Select
                        label="Categoria Desde"
                        name='CategoriaDesde'
                        id='CategoriaDesde'
                        defaultValue={params.categoria_desde}
                        variant='outlined'
                        onChange={handleFilter}
                    >
                        <MenuItem key={0} value={0}>Todas</MenuItem>
                        {datos?.graduaciones?.map((x) => {
                            return (<MenuItem key={x.id} value={x.id}>{x.graduacion}</MenuItem>)
                        })
                        }
                    </Select>

                </FormControl>

                <FormControl variant='outlined' style={{ minWidth: 120, margin: 5, paddingBottom: 0 }} size="small" >
                    <InputLabel id="area" style={{ color: 'white' }}>Categoria Hasta</InputLabel>
                    <Select
                        label="Categoria Hasta"
                        name='CategoriaHasta'
                        id='Categoria Hasta'
                        defaultValue={params.categoria_hasta}
                        variant='outlined'
                        onChange={handleFilter}
                    >
                        <MenuItem key={0} value={0}>Todas</MenuItem>
                        {datos?.graduaciones?.map((x) => {
                            return (<MenuItem key={x.id} value={x.id}>{x.graduacion}</MenuItem>)
                        })
                        }
                    </Select>

                </FormControl>



                <TextField
                    style={{ minWidth: 120, margin: 5, paddingBottom: 0 }}
                    InputProps={{ inputProps: { min: 0 } }}
                    variant='outlined'
                    size='small'
                    label='Edad Desde'
                    name='edadDesde'
                    type='number'
                    value={params.edad_desde}
                    onChange={handleFilter}
                />
                <TextField
                    style={{ minWidth: 120, margin: 5, paddingBottom: 0 }}
                    InputProps={{ inputProps: { min: 0 } }}
                    variant='outlined'
                    size='small'
                    label='Edad Hasta'
                    name='edadHasta'
                    type='number'
                    value={params.edad_hasta}
                    onChange={handleFilter}
                />
            </div>
       
    </div>)
}
export default AreasCentralHeader
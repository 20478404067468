const initialState = {
    user: {
        id: "",
        dni: "",
        nombre: "",
        apellido: "",
        role_id: "",
        foto: "",
        clase_id: "",
        centro_id: "",
        escuela_id: "",
        email: "",
        nacimiento: "",
        instructor_id: "",
        logedIn: false,
    },
}

export default (state = initialState, actions) => {
    if (actions.type === "UPDATE_USER") {
        return {
            ...state,
            user: actions.payload,
        }
    }
    return state
}

export const selectActiveUser = (state) => state.userReducer.user

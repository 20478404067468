
import React, { useEffect, useState, useRef } from 'react'

import { QrReader } from 'react-qr-reader';



const QRreader = (props) => {

    const { dni } = props



    const URL_LENGTH = 31

    const handleResult = (result, error) => {
        if (result) {
            const preDni = result?.text
            const dniData = String(preDni).slice(URL_LENGTH, String(preDni).length)

            if (dniData.length > 0) {

                dni(dniData)
            } else {


            }

        } else {
            console.error(error) //TODO: despues de usarmme borrame  forro 
        }

    }

    return (



        <div style={{ width: '100%', height: "auto" }}>
            <QrReader
                onResult={(result, error) => handleResult(result, error)}
                style={{ width: '100%', margin: 0 }}
                scanDelay={500}
                constraints={{ facingMode: 'environment' }}
            />
        </div>


    )
}

export default QRreader;

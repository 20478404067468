import React, { useEffect, useState } from "react"
import Container from "../../Components/Container/Container"
import Typography from "@material-ui/core/Typography"
import { CircularProgress, makeStyles } from "@material-ui/core"
import MatriculasActual from "../../Components/Matriculas/MatriculasActual"
import { fetchResource } from "../../Functions/Functions"
import { DataGrid } from "@mui/x-data-grid"
import DefaultMessage from "../../Components/DefaultMessage/DefaultMessage"

const Styles = makeStyles({
    intro: {
        textAlign: "center",
        marginTop: 10,
    },
    matriculasContainer: {
        marginBottom: "2em",
        width: "100%",
    },
    currentWraper: {
        width: "100%",
        padding: "2em 0.2em",
        border: "solid 1px white",
        marginTop: 10,
    },
    table: {
        width: "900px",
    },
})

export const matriculasInitialData = {
    actual: false,
    matriculas: [],
}

const Matricula = () => {
    const classes = Styles()
    const [loading, setLoading] = useState(true)
    const [matriculas, setMatriculas] = useState(matriculasInitialData)

    useEffect(() => {
        const fetchMatriculaData = async () => {
            const [data, error] = await fetchResource("/users/matriculas/show", "get", true, null)
            if (data) {
                setMatriculas(data.data)
                setLoading(false)
            } else {
                console.error(error)
                setMatriculas([])
                setLoading(false)
            }
        }
        fetchMatriculaData()
    }, [])

    return (
        <div className="proximamente-container">
            <Container title="Mi  Matricula" more={true}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <div className={classes.matriculasContainer}>
                        <div className={classes.currentWraper}>
                            <MatriculasActual matricula={matriculas} />
                        </div>

                        <MatriculasList className={classes.table} list={matriculas.matriculas} />
                    </div>
                )}
            </Container>
        </div>
    )
}

export default Matricula

const MatriculasList = ({ list }) => {
    const columns = [
        { field: "year", headerName: "Año", width: 140 },
        {
            field: "created_at",
            headerName: "Fecha Registro",
            width: 155,
            renderCell: (row) => <div>{row.row.created_at.split("T")[0]}</div>,
        },
    ]

    return (
        <div>
            {!list?.length ? (
                <DefaultMessage
                    text="Cualquier inconveniente consulte a su intructor directo"
                    title={"Sin Registro de Pagos"}
                    logo
                />
            ) : (
                //    <Typography >Consulte a su instructor por el estado de las matriculas</Typography>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 10,
                        margin: "2em",
                    }}
                >
                    <Typography variant="h6">Listado de Matriculas registradas</Typography>
                    <DataGrid
                        style={{ minWidth: "298px" }}
                        rows={list}
                        columns={columns}
                        rowsPerPageOptions={[]}
                        autoHeight
                        disableColumnMenu
                        disableColumnFilter
                        disableSelectionOnClick
                        isRowSelectable={false}
                        hideFooterPagination
                        hideFooter
                    />
                </div>
            )}
        </div>
    )
}

import React, { useState, useEffect } from "react"
import Container from "../Container/Container"
import { useSelector } from "react-redux"
import { Button, CircularProgress, makeStyles, Box, Typography } from "@material-ui/core"
import { fetchResource } from "../../Functions/Functions"
import moment from "moment"
import { useNavigate } from "react-router-dom"

import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import WhatsAppIcon from "@mui/icons-material/WhatsApp"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import ReactWhatsapp from "react-whatsapp"
import { AlertTitle } from "@mui/material"

const Styles = makeStyles({
    button: {
        width: "210px",
        backgroundColor: "#e00",
    },
    buttonDisabled: {
        width: "210px",
        backgroundColor: "#291f21",
    },
})
const stepStyles = {
    "& .Mui-active": {
        "&.MuiStepIcon-root": {
            color: "orange",
        },
        "&.MuiStepLabel-label": { color: "white" },
    },
    "& .Mui-disabled": { color: "gray" },

    "& .Mui-completed": {
        "&.MuiStepIcon-root": {
            color: "green",
        },
        "&.MuiStepLabel-label": { color: "green" },
    },
}

const NewExamenLayout = (instructor_id) => {
    const basicData = useSelector((state) => ({ ...state["basicDataReducer"] }))
    const { instructores } = basicData.basicData

    const classes = Styles()
    const navigate = useNavigate()

    const [dias, setDias] = useState(0)
    const [dataExamen, setDataExamen] = useState()
    const [loading, setLoading] = useState(true)
    const [index, setIndex] = useState(1)

    useEffect(() => {
        const checkDataExam = async () => {
            const [data, error] = await fetchResource("/users/examen/availableExams", "get", true)
            if (data) {
                data.data.map((x) => {
                    if (x.inscripcion_activa == 1) {
                        setDataExamen(x)
                        if ((x.confirmacion == false) & (x.pago == false)) {
                            setIndex(1)
                        }
                        if ((x.confirmacion == true) & (x.pago == false)) {
                            setIndex(2)
                        }
                        if ((x.confirmacion == true) & (x.pago == true)) {
                            setIndex(3)
                        }
                    }
                })
            }
            setLoading(false)
        }
        checkDataExam()
    }, [])

    const telefonoInstructor = () => {
        let x = "No entro"
        instructores.map((i) => {
            if (i.id == instructor_id.instructor_id) {
                x = i.telefono
            }
        })
        return x
    }

    const confirmExam = async () => {
        const [data, error] = await fetchResource(`/users/examen/alumnoExamConfirmation/${dataExamen.id}`, "put", true)
        if (data) {
            setIndex(2)
        }
        setLoading(false)
    }

    const removeConfirmExam = async () => {
        const [data, error] = await fetchResource(
            `/users/examen/alumnoExamCancelConfirmation/${dataExamen.id}`,
            "put",
            true
        )
        if (data) {
            setIndex(1)
        }
        setLoading(false)
    }

    const mensaje = [
        "Esperando COnfirmacion",
        "El Instructor considera que el alumno esta en condiciones, se solicita confirmacion de asistencia.",
        "Gracias por la confirmacion, el alumnos estara inscripto una vez confirmado el pago.",
        "El Alumno ya se encuentra Inscripto, verifica el dia y horario del examen. Exitos!!",
    ]

    const mensajeButton = (i) => {
        if (i == 1) {
            return "Confirmo Asistencia"
        }
        if (i == 2) {
            return "Cancelar Asistencia"
        }
        if (i > 2) {
            return "Confirmo Asistencia"
        }
    }

    const buttonClick = (i) => {
        if (i == 1) {
            confirmExam()
        }
        if (i == 2) {
            removeConfirmExam()
        }
    }
    const Whats = (numero, mensaje) => {
        let url = `https://web.whatsapp.com/send?phone=${numero}`
        url += `&text=${encodeURI(mensaje)}&app_absent=0`
        window.open(url)
    }

    const PDF_download = () => {
        window.open("https://prod.somacontact.com/teoriaPDF/Teoria_Blancos_2023.pdf")
    }

    return (
        <Container more styles={{ display: "block", textAlign: "center" }} title={"INSCRIPCION DE EXAMEN"}>
            <Typography style={{ marginTop: 20 }}>{mensaje[index]}</Typography>

            <Box paddingTop={5} sx={{ width: "100%" }}>
                <Stepper activeStep={index} alternativeLabel sx={stepStyles}>
                    <Step key={"1"}>
                        <StepLabel>{"Autorizado"}</StepLabel>
                    </Step>

                    {index == 1 && (
                        <Step key={"2"}>
                            <StepLabel>{"Pendiente de Confirmacion"}</StepLabel>
                        </Step>
                    )}
                    {index == 1 && (
                        <Step key={"3"}>
                            <StepLabel>{"Pago Pendiente  "}</StepLabel>
                        </Step>
                    )}

                    {index > 1 && (
                        <Step key={"2"}>
                            <StepLabel>{"Confirmado"}</StepLabel>
                        </Step>
                    )}
                    {index == 2 && (
                        <Step key={"3"}>
                            <StepLabel>{"Pago Pendiente"}</StepLabel>
                        </Step>
                    )}

                    {index > 2 && (
                        <Step key={"3"}>
                            <StepLabel>{"Pago Realizado"}</StepLabel>
                        </Step>
                    )}
                </Stepper>
            </Box>
            {dataExamen && (
                <div style={{ width: "100%", height: "35%", borderStyle: "solid", borderWidth: 2, marginTop: 40 }}>
                    <Typography style={{ fontSize: 30, fontWeight: "bold", margin: 15 }}>Datos del Examen</Typography>

                    <Typography>Fecha: {moment(dataExamen?.fecha).format("DD-MM-YYYY")}</Typography>
                    <Typography>Lugar: {dataExamen?.lugar.toUpperCase()}</Typography>
                    <Typography>
                        Direccion:{" "}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "orange", textDecoration: "none" }}
                            href={`https://www.google.com/maps/search/?api=1&query=${dataExamen.direccion_calle}+${dataExamen.direccion_numero},+${dataExamen.direccion_localidad}`}
                        >
                            {" "}
                            {`${dataExamen?.direccion_calle} ${dataExamen.direccion_numero}, ${dataExamen?.direccion_localidad}`}
                        </a>{" "}
                    </Typography>
                    <Typography>Horario: a designar</Typography>
                    {index < 3 && (
                        <Button onClick={() => buttonClick(index)} style={{ background: "red", marginTop: 20 }}>
                            {mensajeButton(index)}
                        </Button>
                    )}
                    <Typography style={{ marginTop: 20 }}>{mensaje[index]}</Typography>

                    <div style={{ width: "100%", height: 100, display: "flex", marginTop: 20 }}>
                        <div style={{ width: "50%", height: "100%" }}>
                            <Button onClick={() => PDF_download()}>
                                <PictureAsPdfIcon sx={{ color: "red", fontSize: 40 }} />
                            </Button>
                            <Typography>Descarga la Teoria</Typography>
                        </div>
                        <div style={{ width: "50%", height: "100%" }}>
                            <Button onClick={() => Whats(telefonoInstructor(), "hola")}>
                                <WhatsAppIcon sx={{ color: "green", fontSize: 40 }} />{" "}
                            </Button>
                            <Typography>¿Tenes alguna pregunta?</Typography>
                        </div>
                    </div>
                </div>
            )}
        </Container>
    )
}

export default NewExamenLayout

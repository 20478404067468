import { MenuItem, Select, TextField, Typography, Button, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Containter from '../../../Components/Container/Container';
import LigaEncuentro from '../LigaEncuentro';
import AreaCentral from '../AreasCentral/AreasCentral';
import CreaCategoria from './CreaCategoria';
import LigaInfoGeneral from "./LigaInfoGeneral";
import Inscriptos from './Inscriptos';
import { Tab, Tabs } from '@material-ui/core';
import CreaLiga from '../CrearLiga/CreaLiga';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import RankingLiga from './RankingLiga';
import PDFPlanillaPrincipal from '../PDFplanillas/PDFplanillaPrincipal';
const Styles = makeStyles({
    tableContainer: {
        display: 'flex',
        justifyContent: 'center',
        minWidth: "90%",
        borderStyle: "solid",
        borderWidth: 2,
        borderRadius: 10,
        height: 100,
    },
    noDataContainer: {
        border: 'solid 1px white',
        padding: '0.2em 1em 0.2em 1em',
        borderRadius: '5px',
        minWidth: '90%',
    },
    filtros: {
        margin: 10
    },

    noDataText: {
        textAlign: 'center',
    },
})

const HomeLiga = (props) => {
    const location = useLocation();
    let liga = location.state ? location.state : 0;

    const navigate = useNavigate()
    const clases = Styles()
    const [alumnos, setAlumnos] = useState({
        disponibles: 0,
        ocupados: 0
    })
    const [nombreDisc, setNombreDisc] = useState("");
    const [value, setValue] = useState(0);
    const [ligaActive, setLigaActive] = useState(liga) //liga seleccionada
    const [fechas, setFechas] = useState([])
    const [loading, setLoading] = useState(true)
    const [competidores, setCompetidores] = useState([])
    const [llave, setllave] = useState([])
    const [infoLiga, setInfoLiga] = useState([])
    const [imprimir, setImprimir] = useState({
        imprimir: false,
        alumnos: [],
        disciplina: [],
        categoria: []
    })
    const [params, setParams] = useState({
        disciplina_id: "",
        sexo: 3,
        categoria_desde: 1,
        categoria_hasta: 14,
        edad_desde: 1,
        edad_hasta: 99,
        altura_desde: 10,
        altura_hasta: 250,
        peso_desde: 1,
        peso_hasta: 250,
        tipo_compe: 0,
        page: 0,
        liga_id: liga.id
    })
    const [del, setDel] = useState(false)

    useEffect(() => {
      // fetchShowCompetidores()
        fetchInfoLiga(ligaActive.id)
        fetchShowCategorias()
    }, [del,params])

    const [config, setConfig] = useState({
        matriculas: false,
        inscripcion: false,
        inicio: false,
    })

    const fetchIniciaDia = async (dia) => {

        try {
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/IniciarDia/${dia}`,
                {
                    method: "get",
                    headers: { 'Content-type': 'application/json' },

                })
            let response = await res.json()
            if (response.code == 200) {


                setInfoLiga({ ...infoLiga, fechas: response.data.fechas, diaActivo: response.data.diaActivo });
                setLigaActive({ ...ligaActive, estado: 1 })
                setLoading(false)

                notify(1, response.data.mensaje)

            } else {

                setLoading(false)

                notify(0, response.data.mensaje)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const fetchDetenerFecha = async (dia) => {

        try {
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/cancelFecha/${liga.id}&${dia}`,
                {
                    method: "get",
                    headers: { 'Content-type': 'application/json' },

                })
            let response = await res.json()
            if (response.code == 200) {

                setInfoLiga({ ...infoLiga, fechas: response.data.fechas, diaActivo: 0 });
                setLoading(false)
                notify(1, "LA FECHA HA SIDO DETENIDA, CORRECTAMENTE")

            } else {

                setLoading(false)
                console.log(" det")
                notify(0, "Hay Encuentros Finalizados, IMPOSIBLE DETENER LA FECHA")
            }
        } catch (error) {
            console.log(error)
        }
    }
    const fetchShowCompetidores = async () => {
        try {
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/showCompetidores/${params.page}`,
                {
                    method: "get",
                    headers: { 'Content-type': 'application/json' },
                    // body: JSON.stringify(params)
                })
            let response = await res.json()
            if (response.code == 200) {
                setCompetidores(response)
                console.log("COMPETIDOREES", response)
            } else if (response.code == 400) {
                console.log("error")
            }
        } catch (error) {
            console.log(error)
        }
    }
    const fetchShowLLave = async (id) => {
        try {

            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/showEncuentro/${id}`,
                {
                    method: "GET",
                    headers: { 'Content-type': 'application/json' },

                })
            let response = await res.json()
            if (response.code == 200) {
                setllave(response.data)
            } else if (response.code == 400) {
                setllave([])
            }
        } catch (error) {
            console.log(error)
        }
    }
    const fetchShowCategorias = async () => {
        try {
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/filterCategory`,
                {
                    method: "POST",
                    headers: { 'Content-type': 'application/json' },
                    body: JSON.stringify(params)
                })
            let response = await res.json()
            if (response.code == 200) {
                setCategorias(response.categorias)
                setNombreDisc(response.disciplina)
                setAlumnos({
                    ...alumnos, disponibles: response.alumnos_disponibles,
                    ocupados: response.alumnos_ocupados
                })
            } else if (response.code == 400) {
                setCategorias([])
                setNombreDisc(response.disciplina)
                setAlumnos({
                    ...alumnos, disponibles: response.alumnos_disponibles,
                    ocupados: response.alumnos_ocupados
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    const fetchInfoLiga = async (id) => {

        setLoading(true)
        try {
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/infoLiga/${id}`,
                {
                    method: "GET",
                    headers: { 'Content-type': 'application/json' },

                })
            let response = await res.json()

            if (response.code == 200) {
                setInfoLiga({
                    liga: response.data.liga,
                    fechas: response.data.fechas,
                    llaves: response.data.llaves,
                    competidores: response.data.competidores,
                    diaActivo: response.data.diaActivo
                })
                setLoading(false)

            } else if (response.code == 400) {
                setInfoLiga([])
                setLoading(false)
                console.log("NOOOOO ENTRO")
            }
        } catch (error) {
            console.log("ERORR", error)
            console.log("ERRORRO")
            //    setLoading(false)
        }
    }
    const fetchDeleteCategorias = async (id) => {
        try {
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/deleteCategory/${id}`, { method: "delete" })
            let response = await res.json()

            if (response.code == 200) {
                notify(1, response.mensaje)
                eliminar(id)
                setDel(!del)
            } else {
                notify(0, response.mensaje)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const fetchCancelDia = async (id) => {
        try {
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/liga/`, { method: "get" })
            let response = await res.json()

            if (response.code == 200) {
                console.log(response)

            }
        } catch (error) {
            console.log(error)
        }

    }
    const fetchAgregaCategorias = async (categoria) => {
        try {
            console.log({ categoria })
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/addCategory`,
                {
                    method: "POST",
                    headers: { 'Content-type': 'application/json' },
                    body: JSON.stringify(categoria)
                })

            let response = await res.json()

            if (response.code == 200) {

                setCategorias(response.categorias)
                setAlumnos({
                    ...alumnos, disponibles: response.alumnos_disponibles,
                    ocupados: response.alumnos_ocupados
                })
                setDel(!del)
                notify(1, "Categoria Agregada correctamente.")

            } else {

                setAlumnos({
                    ...alumnos, disponibles: response.alumnos_disponibles,
                    ocupados: response.alumnos_ocupados
                })

                notify(0, response.mensaje)
            }
        } catch (error) {
            console.log(error)
        }

    }
    const fetchShowFechas = async () => {
        try {
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/showFechas/${liga}`,
                {
                    method: "GET",
                    headers: { 'Content-type': 'application/json' },
                    // body: JSON.stringify(params)
                })
            let response = await res.json()
            if (response.code == 200) {
                setFechas(response.fechas)
            } else if (response.code == 400) {

            }
        } catch (error) {
            console.log(error)
        }
    }
    const fetchHandleCompe = async (e) => {
        try { //update
            let id = e.target.name
            let data = { tipo_compe: e.target.value };
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/updateCompetencia/${id}`, {
                method: "PUT",
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(data)
            })

            let response = await res.json()

            if (response.code == 200) {
                console.log(response)

            }
        } catch (error) {
            console.log(error)
        }

    }
    const fetchHandleCancelMatch = async (d) => {
        try { //update
            let id = d.id
            let data = d;
            console.log("dataCANCEL ", d)
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/cancelMatchElim/${id}`, {
                method: "PUT",
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(data)
            })

            let response = await res.json()

            if (response.code == 200) {
                fetchShowLLave(d.categoria_id)
                console.log("200", response)
            } else {
                console.log("no200", response)
            }


        } catch (error) {
            console.log(error)
        }

    }
    const fetchHandleWin = async (d) => {
        try { //update
            let id = d.id
            let data = d;
            console.log("dataWIN ", d)
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/winEliminacion/${id}`, {
                method: "PUT",
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(data)
            })

            let response = await res.json()

            if (response.code == 200) {
                fetchShowLLave(1)
                console.log("200", response)
            } else {
                console.log("no200", response)
            }


        } catch (error) {
            console.log(error)
        }

    }

    const fetchDeleteInscripto = async (dni) => {
        try {
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/deleteCompetidor/${dni}`,
                {
                    method: "get",
                    headers: { 'Content-type': 'application/json' },

                })

            let response = await res.json()

            if (response.code == 200) {

                fetchShowCompetidores();

                notify(1, response.mensaje)

            } else {


                notify(0, response.mensaje)
            }
        } catch (error) {
            console.log(error)
        }

    }

    // const fetchAddCompetidor = async (datos) => {
    //     try {
    //         let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/addCompetidorInv`,
    //             {
    //                 method: "POST",
    //                 headers: { 'Content-type': 'application/json' },
    //                 body: JSON.stringify(datos)
    //             })

    //         let response = await res.json()

    //         if (response.code == 200) {

    //             fetchShowCompetidores();

    //             notify(1, response.mensaje)

    //         } else {


    //             notify(0, response.mensaje)
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }

    // }
    const fetchPDF = async (cate_id) => {
        try { //update
            console.log("LIFA", liga)
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/pdfData/${cate_id}&${liga.id}`, {
                method: "GET",
                headers: { 'Content-type': 'application/json' },

            })

            let response = await res.json()

            if (response.code == 200) {

                console.log(" 200", response)

                setImprimir({
                    imprimir: true,
                    categoria: response.data.categoria,
                    alumnos: response.data.alumnosCate,
                    disciplina: response.data.disciplina
                })

            } else {
                console.log("no200", response)
            }


        } catch (error) {
            console.log(error)
        }

    }
    const notify = (x, nombre) => {
        if (x == 1) {
            toast.success(nombre + '.', { style: { background: 'lightgreen', color: "Black" } });
        } else {
            toast.error(nombre + '.', { style: { background: 'red', color: "white" } });
        }
    }
    const filter = (x) => {
        let nombre = x.target.name
        let valor = x.target.value
        switch (nombre) {
            case "disciplinas":
                setParams({ ...params, disciplina_id: valor })
                break;
            case "sexo":
                setParams({ ...params, sexo: valor })
                break;
            case "categoria_desde":
                setParams({ ...params, categoria_desde: valor })
                break;
            case "categoria_hasta":
                setParams({ ...params, categoria_hasta: valor })
                break;
            case "edad_desde":
                setParams({ ...params, edad_desde: valor })
                break;
            case "edad_hasta":
                setParams({ ...params, edad_hasta: valor })
                break;
            case "peso_desde":
                setParams({ ...params, peso_desde: valor })
                break;
            case "peso_hasta":
                setParams({ ...params, peso_hasta: valor })
                break;
            case "altura_desde":
                setParams({ ...params, altura_desde: valor })
                break;
            case "altura_hasta":
                setParams({ ...params, altura_hasta: valor })
                break;
            case "tipo":
                setParams({ ...params, tipo_compe: valor })
                break;
            default:
                break;
        }

    }
    const [categorias, setCategorias] = useState([
        {
            id: 1, disciplina: 1, sexo: 1, categoria_desde: 1,
            categoria_hasta: 4, edad_desde: 1, edad_hasta: 99, altura_desde: 50, altura_hasta: 150,
            peso_desde: 1, peso_hasta: 180,
        }
    ])
    const [catConstruct, setcatConstruct] = useState({
        id: 1,
        disciplina: "",
        sexo: "",
        categoria_desde: "",
        categoria_hasta: "",
        edad_desde: 0,
        edade_hasta: 99,
        altura_desde: 50,
        altura_hasta: 200,
        peso_desde: 1,
        peso_hasta: 180,

    })
    const agregarRenglon = (p) => {

        if (p.disciplina_id > 0 && p.tipo_compe > 0) {

            fetchAgregaCategorias(p)

        } else {
            notify(0, "Falta datos Importantes, INGRESE disciplinia y tipo de competencia")
        }

    }
    const eliminar = (id) => {
        let aux = []
        categorias.map((x) => {
            if (x.id !== id) {
                aux.push({ ...x })
            }
        })
        setCategorias(aux)

    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangePage = (d) => {
        console.log("change", d)
        setParams({ ...params, page: d });
    };
    const handleWin = (d) => {
        fetchHandleWin(d)
    }


    return (
        <Containter title={"LIGA "} more styles={{ width: "100%" }} >
            <Toaster position="top-center" />
            {imprimir.imprimir == true && <PDFPlanillaPrincipal categoria={imprimir.categoria} disciplina={imprimir.disciplina} alumnos={imprimir.alumnos} listo={() => setImprimir({ ...imprimir, imprimir: false })} />}
            {!loading ?
                <>

                    <div style={{ minHeight: 1000, width: "100%", display: "flex", flexDirection: "row", marginTop: 20 }}>
                        <Tabs value={value} onChange={handleChange} orientation='vertical' style={{ height: "100%", width: "10%", marginTop: 20, marginRight: 30 }}>
                            <Tab label="General " />
                            <Tab label="INSCRIPTOS" />
                            {infoLiga.diaActivo > 0 && <Tab label="CATEGORIAS " />}
                            {infoLiga.diaActivo > 0 && <Tab label="Area Central " />}
                            {infoLiga.diaActivo > 0 && <Tab label="DIAS " />}
                            {infoLiga.diaActivo > 0 && <Tab label="PUNTOS" />}

                        </Tabs>

                        {value == 0 && liga.id &&
                            <div style={{ width: "100%" }}>
                                <LigaInfoGeneral
                                    info={infoLiga}
                                    handleIniciaDia={(i) => fetchIniciaDia(i)}
                                    handleCancelaDia={(i) => fetchDetenerFecha(i)}
                                />

                            </div>
                        }

                        {value == 1 && infoLiga &&
                            <div style={{ width: "100%" }}>
                                <Inscriptos
                                    infoLiga={infoLiga}
                                    datos={competidores}
                                    changePage={(d) => handleChangePage(d)}
                                    changeFilter={(x)=>filter(x)}
                                    fechas={infoLiga.fechas}
                                    refresh={() => fetchShowCompetidores()}
                                    handleIniciaDia={(i) => fetchIniciaDia(i)}
                                  //  addCompetidor={(x)=>fetchAddCompetidor(x)}
                                    params={params}
                                    deleteInscripto={(x) => fetchDeleteInscripto(x)}

                                //  }
                                />
                            </div>
                        }

                        {value == 2 &&
                            <div style={{ width: "100%" }} >
                                <CreaCategoria
                                    categorias={categorias}
                                    estado={ligaActive.estado}
                                    pdf={(cate_id) => fetchPDF(cate_id)}
                                  //  agregar={(p) => agregarRenglon(p)}
                                    eliminarRenglon={(id) => fetchDeleteCategorias(id)}
                                    liga={liga.id}
                                    handleIniciaDia={(i) => fetchIniciaDia(i)}
                                    dia={infoLiga.diaActivo}
                                    alumnos={alumnos}
                                    handleCompe={(e) => fetchHandleCompe(e)}
                                />
                            </div>
                        }


                        {value == 3 &&
                            <div style={{ width: "100%" }}>

                                <AreaCentral liga={ligaActive} dia={infoLiga.diaActivo} notificacion={(x, m) => notify(x, m)} />

                            </div>
                        }

                        {value == 5 &&
                            <div style={{ width: "100%" }}>

                                <RankingLiga 
                                
                                />

                            </div>
                        }

                    </div>
                </> :
                <CircularProgress />
            }

        </Containter>


    )
}

export default HomeLiga
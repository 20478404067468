import { Button, CircularProgress, Input, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react'
import Containter from '../../../Components/Container/Container';
 
import { useEffect } from 'react';
 
import toast, { Toaster } from 'react-hot-toast';
 
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie"



const LoginAcreditacion = () => {
    const cookie = new Cookies()
    const navigate = useNavigate()
    const [password, setPassword] = useState("")
    const [area, setArea] = useState(0)
    

    const fetchLogin = async (p) => {
        try {
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/Acreditacion/loginAcreditacion`, {
                method: "post",
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(p)
            })

            let response = await res.json()
            if (response.code == 200) {

                loginCorrect(response.puesto)
                notify(1, response.mensaje)
            } else {
                console.log("no200", response)
                notify(0, response.mensaje)
            }
 

        } catch (error) {
            console.log(error)
        }

    }
    const Login = () => {
        if (password.length > 0) {
            fetchLogin({ password: password })
        } else {
            notify(0, "Ingrese Password")
        }
    }
    const loginCorrect = (p) => {
        cookie.set("userAcreditacion", 10)
        navigate(`/acreditacion`, { state: { puesto:p } })
    }


    const notify = (x, nombre) => {
        if (x == 1) {
            toast.success(nombre + '.', { style: { background: 'lightgreen', color: "Black" } });
        } else {
            toast.error(nombre + '.', { style: { background: 'red', color: "white" } });
        }
    }

    return (
<div style={{width:"100%",height:"100%", justifyContent:"center",display:"flex"}}>
        <Containter title={"ACREDITACIONES"} styles={{ width: "50vh", height:"30vh"  }} more>
           
            <Toaster position="top-center" />
            <Typography variant='h5' >INGRESE CODIGO </Typography>
            <Input onChange={(x) => setPassword(x.target.value)} style={{ background: "lightgrey",borderRadius:5,color:"black",fontSize:20 }} type='password'></Input>
            <Button onClick={() => Login()} style={{ background: "red" }} >INGRESAR</Button>
     
        </Containter>
        </div>
    )
}
export default LoginAcreditacion
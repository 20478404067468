import { IconButton, Tooltip } from "@material-ui/core"
import { Cancel } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import EditIcon from "@mui/icons-material/Edit"
import { DataGrid } from "@mui/x-data-grid"
import React from "react"

const Styles = makeStyles({
    tableContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    noDataContainer: {
        border: "solid 1px white",
        padding: "0.2em 1em 0.2em 1em",
        borderRadius: "5px",
        minWidth: "90%",
    },
    noDataText: {
        textAlign: "center",
    },
    tableButtonConainer: {
        display: "flex",
        marginLeft: "0.8em",
        borderRadius: "50%",
        alignContent: "center",
    },
})

const InscriptosTable = (props) => {
    const clases = Styles()

    const { data, pageData, changePage, loading, removeRecord = null } = props

    const columns = [
        { field: "dni", headerName: "Dni", width: 140 },
        { field: "nombre_completo", headerName: "Nombre", minWidth: 250 },
        {
            field: "edad",
            headerName: "Edad",
            minWidth: 110,
            renderCell: (row) => <div>{row.row.edad || "No Indicado"}</div>,
        },
        {
            field: "telefono",
            headerName: "Telefono",
            minWidth: 120,
            renderCell: (row) => <div>{row.row.telefono || "No Indicado"}</div>,
        },
        { field: "categoria", headerName: "Categoria", minWidth: 190 },
        { field: "instructor", headerName: "Instructor", minWidth: 150 },
        { field: "escuela", headerName: "Escuela", minWidth: 130 },
        // {
        //     field:'more',
        //     headerName: 'Mas',
        //     width:90,
        //     renderCell: row => <IconButton onClick={() => editRecord(row.row.id)}><Tooltip title="Ver/Editar"><EditIcon fontSize='small'/></Tooltip></IconButton>,
        // },
        {
            field: "actions",
            headerName: "Remover",
            minWidth: 120,
            renderCell: (row) => {
                if (removeRecord) {
                    return (
                        <div className={clases.tableButtonConainer}>
                            <IconButton onClick={() => removeRecord(row.row.id)}>
                                <Tooltip title="Remover">
                                    <Cancel />
                                </Tooltip>
                            </IconButton>
                        </div>
                    )
                }
            },
        },
    ]

    return (
        <div className={clases.tableContainer}>
            {data.length ? (
                <DataGrid
                    pagination
                    autoHeight
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={data}
                    pageSize={10}
                    loading={loading}
                    columns={columns}
                    paginationMode="server"
                    isRowSelectable={false}
                    onPageChange={changePage}
                    rowsPerPageOptions={[10]}
                    rowCount={pageData.records}
                    page={pageData.currentPage}
                />
            ) : (
                <div className={clases.noDataContainer}>
                    <h2 className={clases.noDataText}>No se encontraron alumnos</h2>
                </div>
            )}
        </div>
    )
}

export default InscriptosTable

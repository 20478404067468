import React, { useEffect, useState } from "react"
import Container from "../../Components/Container/Container"
import { fetchResource, usePermitions } from "../../Functions/Functions"
import { useNavigate } from "react-router-dom"
import AlumnosTable from "./AlumnosTable"
import { useSelector } from "react-redux"
import { AlumnosDataFilters } from "./AlumnosDataFilters"
import { AlumnosTopInformation } from "./AlumnosTopInformation"
import { Button, makeStyles, MenuItem, Modal, Select, TextField, Typography } from "@material-ui/core"
import AlumnoDatosPersonales from "./AlumnoDatosPersonales"
import toast, { Toaster } from "react-hot-toast"
import { useLocation } from "react-router-dom"

const Alumnos = () => {
  const state = useSelector((state) => ({ ...state["basicDataReducer"] }))
  const { basicData } = state

  const { role_id, user_id } = usePermitions()

  const location = useLocation()

  var activo = true
  if (location.state) {
    activo = location.state.actives
  } else {
    activo = true
  }

  const paramsInitialValues = {
    page: 0,
    nombre: "",
    apellido: "",
    escuela: "",
    asociacion: "",
    categoria: "",
    instructor: "",
    edadDesde: "",
    edadHasta: "",
    actives: activo,
  }
  const [openModalSecundario, setOpenModalSecundario] = useState(false)
  const [loading, setLoading] = useState(true)
  const [openDatosPersonales, setOpenDatosPersonales] = useState(false)
  const [alumnos, setAlumnos] = useState([])
  const [alumnoSel, setAlumnoSel] = useState()
  const [params, setParams] = useState(paramsInitialValues)
  const [records, setRecords] = useState(0)
  const [clases, setClases] = useState("")
  const [cambios, setCambios] = useState(false)

  useEffect(() => {
    const fetchClases = async () => {
      const [data, error] = await fetchResource(`/menu/clases`, "get", true, null)

      if (data) {
        setLoading(false)
        setClases(data.data)
      } else {
        setLoading(false)
        console.log(error)
      }
    }
    fetchClases()
  }, [])

  useEffect(() => {
    const fetchAlumnos = async () => {
      const [data, error] = await fetchResource(
        `/instructor/alumnos/show?page=${params.page + 1}&actives=${params.actives ? 1 : 0}&nombre=${params.nombre}&apellido=${params.apellido}&escuela=${params.escuela}&asociacion=${params.asociacion}&categoria=${params.categoria}&instructor=${params.instructor}&edadDesde=${params.edadDesde}&edadHasta=${params.edadHasta}`,
        "get",
        true,
        null
      )

      if (data) {
        setLoading(false)
        setRecords(data.data.total_records)
        setAlumnos(
          data.data.alumnos.map((alumno) => {
            return { ...alumno, nombre_completo: `${alumno.nombre} ${alumno.apellido}` }
          })
        )
      } else {
        setAlumnos([])
        setRecords(0)
        setLoading(false)
        console.error(error)
      }
    }
    fetchAlumnos()
  }, [params, cambios])

  const changeClase = async (user_id, clase) => {
    let body = [
      {
        id: user_id,
        clase_id: clase,
      },
    ]

    if (clase == 0) {
      const [data, error] = await fetchResource(`/instructor/alumnos/dettachFromClass`, "post", true, body)
      if (data) {
        if (data.code == 200) {
          setLoading(false)
          setOpenModalSecundario(false)
          setOpenDatosPersonales(false)

          setCambios(!cambios)
          notify(1, "Alumno Quitado de la Clase Exitosamente")
        } else {
          notify(0, "No se pudo realizar la Accion")
        }
      } else {
        setLoading(false)
        console.log(error)
      }
    } else {
      const [data, error] = await fetchResource(`/instructor/alumnos/attachToClass`, "post", true, body)

      if (data) {
        if (data.code == 200) {
          setLoading(false)
          setOpenModalSecundario(false)
          setOpenDatosPersonales(false)

          setCambios(!cambios)
          notify(1, "Cambio Realizado Correctamente!!")
        } else {
          notify(0, "No se pudo realizar la Accion")
        }
      } else {
        setLoading(false)
        console.log(error)
      }
    }
  }

  const changeCategory = async (id, cat, dni, pass) => {
    let body = {
      dni: dni,
      password: pass,
      categoria_id: cat,
    }
    const [data, error] = await fetchResource(`/instructor/alumnos/updateGrade/${id}`, "put", true, body)

    if (data) {
      if (data.code == 200) {
        setLoading(false)
        setOpenModalSecundario(false)
        setOpenDatosPersonales(false)
        setCambios(!cambios)
        notify(1, "Cambio Realizado Correctamente!!")
      } else {
        notify(0, "Identidad No Validada, Imposible Realizar a Acción")
      }
    } else {
      setLoading(false)
      console.log(error)
    }
  }

  const handlePageChange = (page) => {
    setParams({ ...params, page: page })
  }

  const handleRemove = async (id) => {
    let parametro = { id: id }
    const [data, error] = await fetchResource("/instructor/alumnos/dettach", "post", true, parametro)

    if (data) {
      let alumnosFiltrado = alumnos.filter((data) => data.id !== id)
      setAlumnos(alumnosFiltrado)
      setOpenDatosPersonales(false)
      notify(0, "Alumno Desvinculado del Instructor")
    }
    if (error) console.error(error)
  }

  const editUser = (userId) => {
    let alumnoSel = alumnos.filter((x) => x.id == userId)
    setAlumnoSel(alumnoSel[0])
    console.log(alumnoSel[0].id)
    setOpenDatosPersonales(true)

    //  history.push(`/editCategory/${userId}`)
  }

  // const tipoUser = (userId) => {

  //     let datos = alumnos.filter((x) => x.id == userId)
  //     console.log("FFF ", datos[0])
  //     setAlumnSel(datos[0])
  //     setModalTipoON(true)

  // }

  // const deattachFromClass = async () => {
  //     try {

  //         const datos = [{ "id": alumnSel.id, "clase_id": alumnSel.clase_id }]

  //         const [data, error] = await fetchResource(`/instructor/alumnos/dettachFromClass`, 'POST', true, datos);

  //         if (data) {
  //             setLoading(false)
  //             notify(1,"Estado Alumno cambiado a INACTIVO ")
  //         } else {
  //             notify(0,"Estado Alumno No pudo ser cambiado.")
  //             console.error(error)
  //         }
  //     } catch (error) {
  //         notify(0,"No pudo ser cambiado.Error Servidor")
  //         console.log(error)
  //     }
  //     setModalTipoON(false)
  // }
  const notify = (x, nombre) => {
    if (x == 1) {
      toast.success(nombre + ".", { style: { background: "lightgreen", color: "Black" } })
    } else {
      toast.error(nombre + ".", { style: { background: "red", color: "white" } })
    }
  }

  const clearFilters = () => {
    setParams(paramsInitialValues)
  }

  const handleFilter = (e) => {
    const field = e.target.name
    const value = e.target.value
    switch (field) {
      case "nombre":
        setParams({ ...params, nombre: value })
        break
      case "apellido":
        setParams({ ...params, apellido: value })
        break
      case "categoria":
        setParams({ ...params, categoria: value })
        break
      case "escuela":
        setParams({ ...params, escuela: value })
        break
      case "instructor":
        setParams({ ...params, instructor: value })
        break
      case "asociacion":
        setParams({ ...params, asociacion: value })
        break
      case "edadDesde":
        setParams({ ...params, edadDesde: value })
        break
      case "edadHasta":
        setParams({ ...params, edadHasta: value })
        break
      case "actives":
        setParams({ ...params, actives: !params.actives })
        break
      default:
        break
    }
  }

  // let notify = (x, nombre) => {
  //     let mensaje = ""

  //     if (x == 1) {
  //         if (nombre) { mensaje = nombre } else { mensaje = "Registro Correcto." }
  //         toast.success(mensaje, { style: { background: 'lightgreen', color: "Black" } });
  //     } else {

  //         if (nombre) { mensaje = nombre } else { mensaje = "Registro Eliminado" }
  //         toast.error(mensaje, { style: { background: 'red', color: "white" } });
  //     }
  // }

  return (
    <div className="alumnos-container">
      <Toaster position="top-center" />
      <Container title="Listado de alumnos" styles={{ display: "flex" }} more>
        <AlumnosTopInformation activesSwitch records={records} params={params} handleFilter={handleFilter} />
        <AlumnosDataFilters
          basicData={basicData}
          handleFilter={handleFilter}
          clearFilters={clearFilters}
          instructor
          params={params}
          userRole={role_id}
        />
        <AlumnosTable
          data={alumnos}
          changePage={handlePageChange}
          pageData={{ currentPage: params.page, records: records }}
          loading={loading}
          editRecord={editUser}
        />
      </Container>
      <Modal open={openDatosPersonales}>
        <AlumnoDatosPersonales
          role={role_id}
          cerrar={() => setOpenDatosPersonales(false)}
          usuario={user_id}
          modalSecundario={openModalSecundario}
          openModalSecundario={() => setOpenModalSecundario(true)}
          cerrarModalSecundario={() => setOpenModalSecundario(false)}
          removeAlumno={handleRemove}
          modalSelectCategoria={() => ""}
          modalSelectClase={() => ""}
          selectCategoria={(id, cat, dni, pass) => changeCategory(id, cat, dni, pass)}
          selectClase={(i, c, tipo) => changeClase(i, c, tipo)}
          datos={alumnoSel}
          datosClases={clases}
          datosCategorias={basicData.categorias}
        />
      </Modal>
    </div>
  )
}

export default Alumnos

import React, { useEffect, useState } from "react"
import Container from "../Components/Container/Container"
import { fetchResource } from "../Functions/Functions"
import CircularProgress from "@material-ui/core/CircularProgress"
import UsersTable from "../Components/Table/UsersTable"
import DefaultMessage from "../Components/DefaultMessage/DefaultMessage"
import TextField from "@material-ui/core/TextField"

const Directores = () => {
    const [loading, setLoading] = useState(true)
    const [directores, setDirectores] = useState(null)
    const [filtro, setFiltro] = useState(null)

    useEffect(() => {
        const fetchAlumnos = async () => {
            const [data, error] = await fetchResource("/presidente/directores/showDirectores", "get", true, null)

            if (data) {
                setLoading(false)
                setDirectores(data.directores)
            } else {
                setLoading(false)
                console.log(error)
            }
        }

        fetchAlumnos()
    }, [])

    const handleChange = (e) => {
        setFiltro(e.target.value)
    }

    return (
        <div className="directores-container">
            <Container title="Directores" more={true}>
                <TextField
                    type="text"
                    name="nombre"
                    label="Nombre o Apellido"
                    value={filtro}
                    onChange={handleChange}
                    size="small"
                    variant="outlined"
                />
                {loading ? (
                    <CircularProgress color="secondary" />
                ) : (
                    <>
                        {
                            // directores ? <UsersTable filtro={filtro} data={directores}/> : <DefaultMessage title={'No hay directores registrados'}/>
                        }
                    </>
                )}
            </Container>
        </div>
    )
}

export default Directores

import React, { useEffect, useState } from "react"
import Container from "./Container/Container"
import { Box, Typography } from "@material-ui/core"
import { fetchResource, usePermitions } from "../Functions/Functions"
import moment from "moment"
import CancelRoundedIcon from "@mui/icons-material/CancelRounded"
import RecommendRoundedIcon from "@mui/icons-material/RecommendRounded"
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded"
import Check from "../Assets/Iconos/green-check.png"
import Cross from "../Assets/Iconos/red-cross.png"
import { useSelector } from "react-redux"

const AsistenciaLayout = () => {
    const state = useSelector((state) => ({ ...state["userReducer"] }))
    const { user } = state
    const [asistencia, setAsistencia] = useState(null)

    useEffect(() => {
        const fetchAsistenciaData = async () => {
            const [data, error] = await fetchResource("/users/asistencia/show", "get", true, null)
            if (error) {
                console.log(error)
            } else {
                ultimaAsist(data.asistencias)
            }
        }

        fetchAsistenciaData()
    }, [])

    const diasSinPractica = (fecha) => {
        //   let hoy = moment().format("YYYY-MM-DD")
        let diaDif = 0
        let ultimo = moment(fecha).format("YYYY-MM-DD")
        diaDif = moment().diff(ultimo, "day")
        return diaDif
    }

    const ultimaAsist = (asist) => {
        console.log("ASIST", asist)
        let ultima = []
        let datos = []

        datos = asist.sort(function (a, b) {
            if (a.fecha > b.fecha) {
                return 1
            }
            if (a.fecha < b.fecha) {
                return -1
            }
            if (a.fecha == b.fecha) {
                return 0
            }
        })
        ultima = datos[datos.length - 1]
        let u = moment(ultima.fecha).format("YYYY-MM-DD")
        setAsistencia({
            dia: nombreDia(ultima.fecha),
            fecha: moment(ultima.fecha).format("DD-MM-YYYY"),
            dif: diasSinPractica(u),
        })
    }

    const nombreDia = (fecha) => {
        let n = moment(fecha).day()

        switch (n) {
            case 1:
                return "Lunes"
                break
            case 2:
                return "Martes"
                break
            case 3:
                return "Miercoles"
                break
            case 4:
                return "Jueves"
                break
            case 5:
                return "Viernes"
                break
            case 6:
                return "Sabado"
                break
            case 0:
                return "Domingo"
                break
            default:
                return "No Definido"
                break
        }
    }

    return (
        <Container title={"Asistencia"} moreUrl={"/usuarios/asistencias"}>
            <Typography style={{ margin: 10 }}>
                {" "}
                {!user.clase?.nombre ? "Sin Clase Asignada" : user.clase.nombre}{" "}
            </Typography>

            {asistencia ? (
                <>
                    <Typography style={{ fontSize: 30 }}>{asistencia.dia + "  " + asistencia.fecha}</Typography>
                    <Typography style={{ fontSize: 15 }}>{"fue tu ultimo Entrenamiento."} </Typography>

                    {asistencia.dif <= 4 && (
                        <>
                            <Typography style={{ color: "green" }}>
                                <RecommendRoundedIcon style={{ fontSize: 60 }} />{" "}
                            </Typography>
                            <Typography style={{ fontSize: 20 }}>
                                {"Estas Entrenando con Buen Ritmo, Felicitaciones!!"}{" "}
                            </Typography>
                        </>
                    )}

                    {asistencia.dif > 4 && asistencia.dif <= 15 && (
                        <>
                            <Typography style={{ color: "orange" }}>
                                <ErrorRoundedIcon style={{ fontSize: 60 }} />{" "}
                            </Typography>
                            <Typography style={{ fontSize: 20, textAlign: "center" }}>
                                {"Hace " +
                                    asistencia.dif +
                                    " dias que No ENTRENAS...VAMOS!! No es Momento de Aflojar. "}{" "}
                            </Typography>
                        </>
                    )}

                    {asistencia.dif > 15 && (
                        <>
                            <Box
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: 10,
                                    marginBottom: 10,
                                }}
                            >
                                <img style={{ maxWidth: "50px" }} src={Cross} />
                            </Box>
                            <Typography style={{ fontSize: 20, textAlign: "center" }}>
                                {"Hace " +
                                    asistencia.dif +
                                    " dias que No ENTRENAS, No esperes mas, ya paso mucho tiempo de descanso. "}{" "}
                            </Typography>
                        </>
                    )}
                </>
            ) : (
                <>
                    <Box
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 10,
                            marginBottom: 10,
                        }}
                    >
                        <img style={{ maxWidth: "50px" }} src={Cross} />
                    </Box>
                    <Typography>Actualmente no hay asistencias registradas</Typography>
                </>
            )}
        </Container>
    )
}

export default AsistenciaLayout

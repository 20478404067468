import { IconButton, Tooltip } from "@material-ui/core"
import { ArrowDownward } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import EditIcon from "@mui/icons-material/Edit"
import { DataGrid } from "@mui/x-data-grid"
import React from "react"
import CheckIcon from "@mui/icons-material/Check"
import CancelIcon from "@mui/icons-material/Clear"
import { style } from "@mui/system"

const Styles = makeStyles({
    tableContainer: {
        display: "flex",
        justifyContent: "center",
        width: 1100,
    },
    noDataContainer: {
        border: "solid 1px white",
        padding: "0.2em 1em 0.2em 1em",
        borderRadius: "5px",
        minWidth: "90%",
    },
    noDataText: {
        textAlign: "center",
    },
})

const MatriTable = (props) => {
    const clases = Styles()

    const { data, pageData, changePage, loading, add, remove } = props

    const colores = (c) => {
        if (c) {
            return {
                background:
                    "linear-gradient(90deg, rgba(9,117,34,0.7791491596638656) 0%, rgba(58,181,8,0.0032387955182072714) 84%)",
                marginLeft: -10,
                paddingLeft: 10,
                width: "100%",
                marginRight: 10,
            }
        } else {
            return { marginLeft: -10, paddingLeft: 10, width: "100%", marginRight: 10 }
        }
    }
    const colores2 = (c) => {
        if (c) {
            return {
                background:
                    "linear-gradient(90deg, rgba(58,181,8,0.0032387955182072714) 14%, rgba(9,117,34,0.7791491596638656) 100%)",
                width: "110%",
                marginRight: -10,
            }
        } else {
            return {}
        }
    }

    const columns = [
        {
            field: "actions",
            headerName: "Matricula",
            minWidth: 120,
            renderCell: (row) =>
                row.row.current == true ? (
                    <IconButton
                        style={{ color: "white", width: "100%", alignItems: "center", display: "flex" }}
                        onClick={() => remove(row.row.id)}
                    >
                        <Tooltip title="Eliminar">
                            <CheckIcon style={{ fontSize: 30, borderRadius: 35, backgroundColor: "green" }} />
                        </Tooltip>
                    </IconButton>
                ) : (
                    <IconButton
                        style={{ color: "white", width: "100%", alignItems: "center", display: "flex" }}
                        onClick={() => add(row.row.id)}
                    >
                        <Tooltip title="Registrar">
                            <CancelIcon style={{ fontSize: 20 }} />
                        </Tooltip>
                    </IconButton>
                ),
        },
        // colores(row.row.current)

        { field: "nombre", headerName: "Nombre", minWidth: 350, renderCell: (row) => <div>{row.row.nombre}</div> },
        {
            field: "edad",
            headerName: "Edad",
            minWidth: 150,
            renderCell: (row) => <div>{row.row.edad || "No Indicado"}</div>,
        },
        { field: "categoria", headerName: "Categoria", minWidth: 190 },
        { field: "instructor", headerName: "Instructor", minWidth: 200 },
        { field: "escuela", headerName: "Escuela", minWidth: 130 },
    ]

    return (
        <div className={clases.tableContainer}>
            {data.length ? (
                <DataGrid
                    pagination
                    autoHeight
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={data}
                    pageSize={10}
                    loading={loading}
                    columns={columns}
                    paginationMode="server"
                    isRowSelectable={false}
                    onPageChange={changePage}
                    rowsPerPageOptions={[10]}
                    rowCount={pageData.records}
                    page={pageData.currentPage}
                    cell
                />
            ) : (
                <div className={clases.noDataContainer}>
                    <h2 className={clases.noDataText}>No se encontraron alumnos</h2>
                </div>
            )}
        </div>
    )
}

export default MatriTable

import React, {useEffect, useState} from 'react';
import DisplayComponent from './DisplayComponent';
import BtnComponent from './BtnComponent';
import bell from "./Sounds/bel-s.mp3"
import { makeStyles } from '@material-ui/core';

     const Styles= makeStyles({
       
     })


function Timer({duracion, campana=new Audio(bell)}) {
   
  
  const [time, setTime] = useState({ms:0, s:0, m:0, h:0});
  const [interv, setInterv] = useState();
  const [status, setStatus] = useState(0);
   const clases=Styles()
  // Not started = 0
  // started = 1
  // stopped = 2
  let reloj=""
   
    useEffect(()=>{
   
      campana=new Audio(bell)
  
     },[time])


  const start = () => {
    run();
    setStatus(1);
      reloj=setInterval(run, 10)
    setInterv(reloj);
  };

  var updatedMs = time.ms, updatedS = time.s, updatedM = time.m, updatedH = time.h;

  const run = () => {
   if(updatedM === 60){
        updatedH++;
      updatedM = 0;
    }
    if(updatedS === 60){
      updatedM++;
      updatedS = 0;
    }
    if(updatedMs === 100){
      updatedS++;
      updatedMs = 0;
    }
    
   updatedMs++;
    if(duracion.s ===updatedS && duracion.m == updatedM){
       
          campana?.play()
          clearInterval(reloj)
       
        updatedMs=0
    }
 
    return setTime({ms:updatedMs, s:updatedS, m:updatedM, h:updatedH});
  };

  const stop = () => {
    clearInterval(interv);
    setStatus(2);
   
  };

  const reset = () => {
    clearInterval(interv);
    setStatus(0);
    setTime({ms:0, s:0, m:0, h:0})
  };

  const resume = () => start();


  return (
    <div  >
     
               <DisplayComponent time={time}/>

               <BtnComponent status={status} resume={resume} reset={reset} stop={stop} start={start}/>
 
    </div>
  );
}

export default Timer;
import React from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import RequestQuoteIcon from "@mui/icons-material/RequestQuote"
import DeleteIcon from "@material-ui/icons/Delete"
import { useNavigate } from "react-router-dom"
import ChecklistIcon from "@mui/icons-material/Checklist"

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
})

const ClaseTable = ({ centros, data, handleDelete }) => {
    const classes = useStyles()
    const navigate = useNavigate()
    return (
        <TableContainer component={Paper} style={{ marginTop: "30px" }}>
            <Table className={classes.table} aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell align="center">Asistencia</TableCell>
                        <TableCell align="center">Cuota</TableCell>
                        <TableCell align="center">Nombre</TableCell>
                        <TableCell align="center">Descripcion</TableCell>
                        <TableCell align="center">Nivel</TableCell>
                        <TableCell align="center">Centro</TableCell>
                        <TableCell align="center">Hora Inicio</TableCell>
                        <TableCell align="center">Hora Fin</TableCell>
                        <TableCell align="center">Borrar</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((fila) => (
                        <TableRow key={fila.id}>
                            <TableCell component="th" scope="row">
                                {fila.id}
                            </TableCell>
                            <TableCell align="center">
                                <IconButton onClick={() => navigate("/instructor/asistencia", { state: fila })}>
                                    <ChecklistIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell align="center">
                                <IconButton onClick={() => navigate("/instructor/cuotasRegistro", { state: fila })}>
                                    <RequestQuoteIcon />
                                </IconButton>
                            </TableCell>

                            <TableCell align="center">{fila.nombre}</TableCell>
                            <TableCell align="center">{fila.descripcion}</TableCell>
                            <TableCell align="center">{fila.nivel}</TableCell>
                            <TableCell align="center">
                                {centros.map((centro) => {
                                    if (centro.id === fila.centro_id) {
                                        return centro.nombre
                                    }
                                })}
                            </TableCell>
                            <TableCell align="center">{`${fila.hora_inicio.toString().slice(0, 2)}:${fila.hora_inicio.toString().slice(2, 4)} hs`}</TableCell>
                            <TableCell align="center">{`${fila.hora_fin.toString().slice(0, 2)}:${fila.hora_fin.toString().slice(2, 4)} hs`}</TableCell>
                            <TableCell align="center">
                                <IconButton onClick={() => handleDelete({ id: fila.id, nombre: fila.nombre })}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ClaseTable

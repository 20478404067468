import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Typography } from "@material-ui/core"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"

const Styles = makeStyles({
    container: {
        margin: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    text: {
        textAlign: "center",
        margin: 10,
    },
})

const DefaultMessage = ({ title, text, logo }) => {
    let style = Styles()

    return (
        <div className={style.container}>
            {logo && <ErrorOutlineIcon />}
            <Typography className={style.text} variant="h6">
                {title}
            </Typography>
            {text && (
                <Typography className={style.text} variant="subtitle1">
                    {text}
                </Typography>
            )}
        </div>
    )
}

export default DefaultMessage

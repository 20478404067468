import React, { useEffect, useState } from "react"
import Container from "../../../Components/Container/Container"
import { fetchResource, usePermitions } from "../../../Functions/Functions"
import CircularProgress from "@material-ui/core/CircularProgress"

import { Typography, makeStyles } from "@material-ui/core"
import toast, { Toaster } from "react-hot-toast"
import moment from "moment"

import CuotaUserControlTopInfo from "./CuotaUserControlTopInfo"
import CuotaUserControlTable from "./CuotaUserControlTable"

const Styles = makeStyles({
    filtersContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        minWidth: "100%",
        flexWrap: "wrap",
    },
    filterInput: {
        alignSelf: "flex-end",
    },
    recordCounter: {
        alignSelf: "end",
    },
    ageInputs: {
        maxWidth: "5em",
    },
    ctaButton: {
        backgroundColor: "red",
    },
})

const CuotaUserControl = () => {
    const { role_id, user_id, clase_id } = usePermitions()

    const [loading, setLoading] = useState(true)

    const [claseId, setClaseId] = useState("")
    const year = moment().format("YYYY")
    const month = moment().format("MM")
    const [cuotas, setCuotas] = useState([])

    const [clasesConfig, setClasesConfig] = useState({ inicio: "2024-02-01", fin: "2024-12-10" })

    const meses = [
        { mes: "Enero", value: "01" },
        { mes: "Febrero", value: "02" },
        { mes: "Marzo", value: "03" },
        { mes: "Abril", value: "04" },
        { mes: "Mayo", value: "05" },
        { mes: "Junio", value: "06" },
        { mes: "Julio", value: "07" },
        { mes: "Agosto", value: "08" },
        { mes: "Septiembre", value: "09" },
        { mes: "Octubre", value: "10" },
        { mes: "Noviembre", value: "11" },
        { mes: "Diciembre", value: "12" },
    ]
    useEffect(() => {
        fetchUserCuota()
    }, [])

    const fetchUserCuota = async () => {
        setLoading(true)
        const [data, error] = await fetchResource("/users/cuotas/cuotasUser", "get", true, null)

        if (data && data.code == 200) {
            let r = cuotasHistorico(data.cuotas)
            setCuotas(r)

            setLoading(false)
        } else {
            setLoading(false)
            console.log(error)
        }
    }

    const VerificaDia = (dat, dia) => {
        let datos = dat[0]
        let verifica = false

        if (dia == 1 && datos.lunes == 1) {
            verifica = true
        }
        if (dia == 2 && datos.martes == 1) {
            verifica = true
        }
        if (dia == 3 && datos.miercoles == 1) {
            verifica = true
        }
        if (dia == 4 && datos.jueves == 1) {
            verifica = true
        }
        if (dia == 5 && datos.sabado == 1) {
            verifica = true
        }
        if (dia == 6 && datos.sabado == 1) {
            verifica = true
        }
        if (dia == 0 && datos.domingo == 1) {
            verifica = true
        }

        return verifica
    }

    const mesNombre = (mes) => {
        let r = []
        meses.map((x) => {
            if (x.value == mes) {
                r = { ...x }
            }
        })

        return r
    }

    const cuotaMesActivo = () => {
        let datos = cuotas
        console.log("DATOS CUOTA", datos)
        console.log("mont", month)
        let respuesta = {
            year: year,
            month: mesNombre(month).mes,
            user_id: user_id,
            clase_id: clase_id,
            fecha: "Sin Pago Registrado.",
            cuota: 0,
        }

        datos?.map((x) => {
            if (x.id == month && x.estado == 1) {
                respuesta = { ...x, cuota: 1, month: mesNombre(month).mes }
            } else {
            }
        })
        return respuesta
    }

    const cuotasHistorico = (c) => {
        let datos = []
        datos = c?.sort(function (a, b) {
            if (a.month > b.month) {
                return 1
            }
            if (a.month < b.month) {
                return -1
            }
            if ((a.month = b.month)) {
                return 0
            }
        })

        let cont = 1
        let d = [...datos]
        let registro = []
        meses.map((m) => {
            let as = d.filter((x) => m.value == x.month && x.year == year)
            if (as.length > 0) {
                registro.push({ id: cont, cuota: cont, mes: mesNombre(m.value).mes, estado: 1, fecha: as[0].fecha })
            } else {
                registro.push({
                    id: cont,
                    cuota: cont,
                    mes: mesNombre(m.value).mes,
                    estado: 0,
                    fecha: "Sin Registro de Pago",
                })
            }
            cont++
        })

        return registro
    }

    const nombreDia = (fecha) => {
        let n = moment(fecha).day()

        switch (n) {
            case 1:
                return "Lunes"
                break
            case 2:
                return "Martes"
                break
            case 3:
                return "Miercoles"
                break
            case 4:
                return "Jueves"
                break
            case 5:
                return "Viernes"
                break
            case 6:
                return "Sabado"
                break
            case 0:
                return "Domingo"
                break
            default:
                return "No Definido"
                break
        }
    }

    const classes = Styles()

    return (
        <div className="alumnos-container">
            <Container title={"CONTROL DE TU CUOTA MENSUAL"} more>
                <Toaster position="center-center" />

                <CuotaUserControlTopInfo mesActivo={cuotaMesActivo()} />

                <Typography variant="h6" style={{ marginBottom: 20, textAlign: "center" }}>
                    Registro Historico de {year}
                </Typography>

                {cuotas?.length < 1 ? (
                    <CircularProgress color="secondary" />
                ) : (
                    <>
                        {claseId == "" ? (
                            <CuotaUserControlTable data={cuotas} mes={month} loading={loading} />
                        ) : (
                            <Typography>NO TIENE CLASE ASIGNADA</Typography>
                        )}
                    </>
                )}

                <Typography variant="h6" style={{ marginBottom: 20, textAlign: "center" }}>
                    ¿Tenes Dudas Sobre Tu Cuota Mensual? No dudes en consultale a tu Instructor.{" "}
                </Typography>
            </Container>
        </div>
    )
}

export default CuotaUserControl

import React, { useState } from "react"
import { makeStyles } from "@material-ui/styles"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"

const useStyles = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.down("sm")]: {
            top: "50vh",
            left: "1vw",
        },
        position: "absolute",
        top: "40vh",
        left: "50vw",
        minWidth: 370,
        maxWidth: 400,
        padding: 10,
        background: "linear-gradient(to right,  #101010, #151515 , #202020 , #303030 , #434343)",
        boxShadow: 10,
        borderRadius: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    alertText: {
        color: "#e00",
    },
    text: {
        textAlign: "center",
    },
    button: {
        width: 80,
        backgroundColor: "#e00",
        margin: 15,
    },
    buttonSection: {
        display: "flex",
    },
}))

const ModalBody = ({ modalOpen, handleDelete, setModalOpen, password, nombre, clase, centro }) => {
    const style = useStyles()
    const [pass, setPass] = useState(null)

    let id = modalOpen

    const handleChange = (e) => {
        setPass(e.target.value)
    }

    return (
        <div className={style.paper}>
            <Typography variant="h5" className={style.alertText}>
                Atencion
            </Typography>

            {password && (
                <>
                    <Typography
                        variant="h6"
                        className={style.text}
                    >{`Ingrese su contraseña para BORRAR el EXAMEN con id: ${id}`}</Typography>
                    <TextField
                        variant="outlined"
                        label="Password"
                        name="pass"
                        value={pass}
                        type="password"
                        onChange={handleChange}
                        required
                    />
                    <section className={style.buttonSection}>
                        <Button className={style.button} onClick={() => handleDelete(id, pass)} size="small">
                            Borrar
                        </Button>
                        <Button
                            className={style.button}
                            onClick={() => {
                                setModalOpen(false)
                            }}
                            size="small"
                        >
                            Cancelar
                        </Button>
                    </section>
                </>
            )}

            {nombre && (
                <>
                    {" "}
                    <Typography
                        variant="h6"
                        className={style.text}
                    >{`¿Desea borrar la actividad ${nombre.nombre} ?`}</Typography>
                    <section className={style.buttonSection}>
                        <Button className={style.button} onClick={() => handleDelete(nombre.id)} size="small">
                            Borrar
                        </Button>
                        <Button
                            className={style.button}
                            onClick={() => {
                                setModalOpen(false)
                            }}
                            size="small"
                        >
                            Cancelar
                        </Button>
                    </section>
                </>
            )}

            {clase && (
                <>
                    <Typography
                        variant="h6"
                        className={style.text}
                    >{`¿Desea borrar la clase ${clase.nombre} ?`}</Typography>
                    <section className={style.buttonSection}>
                        <Button className={style.button} onClick={() => handleDelete(clase.id)} size="small">
                            Borrar
                        </Button>
                        <Button
                            className={style.button}
                            onClick={() => {
                                setModalOpen(false)
                            }}
                            size="small"
                        >
                            Cancelar
                        </Button>
                    </section>
                </>
            )}

            {centro && (
                <>
                    <Typography
                        variant="h6"
                        className={style.text}
                    >{`¿Desea borrar el centro ${centro.nombre} ?`}</Typography>
                    <section className={style.buttonSection}>
                        <Button className={style.button} onClick={() => handleDelete(centro.id)} size="small">
                            Borrar
                        </Button>
                        <Button
                            className={style.button}
                            onClick={() => {
                                setModalOpen(false)
                            }}
                            size="small"
                        >
                            Cancelar
                        </Button>
                    </section>
                </>
            )}
        </div>
    )
}

export default ModalBody

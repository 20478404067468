import React, { useEffect, useState } from "react"
import { fetchResource } from "../Functions/Functions"
import Container from "./Container/Container"
import Loading from "./Loading/Loading"

const DatosLayout = (props) => {
    const [datosUser, setDatosUser] = useState()

    useEffect(() => {
        const fetchUserData = async () => {
            const [data, error] = await fetchResource("/users/show", "get", true, null)
            if (data) {
                setDatosUser(data.user)
            } else {
                console.error(error)
            }
        }
        fetchUserData()
    }, [])

    return (
        <Container more="false" moreUrl={"/profile"} edit title="Datos Personales">
            {!datosUser ? (
                <Loading text={"Cargando datos personales..."} />
            ) : (
                <div>
                    <h4>{`Dni: ${datosUser.dni}`}</h4>
                    <h4>{`Nombre: ${datosUser.nombre}`}</h4>
                    <h4>{`Apellido: ${datosUser.apellido}`}</h4>
                    <h4>{`Email: ${datosUser.email}`}</h4>
                    {datosUser.categoria ? (
                        <h4>{`Categoria: ${datosUser.categoria.nombre}`}</h4>
                    ) : (
                        <h4>{`Categoria: No definido`}</h4>
                    )}
                    <h4>{datosUser.instructor ? `Instructor: ${datosUser.instructor}` : `Instructor: No Asignado`}</h4>
                    {/* {datosUser.escuela ? <h4>{`Escuela: ${datosUser.escuela.nombre}`}</h4> : <h4>{`Escuela: No Asignada`}</h4>} */}
                    {/* {datosUser.centro ? <h4>{`Centro de practica: ${datosUser.centro.nombre}`}</h4> : <h4>{`Centro de practica: No Asignado`}</h4>} */}
                    <h4>{`Fecha de Nacimiento: ${datosUser.nacimiento}`}</h4>
                    <h4>{`Nacionalidad: ${datosUser.nacionalidad}`}</h4>
                </div>
            )}
        </Container>
    )
}

export default DatosLayout

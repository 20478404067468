import React, { useState } from "react"
import { fetchResource } from "../../../Functions/Functions"
import CreateClaseForm from "./CreateClaseForm"
import { CircularProgress } from "@material-ui/core"

const CreateClaseHandler = ({ centros, setListaClases, listaClases }) => {
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState(false)
    const [clase, setClase] = useState({
        descripcion: "",
        lunes: false,
        martes: false,
        miercoles: false,
        jueves: false,
        viernes: false,
        sabado: false,
        domingo: false,
        hora_inicio: "",
        inicio: "",
        hora_fin: "",
        fin: "",
        centro_id: "",
        nivel: "",
    })

    const handleChange = (e) => {
        // console.log(e.target.name,e.target.value)
        if (e.target.name === "inicio") {
            let valor = e.target.value.replace(":", "")
            setClase({ ...clase, [e.target.name]: e.target.value, ["hora_inicio"]: valor })
        } else if (e.target.name === "fin") {
            let valor = e.target.value.replace(":", "")
            setClase({ ...clase, [e.target.name]: e.target.value, ["hora_fin"]: valor })
        } else {
            setClase({ ...clase, [e.target.name]: e.target.value })
        }
    }

    const handleToggle = (dia) => {
        setClase({
            ...clase,
            [dia]: !clase[dia],
        })
    }

    const handleSubmit = async (event) => {
        console.log(clase)
        event.preventDefault()
        setLoading(true)
        const [data, error] = await fetchResource("/instructor/clases/create", "post", true, clase)

        if (data) {
            setLoading(false)
            setListaClases([...listaClases, data.data])
        } else {
            setLoading(false)
            setErrors(true)
            console.log(error)
        }
        setClase({
            descripcion: "",
            lunes: false,
            martes: false,
            miercoles: false,
            jueves: false,
            viernes: false,
            sabado: false,
            domingo: false,
            hora_inicio: "",
            inicio: "",
            hora_fin: "",
            fin: "",
            centro_id: "",
            nivel: "",
        })
    }
    if (loading) {
        return <CircularProgress color="secondary" />
    } else {
        return (
            <CreateClaseForm
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleToggle={handleToggle}
                centros={centros}
                clase={clase}
                error={errors}
            />
        )
    }
}

export default CreateClaseHandler

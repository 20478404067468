import {
    Button,
    CircularProgress,
    makeStyles,
    Modal,
    TextField,
    Typography,
    InputLabel,
    Input,
    Tooltip,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import Containter from "../Components/Container/Container"
import Selection from "../Components/Formularios/Select"
import { fetchResource } from "../Functions/Functions"
import toast, { Toaster } from "react-hot-toast"
import { GridColumnHeadersItemCollection } from "@mui/x-data-grid"

const useStyles = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.down("sm")]: {
            top: "50vh",
            left: "1vw",
        },
        position: "absolute",
        top: "40vh",
        left: "50vw",
        minWidth: 370,
        maxWidth: 400,
        padding: 10,
        background: "linear-gradient(to right,  #101010, #151515 , #202020 , #303030 , #434343)",
        boxShadow: 10,
        borderRadius: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    alertText: {
        color: "#e00",
    },
    text: {
        textAlign: "center",
    },
    button: {
        width: 80,
        backgroundColor: "#e00",
    },
    buttonSection: {
        display: "flex",
    },
}))

export const EditCategory = () => {
    const { userId } = useParams()
    const navigate = useNavigate()
    const [datoAnterior, setDatoAnterior] = useState({ categoria: null, clase: null, email: null })
    const [tipoModal, setTipoModal] = useState(0)
    const [clases, setClases] = useState()
    const [modalON, setModalON] = useState(false)
    const [datosAlumno, setDatosAlumno] = useState([])
    const [loading, setLoading] = useState(true)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [selectedClase, setSelectedClase] = useState(null)
    const [selectedEmail, setSelectedEmail] = useState()
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [instructorData, setInstructorData] = useState({
        dni: "",
        password: "",
    })
    const state = useSelector((state) => ({ ...state["basicDataReducer"] }))

    const { categorias } = state.basicData

    useEffect(() => {
        const fetchDatosClases = async () => {
            const [data, error] = await fetchResource(`/menu/clases`, "get", true, null)
            if (data) {
                setClases(data.data)

                console.log(data.data)
            } else {
                setLoading(false)
                console.error(error)
            }
        }

        const fetchDatosAlumno = async () => {
            const [data, error] = await fetchResource(`/instructor/alumnos/showSpecific/${userId}`, "get", true, null)
            if (data.code === 200) {
                setLoading(false)
                setSelectedClase(data.alumno.clase_id)
                setSelectedCategory(data.alumno.categoria_id)
                setSelectedEmail(data.alumno.email)
                setDatosAlumno(data.alumno)
                setDatoAnterior({
                    categoria: data.alumno.categoria_id,
                    clase: data.alumno.clase_id,
                    email: data.alumno.email,
                })
            } else {
                setLoading(false)
                console.error(error)
            }
        }

        fetchDatosAlumno()
        fetchDatosClases()
    }, [])

    const disbledButtonCategory = () => {
        var value = false
        if (selectedCategory == "") {
            value = true
        } else {
            value = false
        }
        return value
    }

    const disbledButtonClase = () => {
        var value = false
        if (selectedClase == "") {
            value = true
        } else {
            value = false
        }
        return value
    }
    const notify = (x, nombre) => {
        if (x == 1) {
            toast.success(nombre + ".", { style: { background: "lightgreen", color: "Black" } })
        } else {
            toast.error(nombre + ".", { style: { background: "red", color: "white" } })
        }
    }

    const ConfirmaAcreditacion = async (tipo, dni, pass) => {
        try {
            const datos = { dni: instructorData.dni, password: instructorData.password }
            const [data, error] = await fetchResource(`/users/login`, "POST", true, datos)
            if (data.code === 200) {
                //  notify(1,"Clase Actualiza Correctamente")
                setInstructorData({ dni: "", password: "" }) // borra los datos de acreditacion
                switch (tipoModal) {
                    case 2:
                        updateClase()

                        break
                    case 3:
                        updateEmail()
                        break
                    case 4:
                        resetPass()
                        break

                    default:
                        break
                }

                console.log(data.data)
            } else {
                setSelectedClase(datoAnterior.clase)
                setSelectedEmail(datoAnterior.email)
                setModalON(false)
                setInstructorData({ dni: "", password: "" }) // borra los datos de acreditacion
                notify(0, "Acreditacion Incorrecta")
                console.error(error)
            }
        } catch (error) {
            setSelectedClase(datoAnterior.clase)
            setSelectedEmail(datoAnterior.email)
            setInstructorData({ dni: "", password: "" }) // borra los datos de acreditacion
            setModalON(false)
            notify(0, "Acreditacion Incorrecta, No es posible realizar los Cambios")
        }
    }

    const disbledButtonEmail = () => {
        var value = false
        if (!selectedEmail) {
            console.log("FALSOEMAIL  " + selectedEmail)
            return true
        } else {
            const reg1 = /@/
            const resultado1 = selectedEmail.match(reg1)
            const reg2 = /(?=\.[a-z,A-Z]{3})/
            const resultado2 = selectedEmail.match(reg2)
            const reg3 = /\s/g
            const resultado3 = selectedEmail.match(reg3)
            console.log(resultado2)

            if (selectedEmail == "" || !resultado1 || !resultado2 || resultado3) {
                value = true
            } else {
                value = false
            }
            return value
        }
    }

    const handleChangeClase = (e) => {
        setSelectedClase(e.target.value)
    }
    const handleChangeEmail = (e) => {
        setSelectedEmail(e.target.value)
    }

    const handleChangeCategory = (e) => {
        setSelectedCategory(e.target.value)
    }

    const handleClose = () => {
        setConfirmationModal(false)
    }

    const updateCategory = async () => {
        const payload = {
            ...instructorData,
            categoria_id: selectedCategory,
        }
        const [data, error] = await fetchResource(`/instructor/alumnos/updateGrade/${userId}`, "PUT", true, payload)
        if (data.code === 200) {
            setConfirmationModal(false)
            setDatoAnterior({ ...datoAnterior, categoria: selectedCategory })
            notify(1, "Categoria Actualizada Correctamente")
        } else {
            setSelectedCategory(datoAnterior.categoria)
            setConfirmationModal(false)
            notify(0, "Acreditacion Incorrecta")
            console.log(error)
        }
    }

    const updateClase = async () => {
        const payload = [{ id: userId, clase_id: selectedClase }]
        const [data, error] = await fetchResource(`/instructor/alumnos/attachToClass`, "POST", true, payload)

        if (data) {
            setDatoAnterior({ ...datoAnterior, clase: selectedClase })
            setModalON(false)
            notify(1, "Clase Actualizada Correctamente")
        } else {
            setModalON(false)
            notify(0, "Error al actualizar la clase")
            console.log(error)
        }
    }

    const updateEmail = () => {
        setModalON(false)
        notify(1, "Email aun no actualizado")
    }

    const resetPass = async () => {
        try {
            const datos = {
                pw: datosAlumno.dni,
                dni: datosAlumno.dni,
            }
            //    console.log({datos})
            //     const [data, error] = await fetchResource(`/presidente/admin/pwUp`, 'PUT', true, datos)
            notify(0, "Aun no envia NADA")
            setModalON(false)
            // if (data) {
            //     setModalON(false)
            //     notify(1, "Clave Reseteada Correctamente")

            // } else {
            //     notify(0, "error al Resetear la Clave")
            //    cancelModal(false)
            //     console.log(error)
            // }
        } catch (error) {
            cancelModal(false)
            notify(0, "error al Resetear la Clave, ErrorGRAVE")
        }
    }

    const cancelModal = (modal) => {
        setModalON(modal)
        setSelectedClase(datoAnterior.clase)
        setSelectedEmail(datoAnterior.email)
        setInstructorData({ dni: "", password: "" }) // borra los datos de acreditacion
    }

    const cancelCategoryModal = (modal) => {
        setConfirmationModal(modal)
        setSelectedCategory(datoAnterior.categoria)
    }

    const openModal = (tipo) => {
        setModalON(true)
        setTipoModal(tipo)
    }
    var color = ""
    return (
        <Containter title="MODIFICACION DE DATOS RELEVANTES" more>
            <div style={{ marginTop: 10, display: "flex", height: "100%", flexDirection: "column" }}>
                {clases && datosAlumno && selectedCategory !== null ? (
                    <>
                        <div style={{ width: 250, alignSelf: "center" }}>
                            <Typography align="center" variant="h6">
                                {datosAlumno.nombre} {datosAlumno.apellido}
                            </Typography>
                        </div>

                        <div
                            style={{
                                width: "100%",
                                marginTop: 40,
                                display: "flex",
                                flexDirection: "column",
                                padding: 10,
                                borderStyle: "inset",
                                borderWidth: 2,
                                borderColor: "white",
                                borderRadius: 5,
                            }}
                        >
                            <Selection
                                id="categorias"
                                name="categoria"
                                value={selectedCategory}
                                options={categorias}
                                onChange={handleChangeCategory}
                                label="Categoria*"
                                color="secondary"
                                required
                            />
                            <Tooltip title="Edita Categoria" placement="right" arrow>
                                <Button
                                    style={{ background: "#e00", width: "100%", marginTop: 5, alignSelf: "center" }}
                                    onClick={() => setConfirmationModal(true)}
                                    disabled={disbledButtonCategory()}
                                >
                                    Modificar
                                </Button>
                            </Tooltip>
                            <Typography style={{ width: "100%", textAlign: "center", fontSize: 13 }}>
                                *Requiere acreditacion.{" "}
                            </Typography>
                        </div>

                        <div
                            style={{
                                width: "100%",
                                marginTop: 20,
                                display: "flex",
                                flexDirection: "column",
                                padding: 10,
                                borderStyle: "inset",
                                borderWidth: 2,
                                borderColor: "white",
                                borderRadius: 5,
                            }}
                        >
                            {!selectedClase ? (
                                <Selection
                                    id="clase"
                                    name="clase"
                                    value={""}
                                    valorNulo={"Sin Clase"}
                                    options={clases}
                                    onChange={handleChangeClase}
                                    label="Seleccione una Clase"
                                    color="secondary"
                                    estilo={{ width: "10%" }}
                                    required
                                />
                            ) : (
                                <Selection
                                    id="clase"
                                    name="clase"
                                    valorNulo={"Sin Clase"}
                                    value={selectedClase}
                                    options={clases}
                                    onChange={handleChangeClase}
                                    label="Clase"
                                    color="secondary"
                                    estilo={{ width: "10%" }}
                                    required
                                />
                            )}
                            <Tooltip title="Edita Clase" placement="right" arrow>
                                <Button
                                    style={{ background: "#e00", marginTop: 5, alignSelf: "center", width: "100%" }}
                                    onClick={() => openModal(2)}
                                    disabled={disbledButtonClase()}
                                >
                                    Modificar
                                </Button>
                            </Tooltip>
                            {/*                            
                            <Tooltip title="Quitar de Clase" placement="right" arrow><Button style={{ background: "#e00", marginTop: 5, alignSelf: "center", width: "100%" }} onClick={() => openModal(2)} disabled={disbledButtonClase()} >Modificar</Button></Tooltip>
                  */}

                            <Typography style={{ width: "100%", textAlign: "center", fontSize: 13 }}>
                                *Requiere acreditacion.{" "}
                            </Typography>
                        </div>

                        <div
                            style={{
                                width: "100%",
                                padding: 10,
                                marginTop: 20,
                                justifyItems: "center",
                                display: "flex",
                                flexDirection: "column",
                                borderStyle: "inset",
                                borderWidth: 2,
                                borderColor: "white",
                                borderRadius: 5,
                            }}
                        >
                            <TextField
                                style={{ width: "100%" }}
                                id="outlined-basic"
                                label="Email"
                                variant="outlined"
                                value={selectedEmail}
                                onChange={handleChangeEmail}
                                InputLabelProps={{ shrink: true }}
                            />
                            <Tooltip title="Edita eMail" placement="right" arrow>
                                <Button
                                    style={{ background: "#e00", marginTop: 5, alignSelf: "center", width: "100%" }}
                                    onClick={() => openModal(3)}
                                    disabled={disbledButtonEmail()}
                                >
                                    Modificar
                                </Button>
                            </Tooltip>
                            <Typography style={{ width: "100%", textAlign: "center", fontSize: 13 }}>
                                *Requiere acreditacion.{" "}
                            </Typography>
                        </div>
                        <div style={{ width: "100%", marginTop: 20, justifyItems: "center" }}>
                            <Tooltip title="VOLVER" placement="right" arrow>
                                <Button
                                    style={{ background: "#e00", width: "100%", marginTop: 50 }}
                                    onClick={() => navigate(`/alumnos`)}
                                >
                                    Volver
                                </Button>
                            </Tooltip>
                        </div>

                        <Modal
                            open={confirmationModal}
                            onClose={handleClose}
                            aria-label="simple-modal-title"
                            aria-describedby="simple-modal-description"
                        >
                            <ChangeCategoryModal
                                cancelCategory={cancelCategoryModal}
                                newCategory={selectedCategory}
                                updateCategory={updateCategory}
                                instructorData={instructorData}
                                setInstructorData={setInstructorData}
                            />
                        </Modal>

                        <Modal
                            open={modalON}
                            onClose={handleClose}
                            aria-label="simple-modal-title"
                            aria-describedby="simple-modal-description"
                        >
                            <ChangeModal
                                confirm={ConfirmaAcreditacion}
                                updateCategory={updateCategory}
                                instructorData={instructorData}
                                setInstructorData={setInstructorData}
                                cancel={cancelModal}
                            />
                        </Modal>
                    </>
                ) : (
                    <CircularProgress color="red" />
                )}
            </div>
            <Toaster position="center-center" />
        </Containter>
    )
}

const ChangeCategoryModal = ({ newCategory, updateCategory, instructorData, setInstructorData, cancelCategory }) => {
    const style = useStyles()

    return (
        <div className={style.paper}>
            <Typography variant="h5" className={style.alertText}>
                Atencion
            </Typography>
            <>
                <Typography
                    variant="h6"
                    className={style.text}
                >{`Acredite su usuario para realizar los cambios`}</Typography>
                <TextField
                    autoComplete="nope"
                    id="dni"
                    value={instructorData.dni}
                    onChange={(e) => setInstructorData({ ...instructorData, dni: e.target.value })}
                    name="dni"
                    type="text"
                    variant="outlined"
                    size="small"
                    label="Dni"
                    color="secondary"
                    required
                />
                <TextField
                    style={{ margin: 10 }}
                    autoComplete="nope"
                    id="password"
                    value={instructorData.password}
                    onChange={(e) => setInstructorData({ ...instructorData, password: e.target.value })}
                    name="password"
                    type="password"
                    variant="outlined"
                    size="small"
                    label="Contraseña"
                    color="secondary"
                    required
                />
                <section className={style.buttonSection}>
                    <Button
                        className={style.button}
                        style={{ margin: 15 }}
                        onClick={() => updateCategory(newCategory)}
                        size="small"
                    >
                        Confirmar
                    </Button>
                    <Button
                        className={style.button}
                        style={{ margin: 15 }}
                        onClick={() => {
                            cancelCategory(false)
                        }}
                        size="small"
                    >
                        Cancelar
                    </Button>
                </section>
            </>
        </div>
    )
}

const ChangeModal = ({ newCategory, updateCategory, instructorData, setInstructorData, cancel, confirm }) => {
    const style = useStyles()

    return (
        <div className={style.paper}>
            <Typography variant="h5" className={style.alertText}>
                Atencion
            </Typography>
            <>
                <Typography
                    variant="h6"
                    className={style.text}
                >{`Acredite su usuario para realizar los cambios`}</Typography>
                <TextField
                    autoComplete="nope"
                    id="dni"
                    value={instructorData.dni}
                    onChange={(e) => setInstructorData({ ...instructorData, dni: e.target.value })}
                    name="dni"
                    type="text"
                    variant="outlined"
                    size="small"
                    label="Dni"
                    color="secondary"
                    required
                />
                <TextField
                    style={{ margin: 10 }}
                    autoComplete="nope"
                    id="password"
                    value={instructorData.password}
                    onChange={(e) => setInstructorData({ ...instructorData, password: e.target.value })}
                    name="password"
                    type="password"
                    variant="outlined"
                    size="small"
                    label="Contraseña"
                    color="secondary"
                    required
                />
                <section className={style.buttonSection}>
                    <Button className={style.button} style={{ margin: 15 }} onClick={() => confirm()} size="small">
                        Confirmar
                    </Button>
                    <Button className={style.button} style={{ margin: 15 }} onClick={() => cancel(false)} size="small">
                        Cancelar
                    </Button>
                </section>
            </>
        </div>
    )
}

import React from "react"
import Containter from "../Container/Container"
import AppLogo from "../../Assets/Imagenes/Logo_fit.png"
import Button from "@material-ui/core/Button"
import { useNavigate } from "react-router-dom"
import { makeStyles } from "@material-ui/core"

const Styles = makeStyles({
    button: {
        backgroundColor: "#e00",
    },
})

const Intro = () => {
    let style = Styles()
    const navigate = useNavigate()

    return (
        <Containter more>
            <img src={AppLogo} />
            <h1 style={{ textAlign: "center" }}>Bienvenido a Plataforma TKD</h1>
            <Button variant="outlined" className={style.button} onClick={() => navigate("/login")}>
                INGRESA AQUI
            </Button>
        </Containter>
    )
}

export default Intro

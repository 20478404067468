import { Typography } from '@material-ui/core';
import React from 'react';

function DisplayComponent(props) {

  return (
    <div style={{display:"flex",flexDirection:"row",alignItems:"flex-end",justifyContent:"center"}}>
       {/* {h()}&nbsp;&nbsp; */}
      <Typography variant='h3'>{(props.time.m >= 10)? props.time.m : "0"+ props.time.m}:</Typography> 
      <Typography variant='h3'>{(props.time.s >= 10)? props.time.s : "0"+ props.time.s} </Typography> 
      <Typography variant='h5'  style={{margin:3}}>{(props.time.ms >= 10)? props.time.ms : "0"+ props.time.ms}</Typography>
    </div>
  );
}

export default DisplayComponent;
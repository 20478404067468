import React, { useEffect, useState } from "react"
import { fetchResource, usePermitions } from "../../../Functions/Functions"
import CircularProgress from "@material-ui/core/CircularProgress"
import Modal from "@material-ui/core/Modal"
import { useSelector } from "react-redux"
import { makeStyles, Select, MenuItem, option, Typography, TextField, Button } from "@material-ui/core"
import toast, { Toaster } from "react-hot-toast"
import { useLocation, useNavigate } from "react-router-dom"
import moment from "moment"
import Containter from "../../../Components/Container/Container"
import CuotaRegistroPagoTopInfo from "./CuotaRegistroPagoTopInfo"
import CuotaRegistroPagoTable from "./CuotaRegistroPagoTable"
import DefaultMessage from "../../../Components/DefaultMessage/DefaultMessage"

const Styles = makeStyles({
    filtersContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        minWidth: "100%",
        flexWrap: "wrap",
    },
    filterInput: {
        alignSelf: "flex-end",
    },
    recordCounter: {
        alignSelf: "end",
    },
    ageInputs: {
        maxWidth: "5em",
    },
    ctaButton: {
        backgroundColor: "red",
    },
})

const CuotaRegistroPago = () => {
    const state = useSelector((state) => ({ ...state["basicDataReducer"] }))
    const { basicData } = state
    const navigate = useNavigate()
    const { role_id } = usePermitions()
    const location = useLocation()
    const [loading, setLoading] = useState(true)
    const [alumnos, setAlumnos] = useState([]) // guarda los id y nombres de los alumnos
    const [claseActiva, setClaseActiva] = useState(location.state) // guarda todas las clases que va a mostrar en Select
    const [lista, setLista] = useState("")
    const [records, setRecords] = useState(0)
    const [modalOpen, setModalOpen] = useState({ estado: false, alumno: "" })
    const [diaPermitido, setDiaPermitido] = useState(false)
    const [year, setYear] = useState(moment().format("YYYY"))
    const [month, setMonth] = useState(moment().format("MM"))
    const [dia, setDia] = useState(moment().format("YYYY-MM-DD"))
    const paramsInitialValues = {
        page: 0,
        nombre: "",
        apellido: "",
        escuela: "",
        asociacion: "",
        categoria: "",
        instructor: "",
        edadDesde: "",
        edadHasta: "",
        actives: true,
        claseSelected: "",
        rowsPerPage: 10,
    }
    const [params, setParams] = useState(paramsInitialValues)

    const meses = [
        { id: "01", mes: "Enero" },
        { id: "02", mes: "Febrero" },
        { id: "03", mes: "Marzo" },
        { id: "04", mes: "Abril" },
        { id: "05", mes: "Mayo" },
        { id: "06", mes: "Junio" },
        { id: "07", mes: "Julio" },
        { id: "08", mes: "Agosto" },
        { id: "09", mes: "Septiembre" },
        { id: "10", mes: "Octubre" },
        { id: "11", mes: "Noviembre" },
        { id: "12", mes: "Diciembre" },
    ]

    useEffect(() => {
        fetchAlumnos()
        permitirDia()
    }, [params, month])

    const fetchAlumnos = async () => {
        setLoading(true)
        let body = { clase_id: claseActiva.id }

        const [data, error] = await fetchResource(`/instructor/clases/showAlumnosClass `, "post", true, body)

        if (data && data.code == 200) {
            setAlumnos(data.Alumnos)
            setRecords(data.Alumnos.lenght)

            fetchPagosCuotas(data.Alumnos)
        } else {
            setAlumnos(null)
            setLoading(false)
        }
    }

    const fetchPagosCuotas = async (datos) => {
        const [data, error] = await fetchResource("/instructor/cuotas/showAlumnos", "get", true, null)

        if (data && data.code == 200) {
            let periodo = moment().format("YYYY-MM")

            let listaCuotas = []

            datos.map((a) => {
                let d = {
                    id: a.id,
                    nombre_completo: a.nombre + " " + a.apellido,
                    categoria: categoria(a.categoria_id),
                    edad: edad(a.nacimiento),
                    estado: 0,
                    fecha: null,
                }

                let coincide = data.cuotas.filter((c) => c.user_id == a.id)

                if (coincide.length > 0) {
                    coincide[0].cuotas.map((r) => {
                        if (r.year == year && r.month == month && r.clase_id == claseActiva.id) {
                            d = { ...d, estado: 1, fecha: r.fecha }
                        }
                    })
                } else {
                    d = { ...d, estado: 0, fecha: null }
                }

                listaCuotas.push({ ...d })
            })
            setLista(listaCuotas)
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const notify = (x, nombre) => {
        let mensaje = ""

        if (x == 1) {
            if (nombre) {
                mensaje = nombre
            } else {
                mensaje = "Registro Correcto."
            }
            toast.success(mensaje, { style: { background: "lightgreen", color: "Black" } })
        } else {
            if (nombre) {
                mensaje = nombre
            } else {
                mensaje = "Registro Eliminado"
            }
            toast.error(mensaje, { style: { background: "red", color: "white" } })
        }
    }

    const edad = (nac) => {
        let anios = "--"

        if (nac !== "" && nac !== "0000-00-00") {
            let nacimiento = moment(nac)
            let hoy = moment()
            anios = hoy.diff(nacimiento, "years")
        }
        return anios
    }

    const categoria = (cat_id) => {
        let categoria = [
            "Blanco",
            "Punta Amarilla",
            "Amarillo",
            "Punta Verde",
            "Verde",
            "Punta Azul",
            "Azul",
            "Punta Roja",
            "Rojo",
            "Punta Negra",
            "Primer Dan",
            "Segundo Dan",
            "tercer Dan",
            "cuarto Dan",
            "quinto Dan",
            "sexto dan",
            "septimo Dan",
            "octavo Dan",
            "noveno dan",
        ]
        let r = ""

        r = categoria[cat_id - 1].toUpperCase()

        return r
    }
    /*---------------------------*/

    const handleRegister = async (id) => {
        let body = [
            {
                user_id: id,
                clase_id: claseActiva.id,
                year: year,
                month: month,
            },
        ]

        const [data, error] = await fetchResource("/instructor/cuotas/register", "post", true, body)

        if (data.code == 200) {
            let l = []
            lista.map((x) => {
                if (id == x.id) {
                    l.push({ ...x, estado: 1, fecha: moment().format("YYYY-MM-DD") })
                } else {
                    l.push({ ...x })
                }
            })
            notify(1, "Cuota registrada con exito")
            setLista(l)
        }

        if (error) console.error(error)
    }

    const handleFilter = (e) => {
        const field = e.target.name
        const value = e.target.value
        switch (field) {
            case "nombre":
                setParams({ ...params, nombre: value, page: 0 })
                break
            case "apellido":
                setParams({ ...params, apellido: value, page: 0 })
                break
            case "categoria":
                setParams({ ...params, categoria: value, page: 0 })
                break
            case "escuela":
                setParams({ ...params, escuela: value, page: 0 })
                break
            case "instructor":
                setParams({ ...params, instructor: value, page: 0 })
                break
            case "asociacion":
                setParams({ ...params, asociacion: value, page: 0 })
                break
            case "edadDesde":
                setParams({ ...params, edadDesde: value, page: 0 })
                break
            case "edadHasta":
                setParams({ ...params, edadHasta: value, page: 0 })
                break
            case "actives":
                setParams({ ...params, actives: !params.actives })
                break
            default:
                break
        }
    }

    const handleClaseSelect = (value) => {
        if (value.target.value == 0) {
            setParams({ ...params, claseSelected: "", page: 0 })
        } else {
            setParams({ ...params, claseSelected: value.target.value, page: 0 })
        }
    }

    const handleDelete = async (id) => {
        let body = [
            {
                user_id: id,
                clase_id: claseActiva.id,
                year: year,
                month: month,
            },
        ]
        const [data, error] = await fetchResource("/instructor/cuotas/deletePayment", "delete", true, body)

        if (data && data.code == 200) {
            let l = []
            lista.map((x) => {
                if (id == x.id) {
                    l.push({ ...x, estado: 0, fecha: null })
                } else {
                    l.push({ ...x })
                }
            })

            setLista(l)
            notify(0, "Registro de Cuota Eliminado")
            setLoading(false)
        } else {
            setLoading(false)
        }
        setModalOpen(false)
    }

    const handleClose = () => {
        setModalOpen(false)
    }

    const handleMes = (mes) => {
        setMonth(mes)
    }

    const changeAsistencia = async (id2, asist2) => {
        let body = [
            {
                user_id: id2,
                clase_id: claseActiva.id,
                fecha: dia,
                estado: !asist2,
            },
        ]

        const [data, error] = await fetchResource("/instructor/asistencia/register", "post", true, body)

        if (data && data.code == 200) {
            setLoading(false)
            let a = []

            lista.map((x) => {
                if (x.id == id2) {
                    a.push({ ...x, estado: !asist2 })
                } else {
                    a.push({ ...x })
                }
            })

            setLista(a)
        } else {
            setLoading(false)
            // console.log(error)
        }
    }

    const permitirDia = () => {
        let dianumero = moment(dia).day() //0 =domingo
        let domingo = claseActiva.domingo
        let lunes = claseActiva.lunes
        let martes = claseActiva.martes
        let miercoles = claseActiva.miercoles
        let jueves = claseActiva.jueves
        let viernes = claseActiva.viernes
        let sabado = claseActiva.sabado

        if (
            (domingo == 1 && dianumero == 0) ||
            (lunes == 1 && dianumero == 1) ||
            (martes == 1 && dianumero == 2) ||
            (miercoles == 1 && dianumero == 3) ||
            (jueves == 1 && dianumero == 4) ||
            (viernes == 1 && dianumero == 5) ||
            (sabado == 1 && dianumero == 6)
        ) {
            setDiaPermitido(true)
        } else {
            setDiaPermitido(false)
        }
    }

    const handleModal = (e, n) => {
        setModalOpen({ estado: e, nombre: n.nombre_completo, id: n.id })
    }

    const desvincular = async () => {
        let body = [{ id: modalOpen.id, clase_id: claseActiva.id }]

        const [data, error] = await fetchResource("/instructor/alumnos/dettachFromClass", "post", true, body)

        if (data && data.code == 200) {
            fetchAlumnos()
            setModalOpen({ ...modalOpen, estado: false })
        } else {
            console.log(error)
        }
    }

    const classes = Styles()

    return (
        <Containter title={ alumnos?.length >0 ?   "REGISTROS DE CUOTAS" : "REGISTROS DE CUOTAS "+location.state.nombre } more>
            <Toaster position="center-center" />
            {lista ? (
                <>
                    <CuotaRegistroPagoTopInfo
                        btnvolver={() => navigate("/clases")}
                        btnalumnos={() => navigate("/alumnos", { actives: true })}
                        changeMes={(x) => handleMes(x)}
                        nombreClase={location.state.nombre}
                        fecha={month}
                        datos={lista}
                        meses={meses}
                    />

                    <CuotaRegistroPagoTable
                        data={lista}
                        changeAsist={(i, a) => changeAsistencia(i, a)}
                        modal={(e, n) => handleModal(e, n)}
                        loading={loading}
                        desvincular={(id, nombre) => desvincular(id, nombre)}
                        pago={(id) => handleRegister(id)}
                        remove={(id) => handleDelete(id)}
                    />
                </>
            ) : (
                <>
                    {alumnos == null ? <DefaultMessage logo title={"NO HAY ALUMNOS REGISTRADOS A ESTA CLASE"}></DefaultMessage>
                        :
                        <>
                            <CircularProgress color="red"> </CircularProgress>
                            <Typography>Cargando Alumnos....</Typography>
                        </>
                    }

                </>
            )}

            <Modal
                open={modalOpen.estado}
                style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}
            >
                <Containter title="DESVINCULAR ALUMNO" styles={{ width: "50%", height: "50%" }} more>
                    <Typography variant="h5" style={{ textAlign: "center" }}>
                        ¿Desea Desvincular a {modalOpen.nombre} de {claseActiva.nombre}?{" "}
                    </Typography>

                    <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center" }}>
                        <Button onClick={() => desvincular()} style={{ background: "red", margin: 10 }}>
                            Confirmar
                        </Button>

                        <Button
                            onClick={() => setModalOpen({ ...modalOpen, estado: false })}
                            style={{ background: "red", margin: 10 }}
                        >
                            Cancelar
                        </Button>
                    </div>
                </Containter>
            </Modal>
        </Containter>
    )
}

export default CuotaRegistroPago

import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import Container from "../../Components/Container/Container"
import { fetchResource, usePermitions } from "../../Functions/Functions"
import { InscriptosDataFilters } from "./InscriptosDataFilter"
import InscriptosTable from "./InscriptosTable"
import { InscriptosTopInformation } from "./InscriptosTopInformation"

const InscriptosActividad = () => {
    const { activityId } = useParams()

    const state = useSelector((state) => ({ ...state["basicDataReducer"] }))
    const { basicData } = state
    const { role_id } = usePermitions()

    const paramsInitialValues = {
        page: 0,
        nombre: "",
        apellido: "",
        escuela: "",
        asociacion: "",
        categoria: "",
        instructor: "",
        edadDesde: "",
        edadHasta: "",
    }

    const [loading, setLoading] = useState(true)
    const [inscriptos, setInscriptos] = useState([])
    const [params, setParams] = useState(paramsInitialValues)
    const [records, setRecords] = useState(0)
    const navigate = useNavigate()

    useEffect(() => {
        const getInscriptos = async () => {
            const [data, error] = await fetchResource(
                `/instructor/activity/getUserSigned/${activityId}?page=${params.page + 1}&nombre=${params.nombre}&apellido=${params.apellido}&escuela=${params.escuela}&asociacion=${params.asociacion}&categoria=${params.categoria}&instructor=${params.instructor}&edadDesde=${params.edadDesde}&edadHasta=${params.edadHasta}`,
                "get",
                true,
                null
            )

            if (data) {
                setLoading(false)
                setRecords(data.total_records)
                setInscriptos(data.data)
            } else {
                setInscriptos([])
                setRecords(0)
                setLoading(false)
                console.error(error)
            }
        }
        getInscriptos()
    }, [params])

    const handlePageChange = (page) => {
        setParams({ ...params, page: page })
    }

    const handleRemove = async (id) => {
        let parametro = { id: id }
        const [data, error] = await fetchResource(
            `/instructor/activity/unsign/${activityId}/${id}`,
            "put",
            true,
            parametro
        )
        if (error) console.error(error)
        let alumnosFiltrado = inscriptos.filter((data) => data.id !== id)
        setInscriptos(alumnosFiltrado)
    }

    const clearFilters = () => {
        setParams(paramsInitialValues)
    }

    const handleFilter = (e) => {
        const field = e.target.name
        const value = e.target.value
        switch (field) {
            case "nombre":
                setParams({ ...params, nombre: value })
                break
            case "apellido":
                setParams({ ...params, apellido: value })
                break
            case "categoria":
                setParams({ ...params, categoria: value })
                break
            case "escuela":
                setParams({ ...params, escuela: value })
                break
            case "instructor":
                setParams({ ...params, instructor: value })
                break
            case "asociacion":
                setParams({ ...params, asociacion: value })
                break
            case "edadDesde":
                setParams({ ...params, edadDesde: value })
                break
            case "edadHasta":
                setParams({ ...params, edadHasta: value })
                break
            default:
                break
        }
    }

    return (
        <div className="alumnos-container">
            <Container title="Listado de alumnos Inscriptos" styles={{ display: "flex" }} more>
                <InscriptosTopInformation records={records} />
                <InscriptosDataFilters
                    basicData={basicData}
                    handleFilter={handleFilter}
                    clearFilters={clearFilters}
                    params={params}
                    userRole={role_id}
                />
                <InscriptosTable
                    data={inscriptos}
                    changePage={handlePageChange}
                    pageData={{ currentPage: params.page, records: records }}
                    loading={loading}
                    removeRecord={handleRemove}
                />
            </Container>
        </div>
    )
}

export default InscriptosActividad

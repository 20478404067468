import React from "react"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core"
import Check from "../../Assets/Iconos/green-check.png"
import Cross from "../../Assets/Iconos/red-cross.png"

const Styles = makeStyles({
    typo: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
    },
    img: {
        maxWidth: "50px",
        marginTop: "10px",
    },
})

const MatriculasActual = ({ matricula }) => {
    const classes = Styles()
    const date = new Date()

    return (
        <>
            <Typography variant="h6" aria-label="matricula" className={classes.typo}>
                {`Estado de la matricula ${date.getFullYear()}:`}
                <img className={classes.img} src={matricula?.actual ? Check : Cross} />
            </Typography>
        </>
    )
}

export default MatriculasActual

import React, { useState } from "react"
import { fetchResource } from "../../../Functions/Functions"
import CreateCentroForm from "./CreateCentroForm"
import { CircularProgress } from "@material-ui/core"
 


const CreateCentroHandler = ({ setListaCentros, listaCentros, edit, noVisible, handleCancel ,not}) => {
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState(false)
    const [centro, setCentro] = useState({
        id: edit !== null ? edit.id : "",
        nombre: edit !== null ? edit.nombre : "",
        direccion: edit !== null ? edit.direccion : "",
        zona: edit !== null ? edit.zona : "",
        localidad: edit !== null ? edit.localidad : "",
        telefono: edit !== null ? edit.telefono : "",
        contacto: edit !== null ? edit.contacto : "",
        email: edit !== null ? edit.email : "",
        web: edit !== null ? edit.web : "",
    })
 
    if (edit?.nombre !== centro.nombre && edit !== null) {
        setCentro({
            id: edit.id,
            nombre: edit.nombre,
            direccion: edit.direccion,
            zona: edit.zona,
            localidad: edit.localidad,
            telefono: edit.telefono,
            contacto: edit.contacto,
            email: edit.email,
            web: edit.web,
        })
    }

    const handleChange = (e) => {
        setCentro({
            ...centro,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = async (event) => {
        //    event.preventDefault()
        setLoading(true)

        if (edit == null) {
            console.log("CREA")
            const [data, error] = await fetchResource("/instructor/centros/create", "post", true, centro)
            if (data) {
                setListaCentros([...listaCentros, data.centro])
                setLoading(false)
                not(1, "CENTRO, CREADO CORRECTAMENTE")
            } else {
                setLoading(false)
                setErrors(true)
                console.error(error)
                not(0, "ERROR, VERIFIQUE LOS DATOS.")
            }
        } else {
            console.log("EDITA")
            const [data, error] = await fetchResource("/instructor/centros/update", "post", true, centro)
            if (data) {
                setLoading(false)
                setListaCentros(
                    listaCentros.map((x) => {
                        if (x.id == centro.id) {
                            return { ...centro }
                        } else {
                            return { ...x }
                        }
                    })
                )

                not(1, "DATOS DEL CENTRO, MODIFICADOS CORRECTAMENTE")
            } else {
                setLoading(false)
                setErrors(true)
                console.error(error)
                not(0, "NO SE PUDO MODIFICAR, VERIFIQUE LOS DATOS")
              
            }
        }

        setCentro({
            id:"",
            nombre: "",
            direccion: "",
            zona: "",
            localidad: "",
            telefono: "",
            contacto: "",
            email: "",
            web: "",
        })
        noVisible(false)
    }

  

    if (loading) {
        return <CircularProgress color="secondary" />
    } else {
        return (<>
           
             <CreateCentroForm
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                centro={centro}
                error={errors}
                edit={edit ? true : false}
            />
            </>
        )
    }
}

export default CreateCentroHandler

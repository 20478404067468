import React, { useEffect, useState } from "react"
import Container from "../Components/Container/Container"
import { fetchResource, useTableRecordEdit } from "../Functions/Functions"
import CircularProgress from "@material-ui/core/CircularProgress"
import UsersTable from "../Components/Table/UsersTable"
import DefaultMessage from "../Components/DefaultMessage/DefaultMessage"
import ActionsTable from "../Components/Table/ActionsTable"
import { UsersTableBasicColumns } from "../Components/Table/AppColumns"

const Instructores = () => {
    const [loading, setLoading] = useState(true)
    const [instructores, setInstructores] = useState([])
    const [alumnos, setAlumnos] = useState(null)
    const [filtro, setFiltro] = useState(null)

    useEffect(() => {
        const fetchInstructores = async () => {
            const [data, error] = await fetchResource("/director/instructores/showInstructores", "get", true, null)

            if (data) {
                setLoading(false)
                console.log(data)
                setInstructores(data.instructores)
            } else {
                setLoading(false)
                // console.log(error)
            }
        }

        const fetchAlumnos = async () => {
            const [data, error] = await fetchResource("/instructor/alumnos/show", "post", true, null)

            if (data) {
                setLoading(false)
                setAlumnos(data.alumnos)
            } else {
                setLoading(false)
                // console.log(error)
            }
        }
        fetchAlumnos()
        fetchInstructores()
    }, [])

    const handleChange = (e) => {
        setFiltro(e.target.value)
    }

    const { handleRecordEdit } = useTableRecordEdit("/editar/registro")

    return (
        <div className="instructores-container">
            <Container title="Instructores" more>
                {loading ? (
                    <CircularProgress color="secondary" />
                ) : (
                    <>
                        {instructores.length > 0 ? (
                            <ActionsTable data={instructores} columns={UsersTableBasicColumns}>
                                {(props) => (
                                    <UsersTable
                                        data={props.filteredData}
                                        columns={props.columns}
                                        edit={handleRecordEdit}
                                        addAndRemove={props.handleRecordEdit}
                                    />
                                )}
                            </ActionsTable>
                        ) : (
                            <DefaultMessage title={"No hay instructores registrados"} />
                        )}
                    </>
                )}
            </Container>
        </div>
    )
}

export default Instructores

import React, { useEffect, useState } from "react"
import Container from "../Components/Container/Container"
import CreateExamHandler from "../Components/Formularios/Examen/CreateExamHandler"
import { fetchResource } from "../Functions/Functions"
import CircularProgress from "@material-ui/core/CircularProgress"
import ExamenTable from "../Components/Table/ExamenTable"
import Modal from "@material-ui/core/Modal"
import ModalBody from "../Components/ModalBody/ModalBody"
import DefaultMessage from "../Components/DefaultMessage/DefaultMessage"
import { useNavigate } from "react-router-dom"
import { Button, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import toast from "react-hot-toast"

const useStyles = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.down("sm")]: {
            top: "50vh",
            left: "1vw",
        },
        position: "absolute",
        top: "40vh",
        left: "50vw",
        minWidth: 370,
        maxWidth: 400,
        padding: 10,
        background: "linear-gradient(to right,  #101010, #151515 , #202020 , #303030 , #434343)",
        boxShadow: 10,
        borderRadius: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    alertText: {
        color: "#e00",
    },
    text: {
        textAlign: "center",
    },
    button: {
        margin: 4,
        backgroundColor: "#e00",
    },
    buttonSection: {
        display: "flex",
    },
    sideButtonSection: {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
    },
}))

const Examen = () => {
    const navigate = useNavigate()
    const [listaExamenes, setListaExamenes] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [promoteModalOpen, setPromoteModalOpen] = useState(null)
    const [promote, setPromote] = useState(false)

    useEffect(() => {
        const fetchExamenes = async () => {
            const [data, error] = await fetchResource("/director/examen/show", "get", true, null)

            if (data) {
                setLoading(false)
                setListaExamenes(data.examenes)
            } else {
                setLoading(false)
                console.log(error)
            }
        }

        fetchExamenes()
    }, [promote])

    const handleDelete = async (id, pass) => {
        let parametro = {
            id: id,
            password: pass,
        }
        const [data, error] = await fetchResource("/director/examen/delete", "post", true, parametro)

        if (data) {
            let listaFiltrada = listaExamenes.filter((item) => item.id !== id)
            setListaExamenes(listaFiltrada)
        } else {
            console.log(error)
        }
        setModalOpen(false)
    }

    const handleActive = async (id) => {
        let parametro = { id: id }
        const [data, error] = await fetchResource("/director/examen/activation", "post", true, parametro)

        if (data) {
            let listaModif = listaExamenes.map((examen) => {
                if (examen.id === id) {
                    return { ...examen, inscripcion_activa: !examen.inscripcion_activa }
                } else {
                    return { ...examen }
                }
            })
            setListaExamenes(listaModif)
        } else {
            console.log(error)
        }
    }

    const handleEvaluado = async (id) => {
        let parametro = { id: id }
        const [data, error] = await fetchResource("/director/examen/setEvaluado", "post", true, parametro)

        if (data) {
            let listaModif = listaExamenes.map((examen) => {
                if (examen.id === id) {
                    return { ...examen, evaluado: !examen.evaluado }
                } else {
                    return { ...examen }
                }
            })
            setListaExamenes(listaModif)
        } else {
            console.log(error)
        }
    }

    const hadndlePromote = async (examen_id) => {
        const [data, error] = await fetchResource(`/director/examen/promoteCategories/${examen_id}`, "put", true)
        if (data) {
            toast.success("Categorias promovidas con exito", { style: { background: "lightgreen", color: "Black" } })
            setPromote(false)
            return
        }
        toast.error("Ocurrio un error.\n Intentelo nuevamente mas tarde", {
            style: { background: "red", color: "Black", textAlign: "center" },
        })
        console.log(error)
    }

    // Modal

    const handleClose = () => {
        setModalOpen(false)
        setPromote(false)
        setPromoteModalOpen(null)
    }

    const handlePromoteModalOpen = (text, id) => {
        setPromoteModalOpen({ text, id })
        setPromote(true)
    }

    // Modal

    return (
        <div>
            <Container title="Crear Examen" more={true}>
                <CreateExamHandler setListaExamenes={setListaExamenes} listaExamenes={listaExamenes} />
            </Container>
            <Container title="Examenes" more={true}>
                {loading ? (
                    <CircularProgress color="secondary" />
                ) : (
                    <>
                        {listaExamenes ? (
                            <ExamenTable
                                data={listaExamenes}
                                navigate={navigate}
                                handleEvaluado={handleEvaluado}
                                setModalOpen={setModalOpen}
                                handleActive={handleActive}
                                setPromoteModalOpen={handlePromoteModalOpen}
                            />
                        ) : (
                            <DefaultMessage title={"No hay examenes registrados"} />
                        )}
                    </>
                )}
                <Modal
                    open={modalOpen}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <ModalBody password handleDelete={handleDelete} modalOpen={modalOpen} setModalOpen={setModalOpen} />
                </Modal>
                <Modal
                    open={promote}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <PromoteCategoryModalBody
                        setPromote={setPromote}
                        promoteCallback={hadndlePromote}
                        promoteModalOpen={promoteModalOpen}
                    />
                </Modal>
            </Container>
        </div>
    )
}

export default Examen

export const PromoteCategoryModalBody = ({ setPromote, promoteCallback, promoteModalOpen }) => {
    const style = useStyles()
    const { text = "", id } = promoteModalOpen
    const message = `Esta a punto de promober las categorias del examen:`
    return (
        <div className={style.paper}>
            <Typography variant="h5" className={style.alertText}>
                Atencion
            </Typography>
            <>
                <Typography variant="h8" className={style.text}>
                    {message}
                </Typography>
                <Typography variant="h8" className={style.text}>
                    {text}
                </Typography>
                <Typography
                    variant="h9"
                    className={style.text}
                    style={{
                        border: "solid 1px red",
                        borderRadius: "10px",
                        padding: 6,
                        fontSize: "0.9em",
                        color: "red",
                        margin: 2,
                    }}
                >{`Al realizar esta accion se cerrara la inscripcion y el examen no podra ser reactivado`}</Typography>
                <section className={style.buttonSection}>
                    <Button className={style.button} onClick={() => promoteCallback(id)} size="small">
                        Promover
                    </Button>
                    <Button
                        className={style.button}
                        onClick={() => {
                            setPromote(null)
                        }}
                        size="small"
                    >
                        Cancelar
                    </Button>
                </section>
            </>
        </div>
    )
}

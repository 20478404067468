import React, { useState } from "react"
import { Button, IconButton, TextField, Tooltip, Typography, Modal, Select, MenuItem, Switch } from "@material-ui/core"
import { ArrowDownward, ArrowUpward } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import EditIcon from "@mui/icons-material/Edit"
import { DataGrid } from "@mui/x-data-grid"
import moment from "moment"
import FormControlLabel from "@mui/material/FormControlLabel"

import Containter from "../../Components/Container/Container"

const Styles = makeStyles({
    tableContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    noDataContainer: {
        border: "solid 1px white",
        padding: "0.2em 1em 0.2em 1em",
        borderRadius: "5px",
        minWidth: "90%",
    },
    noDataText: {
        textAlign: "center",
    },
})

const AlumnoDatosPersonales = (props) => {
    const clases = Styles()
    const {
        active,
        removeAlumno,
        role,
        usuario,
        modalSelectClase,
        selectCategoria,
        selectClase,
        modalSelectCategoria,
        openModalSecundario,
        cerrarModalSecundario,
        modalSecundario,
        datos,
        datosClases,
        datosCategorias,
        cerrar,
        pageData,
        changePage,
        loading,
        editRecord,
        removeRecord = null,
        addRecord = null,
    } = props

    const [muestraLog, setMuestraLog] = useState(false)
    const [tipo, setTipo] = useState("")
    const [editCategory, setEditCategory] = useState(0)
    const [editClase, setEditClase] = useState(null)
    const [dni, setDni] = useState("")
    const [pass, setPass] = useState("")

    const columns = [
        //  { field:'dni', headerName: 'Dni', width:140 },

        {
            field: "datos",
            headerName: "Info",
            width: 90,
            renderCell: (row) => (
                <IconButton onClick={() => editRecord(row.row.id)}>
                    <Tooltip title="Ver/Editar">
                        <EditIcon fontSize="small" />
                    </Tooltip>
                </IconButton>
            ),
        },
        { field: "nombre", headerName: "Nombre", minWidth: 300 },
        {
            field: "edad",
            headerName: "Edad",
            minWidth: 110,
            renderCell: (row) => <div>{row.row.edad || "No Indicado"}</div>,
        },
        // { field:'telefono', headerName: 'Telefono',minWidth: 120, renderCell: row => <div>{row.row.telefono || 'No Indicado'}</div>},
        { field: "categoria", headerName: "Categoria", minWidth: 250 },
        {
            field: "instructor",
            headerName: "Instructor",
            minWidth: 150,
            renderCell: (row) => <div>{row.row.instructor || "No Asignado"}</div>,
        },
        { field: "escuela", headerName: "Escuela", minWidth: 130 },

        {
            field: "actions",
            headerName: "Acciones",
            minWidth: 120,
            renderCell: (row) => {
                if (removeRecord) {
                    return (
                        <IconButton onClick={() => removeRecord(row.row.id)}>
                            <Tooltip title="Remover">
                                <ArrowDownward />
                            </Tooltip>
                        </IconButton>
                    )
                } else if (addRecord) {
                    return (
                        <IconButton onClick={() => addRecord(row.row.id)}>
                            <Tooltip title="Añadir">
                                <ArrowUpward />
                            </Tooltip>
                        </IconButton>
                    )
                } else {
                    return (
                        <IconButton disabled onClick={() => addRecord(row.row.id)}>
                            <Tooltip title="Añadir">
                                <ArrowUpward />
                            </Tooltip>
                        </IconButton>
                    )
                }
            },
        },
    ]

    return (
        <Containter styles={{ width: "60%", marginLeft: "20%", marginTop: "2%" }} title={datos.nombre_completo} more>
            <Typography variant="h5" style={datos.clase_id > 0 ? { color: "green" } : { color: "red" }}>
                {datos.clase_id > 0 ? "ACTIVO" : "INACTIVO"}
            </Typography>

            <Button
                style={{ background: "red", marginBottom: 7, marginTop: 20 }}
                onClick={() => removeAlumno(datos.id)}
            >
                Desvincular Alumno
            </Button>
            <TextField
                InputProps={{ readOnly: true }}
                style={{ marginBottom: 7, marginTop: 20 }}
                id="dni"
                label="DNI"
                value={datos.dni}
                type="text"
                variant="outlined"
                size="small"
                color="secondary"
            />

            <TextField
                InputProps={{ readOnly: true }}
                style={{ marginBottom: 7, marginTop: 7 }}
                id="fnac"
                label="Fecha Nacimiento"
                value={
                    datos.nacimiento == "0000-00-00" || !datos.nacimiento
                        ? "no Indicado"
                        : moment(datos.nacimiento).format("DD-MM-YYYY")
                }
                type="text"
                variant="outlined"
                size="small"
                color="secondary"
            />

            <TextField
                InputProps={{ readOnly: true }}
                style={{ marginBottom: 7, marginTop: 7 }}
                id="edad"
                label="Edad"
                value={datos.edad < 1 ? "no Indicado" : datos.edad}
                type="text"
                variant="outlined"
                size="small"
                color="secondary"
            />
            <Tooltip title={role < 3 ? "Editar Categoria" : "Sin Autorizacion para Editar"}>
                <TextField
                    onClick={() => {
                        if (role < 3) {
                            openModalSecundario()
                            setTipo(0)
                        }
                    }}
                    InputProps={{ readOnly: true }}
                    style={{ cursor: "pointer", marginBottom: 7, marginTop: 7 }}
                    id="categoria"
                    label="Categoria **"
                    value={datos.categoria}
                    type="text"
                    variant="outlined"
                    size="small"
                    color="secondary"
                />
            </Tooltip>

            <Tooltip title={usuario == datos.instructor_id ? "Editar Clase" : "Edición No Autorizada"}>
                <TextField
                    onClick={() => {
                        if (usuario == datos.instructor_id) {
                            openModalSecundario()
                            setTipo(1)
                        }
                    }}
                    InputProps={{ readOnly: true }}
                    style={{ marginBottom: 7, marginTop: 7 }}
                    id="clase"
                    label="Clase **"
                    value={datos.clase_id > 0 ? datos.clase : "Sin Asignar"}
                    type="text"
                    variant="outlined"
                    size="small"
                    color="secondary"
                />
            </Tooltip>

            <TextField
                InputProps={{ readOnly: true }}
                style={{ marginBottom: 7, marginTop: 7 }}
                id="telefono"
                label="Telefono"
                value={datos.telefono}
                type="text"
                variant="outlined"
                size="small"
                color="secondary"
            />
            <TextField
                InputProps={{ readOnly: true }}
                style={{ marginBottom: 7, marginTop: 7 }}
                id="correo"
                label="Correo Electronico"
                value={datos.email}
                type="text"
                variant="outlined"
                size="small"
                color="secondary"
            />
            <TextField
                InputProps={{ readOnly: true }}
                style={{ marginBottom: 7, marginTop: 7 }}
                id="escuela"
                label="Escuela"
                value={datos.escuela}
                type="text"
                variant="outlined"
                size="small"
                color="secondary"
            />
            <TextField
                InputProps={{ readOnly: true }}
                style={{ marginBottom: 7, marginTop: 7 }}
                id="instructor"
                label="Instructor"
                value={datos.instructor}
                type="text"
                variant="outlined"
                size="small"
                color="secondary"
            />

            <Button style={{ background: "red", marginTop: 15 }} onClick={() => cerrar()}>
                Cerrar
            </Button>

            <Modal open={modalSecundario}>
                <Containter
                    styles={{ width: "60%", marginLeft: "20%", marginTop: "10%" }}
                    title={tipo == 0 ? "EDITAR CATEGORIA" : "EDITAR CLASE"}
                    more
                >
                    {tipo == 0 ? (
                        <>
                            <Select
                                disabled={muestraLog == true ? true : false}
                                style={{
                                    marginTop: 40,
                                    marginBottom: 30,
                                    width: 400,
                                    textAlign: "center",
                                    textJustify: "auto",
                                    fontSize: 17,
                                }}
                                defaultValue={datos.categoria_id}
                                onChange={(x) => {
                                    setEditCategory(x.target.value)
                                }}
                            >
                                {datosCategorias.map((x) => {
                                    return (
                                        <MenuItem id={x.id} value={x.id}>
                                            {x.nombre}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <Button
                                    disabled={editCategory == 0 || muestraLog == true ? true : false}
                                    onClick={() => {
                                        setMuestraLog(true)
                                    }}
                                    style={{ width: 100, background: "red", marginRight: 20, marginLeft: 20 }}
                                >
                                    Confirmar
                                </Button>
                                <Button
                                    disabled={muestraLog == true ? true : false}
                                    onClick={() => {
                                        cerrarModalSecundario()
                                        setEditCategory(0)
                                    }}
                                    style={{ width: 100, background: "red", marginRight: 20, marginLeft: 20 }}
                                >
                                    Cerrar
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            {/* modalSelectCategoria(editCategory) */}

                            <Select
                                style={{
                                    marginTop: 40,
                                    marginBottom: 30,
                                    width: 400,
                                    textAlign: "center",
                                    textJustify: "auto",
                                    fontSize: 17,
                                }}
                                defaultValue={datos.clase_id == null ? 0 : datos.clase_id}
                                onChange={(x) => {
                                    setEditClase(x.target.value)
                                }}
                            >
                                <MenuItem id={0} value={0}>
                                    Sin Clase
                                </MenuItem>

                                {datosClases.map((x) => {
                                    return (
                                        <MenuItem id={x.id} value={x.id}>
                                            {x.nombre}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                            {editClase == 0 && (
                                <Typography variant="subtitle1">
                                    * Cambiara el estado de usuario a INACTIVO.{" "}
                                </Typography>
                            )}

                            <div style={{ display: "flex", flexDirection: "row", marginTop: 15 }}>
                                <Button
                                    disabled={editClase == null ? true : false}
                                    onClick={() => {
                                        selectClase(datos.id, editClase)
                                    }}
                                    style={{ width: 100, background: "red", marginRight: 20, marginLeft: 20 }}
                                >
                                    Confirmar
                                </Button>
                                <Button
                                    onClick={() => {
                                        cerrarModalSecundario()
                                        setEditClase(0)
                                    }}
                                    style={{ width: 100, background: "red", marginRight: 20, marginLeft: 20 }}
                                >
                                    Cerrar
                                </Button>
                            </div>
                        </>
                    )}

                    {muestraLog == true && (
                        <div
                            style={{
                                width: "100%",
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderRadius: 10,
                                marginTop: 30,
                                padding: 10,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Typography variant="h6" style={{ width: "100%", textAlign: "center" }}>
                                INGRESE USUARIO Y CONTRASEÑA
                            </Typography>

                            <Typography style={{ width: "100%", textAlign: "center", fontSize: 12 }}>
                                Debe confirmar su identidad para continuar.{" "}
                            </Typography>

                            <TextField
                                style={{ marginBottom: 7, marginTop: 7 }}
                                id="dni"
                                label="DNI"
                                value={dni}
                                onChange={(e) => setDni(e.target.value)}
                                type="text"
                                variant="outlined"
                                size="small"
                                color="secondary"
                            />
                            <TextField
                                style={{ marginBottom: 7, marginTop: 7 }}
                                id="pass"
                                label="Clave"
                                value={pass}
                                onChange={(x) => setPass(x.target.value)}
                                type="text"
                                variant="outlined"
                                size="small"
                                color="secondary"
                            />
                            <div>
                                <Button
                                    style={{ background: "red", margin: 10 }}
                                    onClick={() => selectCategoria(datos.id, editCategory, dni, pass)}
                                >
                                    Confirma
                                </Button>
                                <Button style={{ background: "red", margin: 10 }} onClick={() => setMuestraLog(false)}>
                                    Cancelar
                                </Button>
                            </div>
                        </div>
                    )}
                </Containter>
            </Modal>
        </Containter>

        // <div className={clases.tableContainer}>
        //     {
        //         data.length ?
        //             <DataGrid
        //                 pagination
        //                 autoHeight
        //                 disableColumnMenu
        //                 disableColumnFilter
        //                 disableSelectionOnClick
        //                 rows={data}
        //                 pageSize={10}
        //                 loading={loading}
        //                 columns={columns}
        //                 paginationMode='server'
        //                 isRowSelectable={false}
        //                 onPageChange={changePage}
        //                 rowsPerPageOptions={[10]}
        //                 rowCount={pageData.records}
        //                 page={pageData.currentPage}
        //             />
        //             :
        //             <div className={clases.noDataContainer}>
        //                 <h2 className={clases.noDataText}>No se encontraron alumnos</h2>
        //             </div>
        //     }
        // </div>
    )
}

export default AlumnoDatosPersonales

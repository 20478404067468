const initialState = {
    basicData: {
        instructores: [],
        categorias: [],
        centros: [],
        escuelas: [],
    },
}

export default (state = initialState, actions) => {
    if (actions.type === "UPDATE_BASIC") {
        return {
            ...state,
            basicData: actions.payload,
        }
    }
    return state
}

export const selectActiveBasicData = (state) => state.basicDataReducer.basicData

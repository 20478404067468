import React, { useEffect, useState } from "react"
import { fetchResource, usePermitions } from "../../../Functions/Functions"
import CircularProgress from "@material-ui/core/CircularProgress"
import Box from "@mui/material/Box"
import { useSelector } from "react-redux"
import { makeStyles, Select, MenuItem, option, Typography, TextField, Button } from "@material-ui/core"
import toast, { Toaster } from "react-hot-toast"
import Rating from "@mui/material/Rating"
import { styled } from "@mui/material/styles"
import { useLocation } from "react-router-dom"
import moment from "moment"
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions"
import FavoriteIcon from "@mui/icons-material/Favorite"
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied"
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt"
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon"

const Styles = makeStyles({
    rankingMensual: {
        "&.MuiRating-root": {
            fontSize: "2.5rem",
        },
    },

    rankingAnual: {
        "&.MuiRating-root": {
            fontSize: "4rem",
        },
    },

    rankingAnualRojo: {
        "&.MuiRating-root": {
            fontSize: "4rem",
            color: "red",
        },
    },
    rankingMensualRojo: {
        "&.MuiRating-root": {
            fontSize: "2.5rem",
            color: "orange",
        },
    },

    filtersContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        minWidth: "100%",
        flexWrap: "wrap",
    },
    filterInput: {
        alignSelf: "flex-end",
    },
    recordCounter: {
        alignSelf: "end",
    },
    ageInputs: {
        maxWidth: "5em",
    },
    ctaButton: {
        backgroundColor: "red",
    },

    top: {
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 5,
        marginBottom: 20,
        marginTop: 10,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: 10,
        "@media (min-width: 1200px)": { flexDirection: "row" },
    },
})

const CuotaUserControlTopInfo = (props) => {
    const { btnvolver, meses, disabled, handleMeses, mesSeleccion, conteo, mesActivo } = props

    const state = useSelector((state) => ({ ...state["basicDataReducer"] }))
    const { basicData } = state
    const { role_id } = usePermitions()
    const location = useLocation()
    const examenesActivos = location.state
    const [modalOpen, setModalOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [alumnos, setAlumnos] = useState([]) // guarda los id y nombres de los alumnos
    const [clases, setClases] = useState("") // guarda todas las clases que va a mostrar en Select
    const [records, setRecords] = useState(0)
    const [modal, setModal] = useState(false)

    console.log({ conteo })

    const classes = Styles()

    return (
        <div className={classes.top}>
            <Box
                style={{
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    margin: 20,
                }}
            >
                <Typography>MES </Typography>
                <Typography style={{ fontSize: 40 }}>{mesActivo.month}</Typography>
            </Box>

            <Box
                style={{
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    margin: 20,
                }}
            >
                <Typography>ESTADO CUOTA </Typography>
                {mesActivo.cuota == 1 ? (
                    <SentimentSatisfiedAltIcon style={{ color: "lightgreen", fontSize: "4.5rem" }} />
                ) : (
                    <SentimentVeryDissatisfiedIcon style={{ color: "red", fontSize: "4.5rem" }} />
                )}
            </Box>

            <Box
                style={{
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    margin: 20,
                }}
            >
                <Typography>FECHA DE PAGO </Typography>

                <Typography>
                    {" "}
                    {mesActivo.cuota == 1 ? moment(mesActivo.fecha).format("DD-MM-YYYY") : mesActivo.fecha}
                </Typography>
            </Box>
        </div>
    )
}

export default CuotaUserControlTopInfo

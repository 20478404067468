import React, { useEffect, useState } from "react"
import Container from "../../../Components/Container/Container"
import { fetchResource } from "../../../Functions/Functions"
import CircularProgress from "@mui/material/CircularProgress"
import ActionsTable from "../../../Components/Table/ActionsTable"
import { ActivitiesBasicColumns } from "../../../Components/Table/AppColumns"
import DefaultMessage from "../../../Components/DefaultMessage/DefaultMessage"
import { Button, MenuItem, Typography, Select, FormControl } from "@material-ui/core"
import { useNavigate } from "react-router-dom"
import { makeStyles } from "@material-ui/styles"
import ActividadesExamenesTable from "./ExamenesTerminadosTable"
import { useSelector } from "react-redux"

const Styles = makeStyles({
    actividadesContainer: {
        borderWidth: 1,
        borderRadius: 10,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
    },

    actividadesBox: {
        borderStyle: "solid",
        borderRadius: 10,
        borderWidth: 1,
        width: "100%",
        height: 200,
        textAlign: "center",
        padding: 20,
        margin: 10,
        alignItems: "center",
    },
    examenBox: {
        borderStyle: "solid",
        borderWidth: 1,
        width: "30%",
        height: 200,
        textAlign: "center",
        padding: 20,
        margin: 10,
        alignItems: "center",
    },
    button: {
        minWidth: "210px",
        backgroundColor: "#e00",
        textAlign: "center",
        maxWidth: "220px",
    },
    buttonDisabled: {
        width: "210px",
        backgroundColor: "#291f21",
    },
    eventListContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "1em",
        marginTop: "2em",
        borderRadius: 10,
    },
    select: {
        width: 250,
        height: 50,
        fontSize: 25,
        textAlign: "center",
    },
})

const HomeExamenes = () => {
    const state = useSelector((state) => ({ ...state["userReducer"] }))
    const { user } = state

    const classes = Styles()
    const [estado, setEstado] = useState(1)

    const [actividadSelected, setActividadSelected] = useState(0)
    const [actividades, setActividades] = useState(0)
    const [inscriptos, setInscriptos] = useState(0)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [examenes, setExamenes] = useState([])

    useEffect(() => {
        fetchExamenes()
        fetchActividades()
    }, [actividadSelected, estado])

    const fetchExamenes = async () => {
        const [data, error] = await fetchResource("/users/examen/activeExams", "get", true)
        console.log("EXAMEN ", data)
        if (data.data) {
            // cuando viene vacio no trae "data"

            setExamenes(data.data)
            setLoading(false)
        }
    }

    const fetchActividades = async () => {
        setLoading(true)
        let auxAnt = estado == 1 ? 0 : 1

        const [data, error] = await fetchResource(
            `/director/actividades/all?activo=${estado}&page=0&anterior=${auxAnt}`,
            "get",
            true,
            null
        )
        //activo=0 & anterior=0 => inactivas
        //activo=1 & anterior=0 => activa
        //activo=0 & anterior=1 => terminada
        if (data) {
            setLoading(false)
            setActividades(data.data)
        } else {
            setLoading(false)
            console.log("ERROR Actividades", error)
            setActividades(0)
        }
    }

    const fetchInscriptosActividad = async (activity_id) => {
        setLoading(true)
        const [data, error] = await fetchResource(
            `/instructor/activity/getUserSigned/${activity_id}?page=1&nombre=&apellido&edadDesde=1&edadHasta=&escuela=&asociacion=&clase=&centro=&categoria=&instructor=&dni=`,
            "get",
            true,
            null
        )
        if (data) {
            setLoading(false)
            setInscriptos(data.data)
        } else {
            setLoading(false)
            console.log("ERROR Inmscriptos", error)
            setInscriptos(0)
        }
    }

    const handleActividad = (x) => {
        setActividadSelected(x)
        fetchInscriptosActividad(x)
    }

    const handleEstado = (x) => {
        setActividadSelected(0)
        setInscriptos(0)
        setEstado(x)
    }

    return (
        <div className="instructores-container">
            <Container title="Examenes" more>
                <div className={classes.actividadesContainer}>
                    <div className={classes.actividadesBox}>
                        <div style={{ display: "flex", marginTop: 10 }}>
                            <div style={{ width: "100%" }}>
                                <Typography>Examen: </Typography>
                                <FormControl
                                    style={{
                                        borderWidth: 1,
                                        borderStyle: "solid",
                                        borderRadius: 5,
                                        padding: 10,
                                        width: 390,
                                    }}
                                >
                                    <Select
                                        className={classes.select}
                                        style={{ width: 370 }}
                                        onChange={(x) => handleActividad(x.target.value)}
                                        value={actividadSelected}
                                    >
                                        <MenuItem disabled id={0} value={0}>
                                            {examenes?.length > 0 ? "Selecciones Examen" : "Proximamente"}
                                        </MenuItem>
                                        {actividades.length > 0 &&
                                            actividades.map((x) => {
                                                return (
                                                    <MenuItem id={x.id} value={x.id}>
                                                        {x.nombre}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div style={{ width: "100%", marginTop: 20 }}>
                                <Typography>Total de Inscriptos </Typography>
                                <Typography variant="h4">{inscriptos.length > 0 ? inscriptos.length : "0"}</Typography>
                            </div>
                        </div>
                    </div>
                    <div className={classes.examenBox}>
                        <Typography variant="h5">Inscripcion a Examen</Typography>
                        <Button
                            onClick={() => navigate(`/instructor/inscripcionExamen`, { state: examenes })}
                            disabled={examenes?.length > 0 ? false : true}
                            className={classes.button}
                            style={{ marginTop: 30, marginBottom: 10 }}
                        >
                            {examenes.length > 0 ? "Inscribir Alumnos" : "Sin Examen Activo"}
                        </Button>
                        <Typography variant="subtitle1">Examenes Activos: {examenes.length} </Typography>
                    </div>
                </div>

                <ActividadesExamenesTable
                    data={inscriptos}
                    loading={loading}
                    mensaje={"Proximamente podra consultar los inscriptos de los examenes finalizados"}
                />
            </Container>
        </div>
    )
}

export default HomeExamenes
